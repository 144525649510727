import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ActionIcon, ContentName, Table } from "./style";

type CompanyUsers = {
  user_id: number;
  full_name: string;
};
interface IProps extends RouteComponentProps {
  data: CompanyUsers[];
}

const UserCompanies: React.FC<IProps> = ({ data, history }) => {
  const columns = [
    {
      title: "ID do Usuário",
      dataIndex: "user_id",
      key: "user_id",
      width: "30%",
    },
    {
      title: "Nome",
      dataIndex: "full_name",
      key: "full_name",
      ellipsis: true,
      width: "50%",
      render: (text) => (
        <ContentName>
          <span>{text}</span>
        </ContentName>
      ),
    },
    {
      title: "Ações",
      dataIndex: "more",
      key: "more",
      width: "20%",
      render: () => (
        <>
          <ActionIcon
            onClick={() => {
              history.push("/users");
            }}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        pagination={false}
        scroll={{ y: 600 }}
        dataSource={data.map((entity) => ({
          key: entity.user_id,
          ...entity,
        }))}
      />
    </>
  );
};

export default withRouter(UserCompanies);
