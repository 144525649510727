import styled from "styled-components";
import { Search, AddCircle, Download } from "../../styles/Icons";

import {
  Input as InputAnt,
  Col as ColAnt,
  Row as RowAnt,
  Select as SelectAnt,
} from "antd";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 8%;
  align-items: flex-start;

  button {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 3rem;
    color: white;
    background: var(--gradiente);
    border-radius: 5px;
  }

  @media only screen and (max-width: 1600px) {
    height: 10%;

    button {
      height: 2.8rem;
      font-size: 0.9rem;
    }
  }

  @media only screen and (max-width: 800px) {
    height: 100%;
    flex-direction: column;
  }

  @media only screen and (max-width: 1400px) {
    @media only screen and (max-height: 1050px) {
      button {
        height: 3rem;
      }
    }
  }

  @media only screen and (max-width: 1366px) {
    button {
      height: 2.3rem;
      font-size: 0.8rem;
    }
  }
`;

export const Col = styled(ColAnt)``;

export const Input = styled(InputAnt)`
  .search-input {
    height: 3rem !important;
    display: flex;
    align-items: center;
  }
`;

export const Select = styled(SelectAnt)`
  width: 100%;
  color: var(--gray-550);
  border: 1px solid rgba(205, 207, 213, 0.5);

  .ant-select-arrow {
    color: var(--gray-550);
  }
`;

export const Row = styled(RowAnt)`
  display: flex;
  width: 70%;
  height: 100%;

  .input-filter {
    margin-right: 1rem;
  }

  .ant-input-affix-wrapper {
    padding: 0 5%;
  }

  .ant-input,
  .ant-select-selector {
    height: 3rem !important;

    @media only screen and (max-width: 1600px) {
      height: 2.8rem !important;
      font-size: 0.9rem;
    }

    @media only screen and (max-width: 1400px) {
      @media only screen and (max-height: 1050px) {
        height: 3rem !important;
      }
    }

    @media only screen and (max-width: 1366px) {
      height: 2.3rem !important;
      font-size: 0.8rem;
    }
  }

  @media only screen and (max-width: 1280px) {
    width: 45%;
  }

  @media only screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const Content = styled.header`
  display: flex;
  width: 100%;
  height: 92%;

  @media only screen and (max-width: 1600px) {
    height: 90%;
  }

  @media only screen and (max-width: 1280px) {
    height: 92%;
  }
`;

export const SearchIcon = styled(Search)`
  width: 1.2rem;
  height: 1.2rem;
  color: var(--gray-550);

  @media only screen and (max-width: 1600px) {
    width: 1rem;
    height: 1rem;
  }
`;

export const Addicon = styled(AddCircle)`
  width: 1rem;
  height: 1rem;
  color: white;
`;

export const DownloadIcon = styled(Download)`
  width: 1rem;
  height: 1rem;
  color: white;
`;

export const ContentButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;

  @media screen and (max-width: 575px) {
    .ant-col {
    display: contents;
    margin-left: 1.5rem;
  }}

  .btnCsv {
    margin-right: 1rem;
  }

  .btnModal {
    @media screen and (max-width: 575px) {
      margin: 1rem 0;
    }
  }
`;