export enum CompanyFields {
  defaulter_reason = "Razão de bloqueio",
  defaulter_status = "Status da loja",
  company_name = "Nome da Empresa",
  cnpj = "CNPJ",
  emitente_uf = "UF do Emitente",
  emitente_incentivo_fiscal = "Incentivo Fiscal do Emitente",
  emitente_razao_social = "Razão Social do Emitente",
  emitente_nome_fantasia = "Nome Fantasia do Emitente",
  emitente_inscricao_estadual = "Inscrição Estadual do Emitente",
  emitente_isento = "Emitente Isento",
  emitente_regime_tributario = "Regime Tributário do Emitente",
  emitente_inscricao_municipal = "Inscrição Municipal do Emitente",
  emitente_cnae = "CNAE do Emitente",
  occupation_area = "Área de Atuação",
  franchisee_fee = "Taxa de Franquia",
  royalts = "Royalties",
  marketing = "Marketing",
  software = "Software",
  contract_start = "Início do Contrato",
  contract_time = "Tempo de Contrato",
  selected_group = "Grupo Selecionado",
  cep = "CEP",
  state_registration = "Inscrição Estadual",
  city = "Cidade",
  city_id = "ID da Cidade",
  address = "Endereço",
  number = "Número",
  district = "Bairro",
  complement = "Complemento",
  distance = "Distância",
  min_order = "Pedido Mínimo",
  token_nfce_production_id = "ID do Token NFCe em Produção",
  token_nfce_production = "Token NFCe em Produção",
  token_focus_homologation = "Token Focus em Homologação",
  token_focus_production = "Token Focus em Produção",
  email = "E-mail",
  telefone = "Telefone",
  crt = "CRT",
  password_certificate = "Senha do Certificado",
  certificate_url = "URL do Certificado",
  certificate_key = "Chave do Certificado",
  token_ibpt = "Token IBPT",
  password = "Senha",
  number_nfe = "Número da NF-e",
  serie_nfe = "Série da NF-e",
  status_nfe = "Status da NF-e",
  merchant_id = "ID do Comerciante",
  image = "Imagem",
  image_key = "Chave da Imagem",
  population = "População",
  own_store = "Loja Própria",
  habilita_nfce = "Habilita NFCe",
  habilita_nfe = "Habilita NFe",
  token_nfce_homologation = "Token NFCe em Homologação",
  token_nfce_homologation_id = "ID do Token NFCe em Homologação",
  enviar_email_destinatario = "Enviar E-mail ao Destinatário",
  discrimina_impostos = "Discrimina Impostos",
  expiration_certificate = "Validade do Certificado",
  description_delivery_plataform = "Descrição da Plataforma de Entrega",
  description_delivery_time = "Descrição do Tempo de Entrega",
  description_store_time = "Descrição do Tempo da Loja",
  whatsapp_number = "Número do WhatsApp",
  responsible_user_id = "ID do Usuário Responsável",
  lat = "Latitude",
  lng = "Longitude",
  csc_nfce_producao = "CSC Token de produção",
  id_token_nfce_producao = "Id do Token de produção",
  csc_nfce_homologacao = "CSC Token de homologação",
  id_token_nfce_homologacao = "Id do Token de homologação",
  company_reason = "Justificativa da inatividade",
  company_status = "Status da Loja",
  country = "País",
}
