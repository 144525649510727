const convertToCSV = (objArray) => {
    const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";
  
    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line !== "") line += ",";
  
        line += array[i][index];
      }
  
      str += line + "\r\n";
    }
  
    return str;
  };
  
  export function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
      items = [headers, ...items];
    }
  
    const jsonObject = JSON.stringify(items);
  
    const xls = convertToCSV(jsonObject);
  
    const exportedFileName = fileTitle + ".csv" || "export.csv";
  
    const blob = new Blob([xls], { type: "text/xls;charset=utf-8;" });
    //@ts-ignore
    if (navigator.msSaveBlob) {
      //@ts-ignore
      navigator.msSaveBlob(blob, exportedFileName);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  