import styled from "styled-components";
import { Search, AddCircle } from "../../styles/Icons";

import {
  Input as InputAnt,
  Col as ColAnt,
  Row as RowAnt,
  Select as SelectAnt,
} from "antd";

const { Option: OptionAnt } = SelectAnt;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 8%;

  button {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 11%;
    height: 3rem;
    color: white;
    background: var(--gradiente);
    border-radius: 5px;
  }

  @media only screen and (max-width: 1600px) {
    height: 10%;

    button {
      height: 2.8rem;
      font-size: 0.9rem;
    }
  }

  @media only screen and (max-width: 1400px) {
    @media only screen and (max-height: 1050px) {
      button {
        height: 3rem;
        width: 12%;
      }
    }
  }

  @media only screen and (max-width: 1366px) {
    button {
      height: 2.3rem;
      font-size: 0.8rem;
    }
  }

  @media only screen and (max-width: 1280px) {
    height: 8%;
  }

  @media only screen and (max-width: 575px) {
    height: 10%;
  }

  @media only screen and (max-width: 575px) {
    button {
      width: 10%;

      span {
        display: none;
      }
    }
  }
`;

export const Col = styled(ColAnt)``;

export const Input = styled(InputAnt)``;

export const Option = styled(OptionAnt)``;

export const Select = styled(SelectAnt)`
  width: 100%;
  color: var(--gray-550);
  border: 1px solid rgba(205, 207, 213, 0.5);

  .ant-select-arrow {
    color: var(--gray-550);
  }
`;

export const Row = styled(RowAnt)`
  display: flex;
  width: 100%;
  height: 100%;

  .input-filter {
    margin-right: 1rem;
    display: flex;
  }

  .ant-input,
  .ant-select-selector {
    height: 2.7rem !important;

    @media only screen and (max-width: 1600px) {
      font-size: 0.9rem;
    }

    @media only screen and (max-width: 1400px) {
      @media only screen and (max-height: 1050px) {
        height: 3rem !important;
      }
    }

    @media only screen and (max-width: 1366px) {
      height: 2.3rem !important;
      font-size: 0.8rem;
    }
  }

  @media only screen and (max-width: 1280px) {
    width: 45%;
  }

  @media only screen and (max-width: 575px) {
    width: 90%;
  }
`;

export const Content = styled.header`
  display: flex;
  width: 100%;
  height: 92%;

  @media only screen and (max-width: 1600px) {
    height: 90%;
  }

  @media only screen and (max-width: 1280px) {
    height: 92%;
  }

  @media only screen and (max-width: 575px) {
    height: 90%;
  }
`;

export const SearchIcon = styled(Search)`
  width: 1.2rem;
  height: 1.2rem;
  color: var(--gray-550);
`;

export const Addicon = styled(AddCircle)`
  width: 1.4rem;
  height: 1.4rem;
  color: white;
`;
