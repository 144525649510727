import styled, { css } from "styled-components";

import { AddCircle, Edit, TrashRestore, Gear } from "../../styles/Icons";

import {
  Collapse as CollapseAnt,
  Button as ButtonAnt,
  Row as RowAnt,
  Col as ColAnt,
  Input as InputAnt,
  Modal as ModalAnt,
  Dropdown as DropdownAnt,
  Menu as MenuAnt,
} from "antd";

const { Panel } = CollapseAnt;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 3%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 10%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: var(--gray-50);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--gray-250);
    border-radius: 5px;
  }
`;
export const CollapseHeader = styled.div`
  background-color: var(--white);
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: var(--gray-100);

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 12px;
  font-weight: 500;
  color: var(--gray-250);

  padding: 1rem 2.5rem 0 3.25rem;
  margin-top: 1rem;
`;

export const Collapse = styled(CollapseAnt)`
  background-color: transparent;
  border: none;

  .ant-collapse-header {
    font-size: 14px;
    font-weight: 400;

    background: var(--white);

    padding-right: 2rem;

    border-width: 1px 1px 0px 1px !important;
    border-style: solid !important;
    border-color: var(--gray-100) !important;
    border-radius: 0 !important;

    color: var(--gray-550) !important;
    font-weight: 400;

    .ant-collapse-arrow svg {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
    .ant-collapse-extra svg {
      margin-right: 1.75rem;
    }

    :hover {
      border-left: 1px solid var(--orange-100) !important;
    }
  }
  .ant-collapse-content {
    border-top: 1px solid var(--gray-100);
  }
  .ant-collapse-content-box {
    background: var(--gray-20);
  }
  &:last-child {
    border-bottom: 1px solid var(--gray-100);
  }
`;

export const CollapseItem = styled(CollapseAnt)`
  background-color: transparent;
  border: none;

  .ant-collapse-header {
    font-size: 14px;
    font-weight: 400;

    background: var(--white);

    padding-right: 1.5rem;

    border: 1px solid var(--gray-100);
    border-radius: 0;

    .ant-collapse-extra svg {
      margin-right: 1.25rem;
    }

    .ant-collapse-arrow svg {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
    :hover {
      border-left: 1px solid var(--orange-100) !important;
    }
  }
  .ant-collapse-content-box {
    padding: 0;
    background: var(--gray-20);

    color: var(--gray-550);
    font-weight: 400;

    label {
      padding-left: 1rem;
    }
  }
  &:last-child {
    border-bottom: 1px solid var(--gray-100);
  }
`;

export const Menu = styled(MenuAnt)`
  .ant-dropdown-menu-title-content {
    span {
      color: var(--gray-550);
      font-weight: 400;
      font-size: 16px;
    }
  }
`;

export const MenuItem = styled(MenuAnt.Item)``;

export const MenusInfoContent = styled.div``;

export const ButtonsPermission = styled.div`
  margin-top: 1%;
`;

export const PanelAnt = styled(Panel)``;

export const Button = styled(ButtonAnt)`
  background: var(--gradiente);
  color: white;
  font-weight: 500;

  width: 10.25rem;
  height: 3rem;

  border-radius: 5px;
  margin-right: 0.4rem;

  :hover {
    background: var(--gradiente);
  }

  ::selection {
    transform: none;
  }
`;

const IconCSS = css`
  width: 1.4rem;
  height: 1.4rem;
  color: var(--gray-700);
  cursor: pointer;
`;

export const AddIcon = styled(AddCircle)`
  ${IconCSS}
  color: white;
  margin: 0 1rem 0.2rem 0;
`;

export const SettingsIcon = styled(Gear)`
  ${IconCSS}
`;

export const EditIcon = styled(Edit)`
  ${IconCSS}
`;

export const RemoveIcon = styled(TrashRestore)`
  ${IconCSS}
  color: var(--red-600);
`;

export const Dropdown = styled(DropdownAnt)``;

export const RowRouteList = styled(RowAnt)`
  justify-content: space-between;
  padding: 0.5rem;
  background-color: var(--white);
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: var(--gray-100);

  cursor: pointer;
  :hover {
    border-left: 1px solid var(--orange-100);
  }

  &:first-child() {
    margin-top: 1rem;
  }

  &:last-child {
    border-width: 0 1px 0px 1px;
    border-radius: 0;
  }
`;

export const Row = styled(RowAnt)`
  width: 100%;

  .ant-form-item {
    margin-bottom: 10px;
  }
`;

export const Col = styled(ColAnt)``;

export const Input = styled(InputAnt)`
  width: 92%;
`;

export const ButtonCancel = styled(ButtonAnt)`
  color: var(--blue-300);
  margin-right: 3.7rem;
  background-color: transparent !important;
  :active,
  :hover,
  :focus {
    opacity: 0.8;
  }
`;

export const ButtonSave = styled(ButtonAnt)`
  width: 7.5rem;
  height: 3.2rem;

  background: var(--orange-100);
  color: white;
  border-radius: 5px;

  :active,
  :hover,
  :focus {
    background: var(--orange-100);
  }
`;

export const BtnsNewModule = styled.div``;

export const ModalDropZone = styled.div``;

export const ModalNewModule = styled(ModalAnt)`
  .ant-modal-content {
    width: 55rem;
    height: 27.5rem;
  }

  .ant-modal-title {
    font-weight: 500;
    font-size: 1.25rem;
    color: var(--blue-300);
  }

  .ant-modal-body {
    height: 68%;
    padding: 0.4rem 2.8rem;

    input {
      border: 1px solid var(--gray-100);
      border-radius: 0.3rem;

      height: 3.2rem;
    }

    label {
      color: var(--gray-600);
      padding-bottom: 0;
    }

    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      color: var(--orange-150);
      content: "*";
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      content: "";
    }
  }

  .ant-modal-header {
    padding: 2rem 2.8rem;
    border-bottom: none;
  }

  .ant-modal-footer {
    padding: 1rem 2.8rem;

    border-top: none;

    background-color: var(--gray-20);
  }
`;

export const Modal = styled(ModalAnt)`
  .ant-modal-content {
    width: 51.1rem;
    height: 21rem;

    .ant-modal-title {
      font-weight: 500;
      font-size: 1.25rem;
      color: var(--blue-300);
    }
  }

  .ant-modal-body {
    height: 56%;
    padding: 0.4rem 2.8rem;

    input {
      border: 1px solid var(--gray-100);
      border-radius: 0.3rem;

      height: 3.2rem;
    }

    label {
      color: var(--gray-600);
      padding-bottom: 0;
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      color: var(--orange-150);
      content: "*";
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      content: "";
    }
  }

  .ant-modal-header {
    padding: 2rem 2.8rem;
    border-bottom: none;
  }

  .ant-modal-footer {
    width: 51.1rem;
    height: 6.1rem;

    padding: 1rem 2.8rem;

    border-top: none;

    background-color: var(--gray-20);
  }
`;

export const ModalAddRoute = styled(ModalAnt)`
  .ant-modal-content {
    width: 27.6rem;
    height: 21rem;
  }

  .ant-modal-title {
    font-weight: 500;
    font-size: 1.25rem;
    color: var(--blue-300);
  }

  .ant-modal-body {
    height: 47%;
    padding: 0.4rem 2.8rem;
    input {
      border: 1px solid var(--gray-100);
      border-radius: 0.3rem;

      height: 3.2rem;
    }
    label {
      color: var(--gray-600);
      padding-bottom: 0;
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      color: var(--orange-150);
      content: "*";
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      content: "";
    }
  }

  .ant-modal-header {
    padding: 2rem 2.8rem;
    border-bottom: none;
  }

  .ant-modal-footer {
    width: 27.6rem;
    height: 6.1rem;

    padding: 1.4rem 2.8rem;
    border-top: none;

    background-color: var(--gray-20);
  }
`;

export const BtnsAddRoute = styled.div``;
