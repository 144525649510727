export const genderOptions = [
    { value: 0, description: 'Feminino' },
    { value: 1, description: 'Masculino' },
    { value: 2, description: 'Prefiro não informar' }
];

export const scholarityOptions = [
    { value: 0, description: 'Sem Escolaridade' },
    { value: 1, description: 'Ensino Fundamental Incompleto' },
    { value: 2, description: 'Ensino Fundamental Completo' },
    { value: 3, description: 'Ensino Médio Incompleto' },
    { value: 4, description: 'Ensino Médio Completo' },
    { value: 5, description: 'Ensino Superior Incompleto' },
    { value: 6, description: 'Ensino Superior Completo' },
    { value: 7, description: 'Pós-Graduação' },
    { value: 8, description: 'Mestrado' },
    { value: 9, description: 'Doutorado' },
    { value: 10, description: 'Outro' },
];

export const maritalStatus = [
    { value: 0, description: 'Casado' },
    { value: 1, description: 'Solteiro' },
    { value: 2, description: 'Viúvo' },
    { value: 3, description: 'Divorciado' },
]

export const atuationArea = [
    { value: 0, description: 'Tecnologia da Informação (TI)' },
    { value: 1, description: 'Saúde e Medicina' },
    { value: 2, description: 'Educação' },
    { value: 3, description: 'Finanças e Contabilidade' },
    { value: 4, description: 'Vendas e Marketing' },
    { value: 5, description: 'Engenharia' },
    { value: 6, description: 'Artes e Entretenimento' },
    { value: 7, description: 'Serviços Jurídicos' },
    { value: 8, description: 'Consultoria' },
    { value: 9, description: 'Recursos Humanos' },
    { value: 10, description: 'Agricultura e Meio Ambiente' },
    { value: 11, description: 'Construção e Arquitetura' },
    { value: 12, description: 'Governo e Setor Público' },
    { value: 13, description: 'Varejo e Comércio' },
    { value: 14, description: 'Indústria Manufatureira' },
    { value: 15, description: 'Transporte e Logística' },
    { value: 16, description: 'Mídia e Comunicação' },
    { value: 17, description: 'Sem Fins Lucrativos e Organizações Sociais' },
    { value: 18, description: 'Outro (especificar)' },
]