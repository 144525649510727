import React from "react";
import Routes from "./routes";
import "./datadog"

import GlobalStyles from "./styles/GlobalStyles";

const App = () => {
  return (
    <>
      <Routes />
      <GlobalStyles />
    </>
  );
};

export default App;
