export const rucFormater = (value: string) => {
  if (!value) {
    return "Loja em Montagem";
  }
  const cleanedValue = value.replace(/[^0-9]/g, "");
  if (cleanedValue.length === 8) {
    return cleanedValue.replace(/(\d{7})(\d{1})/g, "$1-$2");
  } else {
    return cleanedValue.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      "$1.$2.$3/$4-$5"
    );
  }
};
