import styled from "styled-components";

import { Table as TableAnt } from "antd";
import { InformationCircle } from "../../styles/Icons";

export const Table = styled(TableAnt)`
  width: 100%;
  .actived {
    color: var(--green-700);
  }

  .no-actived {
    color: var(--gray-550);
  }
`;

export const ContentName = styled.div`
  display: flex;

  span{
    text-transform: capitalize;
  }
`;

export const ActionIcon = styled(InformationCircle)`
  width: 30px;
  height: 30px;
  color: var(--orange-150);

  :hover {
    cursor: pointer;
  }
`;
