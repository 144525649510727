import styled from "styled-components";

import {
  Form as FormAnt,
  Row as RowAnt,
  Col as ColAnt,
  Button as ButtonAnt,
  Modal as ModalAnt,
  Input as InputAnt,
} from "antd";

import { Table as TableAnt } from "antd";

import { DotsHorizontal } from "../../styles/Icons";

export const Form = styled(FormAnt)`
  padding: 0 2.7rem;
  background: var(--white);

  .ant-form-item {
    margin-bottom: 0px;
  }
`;

export const FormItem = styled(FormAnt.Item)`
  padding: 0.3rem;
`;

export const LevelSpan = styled.span`
  text-transform: capitalize !important;
`;

export const Row = styled(RowAnt)``;

export const Col = styled(ColAnt)``;

export const Input = styled(InputAnt)``;

export const Table = styled(TableAnt)`
  width: 100%;
`;

export const MoreIcon = styled(DotsHorizontal)`
  width: 1.5rem;
  height: 1.5rem;
  color: var(--orange-100);
  cursor: pointer;
`;

export const ModalEditPass = styled(ModalAnt)`
  width: 100%;
  height: 70%;

  .ant-modal-body {
    padding: 0;
    height: 60%;
    padding-bottom: 2rem;

    input {
      border: 1px solid var(--gray-100);
      border-radius: 0.3rem;
      height: 3.25rem;
    }

    label {
      color: var(--gray-600);
      font-weight: 500;
    }

    .ant-select-selector {
      height: 3.25rem;
      border: 1px solid var(--gray-100);
      border-radius: 0.3rem;
    }

    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      color: var(--orange-150);
      content: "*";
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      content: "";
    }
  }

  .ant-modal-header {
    padding: 2.5rem 3rem;
    border-bottom: none;

    .ant-modal-title {
      font-weight: 500;
      font-size: 1.85rem;
      color: var(--gray-700);
    }
  }

  .ant-modal-footer {
    justify-content: end;
    border-top: none;
    background-color: var(--gray-20);
    span {
      width: 3.3rem !important;
    }
  }
`;
export const Modal = styled(ModalAnt)``;

export const ButtonCancel = styled(ButtonAnt)`
  color: var(--blue-300);
  margin-right: 3.7rem;
  background-color: transparent !important;
  :active,
  :hover,
  :focus {
    opacity: 0.8;
  }
`;

export const ButtonSave = styled(ButtonAnt)`
  height: 3.2rem;

  margin-right: 1.6rem;

  background: var(--orange-100);
  color: white;
  border-radius: 5px;

  :active,
  :hover,
  :focus {
    background: var(--orange-100);
  }
`;

export const BtnsModalUser = styled.div``;
