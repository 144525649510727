import React, { useState, useEffect } from "react";

import { Container, Button } from "./styles";

import api from "../../services/api";
import { getTokenInfo } from "../../services/auth";

const mandatoryInfos = [
  { field: "address", description: "endereço", mandatory: true },
  { field: "cep", description: "cep", mandatory: true },
  { field: "city", description: "cidade", mandatory: true },
  { field: "city_id", description: "", mandatory: false },
  { field: "cnpj", description: "cnpj", mandatory: true },
  { field: "company_id_focus", description: "", mandatory: false },
  { field: "company_name", description: "nome da empresa", mandatory: true },
  { field: "company_reason", description: "razão social", mandatory: true },
  { field: "company_status", description: "", mandatory: false },
  { field: "complement", description: "", mandatory: false },
  { field: "contract", description: "contrato", mandatory: true },
  { field: "contract_key", description: "chave do contrato", mandatory: true },
  {
    field: "contract_start",
    description: "inicio do contrato",
    mandatory: true,
  },
  { field: "contract_time", description: "tempo do contrato", mandatory: true },
  { field: "crt", description: "", mandatory: false },
  {
    field: "description_delivery_plataform",
    description: "descrição da plataforma de delivery",
    mandatory: false,
  },
  {
    field: "description_delivery_time",
    description: "descrição do funcionamento do veliry",
    mandatory: false,
  },
  {
    field: "description_store_time",
    description: "horário de funcionamento da loja",
    mandatory: false,
  },
  { field: "discrimina_impostos", description: "", mandatory: false },
  { field: "distance", description: "", mandatory: false },
  { field: "district", description: "", mandatory: false },
  { field: "email", description: "email", mandatory: true },
  { field: "emitente_cnae", description: "", mandatory: false },
  { field: "emitente_incentivo_fiscal", description: "", mandatory: false },
  { field: "emitente_inscricao_estadual", description: "", mandatory: false },
  { field: "emitente_inscricao_municipal", description: "", mandatory: false },
  { field: "emitente_isento", description: "", mandatory: false },
  { field: "emitente_nome_fantasia", description: "", mandatory: false },
  { field: "emitente_razao_social", description: "", mandatory: false },
  { field: "emitente_regime_tributario", description: "", mandatory: false },
  { field: "emitente_uf", description: "", mandatory: false },
  { field: "enviar_email_destinatario", description: "", mandatory: false },
  { field: "expiration_certificate", description: "", mandatory: false },
  { field: "franchisee_fee", description: "", mandatory: false },
  { field: "habilita_nfce", description: "", mandatory: false },
  { field: "habilita_nfe", description: "", mandatory: false },
  { field: "id", description: "", mandatory: false },
  { field: "image", description: "imagem", mandatory: true },
  { field: "image_key", description: "", mandatory: false },
  { field: "lat", description: "", mandatory: false },
  { field: "lng", description: "", mandatory: false },
  { field: "marketing", description: "", mandatory: false },
  { field: "merchant_id", description: "", mandatory: false },
  { field: "min_order", description: "", mandatory: false },
  { field: "number", description: "", mandatory: false },
  {
    field: "number_contract",
    description: "numero do contrato",
    mandatory: true,
  },
  { field: "number_nfe", description: "", mandatory: false },
  {
    field: "occupation_area",
    description: "área de ocupação",
    mandatory: true,
  },
  { field: "own_store", description: "proprietário", mandatory: true },
  { field: "password", description: "", mandatory: false },
  { field: "population", description: "", mandatory: false },
  { field: "royalts", description: "", mandatory: false },
  { field: "selected_group", description: "", mandatory: false },
  { field: "serie_nfe", description: "", mandatory: false },
  { field: "software", description: "", mandatory: false },
  { field: "state_registration", description: "", mandatory: false },
  { field: "status_nfe", description: "", mandatory: false },
  { field: "telefone", description: "contato", mandatory: true },
  { field: "token_focus_homologation", description: "", mandatory: false },
  { field: "token_focus_production", description: "", mandatory: false },
  { field: "token_ibpt", description: "", mandatory: false },
  { field: "token_nfce_homologation", description: "", mandatory: false },
  { field: "token_nfce_homologation_id", description: "", mandatory: false },
  { field: "token_nfce_production", description: "", mandatory: false },
  { field: "token_nfce_production_id", description: "", mandatory: false },
  { field: "web_hook_focus_id", description: "", mandatory: false },
  { field: "whatsapp_number", description: "número whatsapp", mandatory: true },
];

type MissingCompanyProps = { company: string; missingProps: string[] };
const CompanyWarning: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [missingFields, setMissingFields] = useState<MissingCompanyProps[]>([]);

  const [count, setCount] = useState(30);
  const [initCount, setInitCount] = useState(false);

  useEffect(() => {
    let interval;

    if (initCount && count > 0) {
      interval = setInterval(() => {
        setCount((prevContador) => prevContador - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [count, initCount]);

  useEffect(() => {
    async function init() {
      const user = getTokenInfo();
      if (user?.level && user?.level >= 4) {
        const {
          data: { content },
        } = await api.get(`/companyUser/${user?.id}/user`);
        if (content?.length) {
          const companies = content.map((c) => c.company);
          const result: MissingCompanyProps[] = [];
          companies.forEach((company) => {
            let companyProps = Object.getOwnPropertyNames(company);
            companyProps.forEach((companyProp) => {
              const mandatoryInfo = mandatoryInfos.find(
                (_mandatoryInfo) =>
                  _mandatoryInfo.field === companyProp &&
                  !company[companyProp] &&
                  _mandatoryInfo.mandatory
              );
              if (mandatoryInfo) {
                const resultIndex = result.findIndex(
                  (res) => res.company === company.company_name
                );
                if (resultIndex < 0) {
                  result.push({
                    company: company.company_name,
                    missingProps: [mandatoryInfo.description],
                  });
                } else {
                  result[resultIndex].missingProps.push(
                    mandatoryInfo.description
                  );
                }
              }
            });
          });
          const shouldOpenFlag =
            process.env.REACT_APP_SHOW_UPDATE_COMPANY || "false";
          if (result.length && shouldOpenFlag === "true") {
            setMissingFields(result);
            setVisible(true);
            setInitCount(true);
          }
        }
      }
    }
    init();
  }, []);

  return (
    <Container
      visible={visible}
      closable={false}
      footer={
        <>
          <span style={{ marginRight: "5px" }}>
            Entendo e estou ciente das politicas da franquia
          </span>
          <Button
            disabled={count > 0}
            type="primary"
            onClick={() => setVisible(false)}
          >
            {count > 0 ? `Fechar em ${count}` : "Fechar"}
          </Button>
        </>
      }
    >
      <span>
        Identificamos que você possui pendencias cadastrais de empresas
      </span>
      <br />
      <span>
        Solicitamos que você realize a atualização dos cadastros das empresas
        para estar de acordo com a politica da franqueadora
      </span>
      {missingFields.map((missingField) => (
        <>
          <br />
          <br />
          <span>
            <strong>{missingField.company}</strong>
          </span>
          <br />
          <span>campos: {missingField.missingProps.join(", ")}</span>
          <br />
        </>
      ))}
    </Container>
  );
};

export default CompanyWarning;
