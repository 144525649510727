import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--white);
  border-radius: 0.6rem;
  border-top: 0.6rem solid var(--orange-100) !important;
  border: 1px solid rgba(141, 141, 141, 0.25);
  padding: 2.5rem;
  user-select: none;

  img {
    width: 45%;
  }

  h2 {
    font-size: 1.2rem;
    color: black;
    font-weight: 500;
    text-transform: uppercase;
  }

  p {
    color: var(--gray-550);
    font-size: 0.9rem;
    text-align: center;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55%;
    height: 8%;
    border: 1px solid var(--orange-100);
    border-radius: 5px;
    color: var(--orange-100);
    font-size: 1rem;
    transition: 0.2s;

    :hover {
      color: var(--white);
      background-color: var(--orange-100);
    }
  }

  @media only screen and (max-width: 1600px) {
    p {
      font-size: 0.85rem;
    }

    button {
      width: 60%;
      height: 10%;
    }
  }

  @media only screen and (max-width: 1550px) and (max-height: 750px) {
    h2 {
      font-size: 1rem;
    }

    p {
      font-size: 0.8rem;
    }
  }

  @media only screen and (max-width: 1440px) {
    h2 {
      font-size: 1rem;
    }

    p {
      font-size: 0.8rem;
    }
  }

  @media only screen and (max-width: 1366px) {
    h2 {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.7rem;
    }
  }

  @media only screen and (max-width: 575px) {
    justify-content: space-between;
    padding: 1.5rem;
    height: 20rem;
    img {
      width: 40%;
    }

    button {
      width: 100%;
      height: 15%;
    }
  }
`;
