import styled from "styled-components";
import {
  Modal as ModalAnt,
  Col as ColAnt,
  Row as RowAnt,
  Input as InputAnt,
  Form as FormAnt,
  Button,
} from "antd";

export const Modal = styled(ModalAnt)``;

export const CancelButton = styled(Button)`
  background: none;
  color: var(--gray-900);
  font-weight: 500;

  :hover,
  :focus,
  :active {
    background: none;
    color: var(--gray-900);
  }
`;

export const SaveButton = styled(Button)`
  background: var(--orange-100);
  color: var(--white);
  border-radius: 5px;

  :hover,
  :focus,
  :active {
    background: var(--orange-100);
    color: var(--white);
  }
`;

export const Col = styled(ColAnt)``;

export const Form = styled(FormAnt)`
  .ant-input {
    height: 3.2rem;
    border: 1px solid var(--gray-100);
    border-radius: 5px;
    outline: none;
    box-shadow: 0 0 0 0;
  }

  .ant-form-item-label {
    font-weight: 500;
    color: var(--gray-550);
  }
`;

export const Input = styled(InputAnt)``;

export const Row = styled(RowAnt)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;
