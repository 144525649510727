import styled from "styled-components";

import {
  Modal as ModalAnt,
  Table as TableAnt,
  Dropdown as DropdownAnt,
  Menu as MenuAnt,
} from "antd";

import { DotsHorizontal } from "../../styles/Icons";

export const Table = styled(TableAnt)`
  width: 100%;

  .actived {
    color: var(--green-700);
  }

  .expired {
    color: var(--red-600);
  }
  .no-actived {
    color: var(--gray-550);
  }

  .certificate {
    display: flex;
    flex-direction: column;
  }
  .expiration_date {
    font-size: 0.7rem;
  }
`;

export const MoreIcon = styled(DotsHorizontal)`
  width: 1.5rem;
  height: 1.5rem;
  color: var(--orange-100);
  cursor: pointer;
`;

export const Dropdown = styled(DropdownAnt)``;

export const Modal = styled(ModalAnt)``;

export const Menu = styled(MenuAnt)``;

export const ModalCompanyUsers = styled(ModalAnt)`
  width: 44rem;
  height: 53rem;
  .ant-modal-header {
    padding: 3rem 2rem 1rem;
  }
  .ant-modal-title {
    color: var(--gray-530);
    font-size: 2rem;
  }

  .ant-table-container,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border: none;
  }
  .ant-modal-body {
    padding-left: 3rem;
  }
`;

export const ContentOwnerDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Country = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;
