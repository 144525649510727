import React, { useEffect, useState } from "react";

import api from "../../services/api";

import { User as UserModel } from "../../models/entities/user";
import UserList from "../../containers/UserList";

import UserForm from "../../containers/UserForm";

import { verifyPermission } from "../../services/auth";

import PageContainer from "../../containers/PageContainer";
import Spinner from "../../components/Spinner";

import {
  Addicon,
  Col,
  Container,
  Content,
  Input,
  Row,
  SearchIcon,
  Select,
  Option,
  Header,
} from "./styles";

function handleFilter<T>(
  data,
  pattern?: string,
  selectCategory?: string,
  searchType?: string
): T[] {
  if (selectCategory) {
    data = data?.filter((entity) => entity["level"] === selectCategory);
  }

  if (pattern && searchType) {
    pattern = pattern?.toLowerCase();
    return data?.filter((entity) => {
      if (searchType === "email") {
        return entity["email"]?.toLowerCase()?.includes(pattern);
      } else if (searchType === "id") {
        return entity["id"]?.toString()?.toLowerCase()?.includes(pattern);
      } else {
        return entity["full_name"]?.toLowerCase()?.includes(pattern);
      }
    });
  }

  return data;
}

const User: React.FC = () => {
  const [users, setUsers] = useState<UserModel[]>([]);
  const [filter, setFilter] = useState<string | undefined>();
  const [user, setUser] = useState<UserModel | null>(null);
  const [shouldSearch, setShouldSearch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [visibleNewUser, setVisibleNewUser] = useState(false);
  const [visibleEditUser, setVisibleEditUser] = useState(false);
  const [profiles, setProfiles] = useState<UserModel[]>([]);
  const [filterSelect, setFilterSelect] = useState<string | undefined>(
    undefined
  );

  const [searchType, setSearchType] = useState<string>("name");

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const {
          data: { content },
        } = await api.get("/user");
        setUsers(content);

        const {
          data: { content: _profiles },
        } = await api.get("/user?is_profile=true");
        const sortedProfiles = _profiles.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setProfiles(sortedProfiles);
      } catch (error: any) {
      } finally {
        setShouldSearch(false);
        setLoading(false);
      }
    };

    if (shouldSearch) {
      fetchUser();
    }
  }, [shouldSearch]);

  return (
    <PageContainer route="Usuários">
      <Container>
        <Header>
          <Row>
            <Col sm={14} className="input-filter">
              <Input
                style={{ height: "2.8rem" }}
                value={filter}
                prefix={<SearchIcon />}
                placeholder={`Buscar usuário por ${
                  searchType ? searchType : "nome"
                }`}
                onChange={({ target: { value } }) => setFilter(value)}
              />

              <Select
                style={{ marginLeft: "1rem", height: "2.8rem" }}
                placeholder="Tipo de Pesquisa"
                onChange={(value) => setSearchType(value as string)}
                defaultValue="name"
              >
                <Option value="name">Nome</Option>
                <Option value="email">E-mail</Option>
                <Option value="id">ID</Option>
              </Select>
            </Col>

            <Col sm={6} xs={6}>
              <Select
                placeholder="Categoria"
                onChange={(value) =>
                  setFilterSelect(value as string | undefined)
                }
              >
                <Option value={1}>Master</Option>
                <Option value={2}>Full</Option>
                <Option value={3}>Admin</Option>
                <Option value={4}>Proprietário</Option>
                <Option value={5}>Usuário</Option>
              </Select>
            </Col>
          </Row>

          {verifyPermission("user.create_user") && (
            <button onClick={() => setVisibleNewUser(true)}>
              <Addicon />
              <span>Novo Usuário</span>
            </button>
          )}
        </Header>

        <Content>
          {loading ? (
            <Spinner />
          ) : (
            <UserList
              user={user}
              data={handleFilter<UserModel>(
                users,
                filter,
                filterSelect,
                searchType
              )}
              setUser={setUser}
              setShouldSearch={setShouldSearch}
              setVisibleEditUser={setVisibleEditUser}
            />
          )}
        </Content>
        <UserForm
          visible={visibleNewUser}
          setVisible={setVisibleNewUser}
          visibleEditUser={visibleEditUser}
          setVisibleEditUser={setVisibleEditUser}
          user={user}
          users={users}
          profiles={profiles}
          setUser={setUser}
          setShouldSearch={setShouldSearch}
        />
      </Container>
    </PageContainer>
  );
};

export default User;
