import React from 'react';
import moment from 'moment';
import { Company as CompanyModel } from "../../models/entities/company";
import { FranchiseeInfo } from '../../models/entities/franchisee';
import { Empty, Modal, Table } from 'antd';
import { FranchiseeFields } from '../../models/enums/FranchiseeFields';
import { ContentCapitalize } from './styles';

interface IProps {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    company: CompanyModel | null;
    auditFranchiseeData: FranchiseeInfo[];
}

const ModalFranchiseeAudit: React.FC<IProps> = ({ visible, setVisible, loading, auditFranchiseeData }) => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Campo',
            dataIndex: 'field',
            key: 'field',
            render: (text) => <span>{FranchiseeFields[text]}</span>,
        },
        {
            title: 'Alteração',
            dataIndex: 'companyFranchisee',
            key: 'companyFranchisee',
            render: (_, record) => {
                return (
                    <span>
                        {record.field === 'id' ? (
                            <span>{record.old_value} {"→"} {record?.new_value === null ? "removido" : record?.new_value}</span>
                        ) : (
                            record.field === 'company_percentage' ? (
                                <ContentCapitalize>
                                    {record?.companyFranchise?.user && record?.companyFranchise.user.name}: {record.old_value}{"%"} {"→"} {record.new_value && <span>{record.new_value}{"%"}</span>}
                                </ContentCapitalize>
                            ) : (
                                record.field === 'created_at' ? (
                                    <ContentCapitalize>
                                        {record?.companyFranchise?.user && record?.companyFranchise.user.name} {"→"} criado com {record?.companyFranchise.company_percentage}%
                                    </ContentCapitalize>
                                ) : (
                                    record.field === 'deleted_at' ? (
                                        <ContentCapitalize>{record?.companyFranchise?.user && record?.companyFranchise.user.name} {"→"} removido</ContentCapitalize>
                                    ) : null
                                )
                            )
                        )}
                    </span>
                );
            }
        },
        {
            title: 'Responsável',
            dataIndex: 'user_name',
            key: 'user_name',
            render: (text) => {
                return <span>{text}</span>
            }
        },
        {
            title: 'Data de atualização',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => {
                return <span>{moment(text).subtract(3, 'hours').format("DD/MM/YYYY HH:mm:ss")}</span>
            }
        }
    ];

    return (
        <Modal
            title="Auditoria do franqueado"
            visible={visible}
            centered
            destroyOnClose
            closable
            width={window.innerWidth > 600 ? '60%' : '100%'}
            footer={false}
            onCancel={() => {
                setVisible(false)
            }}
        >
            {auditFranchiseeData.length !== 0 ? (
                <Table
                    loading={loading}
                    columns={columns}
                    pagination={false}
                    dataSource={auditFranchiseeData}
                />
            ) : (
                <Empty description="Não há informações na loja selecionada!" />
            )}

        </Modal>
    )
}

export default ModalFranchiseeAudit;
