import React from "react";

import Spin from "../../assets/svg/spinner.svg";

import { Container } from "./styles";

const Spinner: React.FC = () => {
  return (
    <Container>
      <img src={Spin} alt="" />
    </Container>
  );
};

export default Spinner;
