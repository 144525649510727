import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Container,
  Input,
  ButtonAcess,
  ButtonCancel,
  ContentModalDiv,
  Select
} from "./styles";
import { Col, Form, Row, message, notification } from "antd";

import api from "../../services/api";

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setReload?: Dispatch<SetStateAction<boolean>>;
  userLevelArray: {
    description: string;
    value: number;
  }[]
}

const ModalAddUser: React.FC<IProps> = ({ visible, setVisible, setReload, userLevelArray }) => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [level, setLevel] = useState(0);
  const [form] = Form.useForm();

  const handleAddUser = async () => {
    if (username.length < 3) {
      notification.warning({
        message: "Nome inválido",
        description: "Informe um nome para o perfil de pelo menos 4 caracteres",
        duration: 3,
      });
      return;
    }
    if (level === 0) {
      notification.warning({
        message: "Nível inválido",
        description: "Informe um nível para o perfil",
        duration: 3,
      });
      return;
    }
    try {
      setLoading(true);
      await api.post("/user/profile", { name: username, level });
      setUsername("");
      if (setReload) setReload(true);
      setVisible(false);
    } catch (error: any) {
      message.error("Ocorreu um erro ao criar o perfil");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      title="Adicionar perfil"
      destroyOnClose
      onCancel={() => setVisible(false)}
      onOk={handleAddUser}
      visible={visible}
      centered
      footer={[
        <ContentModalDiv>
          <ButtonAcess onClick={handleAddUser} loading={loading}>
            Adicionar
          </ButtonAcess>
          <ButtonCancel onClick={() => setVisible(false)} disabled={loading}>
            Cancelar
          </ButtonCancel>
        </ContentModalDiv>,
      ]}
    >
      <Form layout="vertical" onFinish={() => { }} form={form}>
        <Row>
          <Col sm={24} xs={24}>
            <Form.Item
              label="Perfil"
              name="username"
              rules={[{ required: true, message: "Preencha o nome do perfil" }]}
            >
              <Input
                name="username"
                placeholder="Digite aqui"
                value={username}
                onChange={({ target: { value } }) => setUsername(value)}
              />
            </Form.Item>
          </Col>
          <Col sm={24} xs={24}>
            <Form.Item
              label="Nível"
              name="level"
              rules={[
                { required: true, message: "Preencha o level do perfil" },
              ]}
            >
              <Select
                placeholder="Digite aqui"
                value={level}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => setLevel(value as number)}
              >
                {userLevelArray.map((levelName) => (
                  <Select.Option key={levelName.value} value={levelName.value}>
                    {levelName.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default ModalAddUser;
