import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const MenuContainer = styled.div`
  display: flex;
  width: 14%;
  height: 100%;

  @media only screen and (max-width: 1440px) {
    width: 15%;
  }

  @media only screen and (max-width: 575px) {
    width: 0%;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  width: 86%;
  height: 100%;

  @media only screen and (max-width: 1440px) {
    width: 85%;
  }

  @media only screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const Page = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: var(--gray-20);

  @media only screen and (max-width: 575px) {
    background-color: var(--white);
  }
`;
