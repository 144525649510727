import styled, { css } from "styled-components";

import {
  Row as RowAnt,
  Col as ColAnt,
  Input as InputAnt,
  Select as SelectAnt,
  Table as TableAnt,
  Button as ButtonAnt,
} from "antd";
import { SearchOutline } from "../../styles/Icons";

const { Option: OptionAnt } = SelectAnt;

export const Row = styled(RowAnt)`
  display: flex;
  align-items: center;
`;

export const Col = styled(ColAnt)`
  padding: 0;
`;
export const Input = styled(InputAnt)``;

const IconCSS = css`
  width: 1.4rem;
  height: 1.4rem;
  color: var(--gray-700);
  margin-right: 1rem;
`;

export const SearchIcon = styled(SearchOutline)`
  ${IconCSS}
`;

export const CompanyContainer = styled.div`
  .ant-col {
    color: var(--red-600);
    font-weight: 500;
    font-size: 1.4rem;
  }

  .ant-input-affix-wrapper {
    border: 1px solid var(--gray-100);
    border-radius: 0.3rem;
    height: 2.7rem;
    input {
      margin-top: 0;
      height: 2.2rem !important;
      border: none !important;
    }
  }

  .ant-input,
  .ant-select-selector {
    height: 3.7rem;

    margin-top: 0.3rem;

    border-radius: 0.7rem;
    border: none;

    font-weight: 500 !important;

    outline: none;

    @media only screen and (max-width: 1366px) {
      height: 3.2rem;
    }

    @media only screen and (max-width: 1280px) {
      height: 3rem;
    }

    :focus,
    :active,
    :hover {
      outline: none;
      box-shadow: 0 0 0 0;
      border: none;
    }
  }
`;

export const Option = styled(OptionAnt)``;

export const Select = styled(SelectAnt)`
  width: 100% !important;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23.5rem;
  width: 100%;
`;

export const Table = styled(TableAnt)`
  width: 100%;
  margin-top: 1rem;

  .ant-table-container {
    border: 1px solid var(--gray-100);
    height: 23.5rem;
    overflow: scroll;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--green-300);
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: none;

    :hover {
      background: none;
    }
  }
`;

export const ButtonCancel = styled(ButtonAnt)`
  color: var(--orange-250);

  :active,
  :hover,
  :focus {
    color: var(--orange-250);
  }
`;

export const ButtonSave = styled(ButtonAnt)`
  background: var(--orange-250);
  color: white;
  border-radius: 1.2rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  :active,
  :hover,
  :focus {
    background: var(--orange-250);
    color: white;
  }
`;
