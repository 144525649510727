import React from "react";
import SideMenu from "../../components/SideMenu";
import { Container, MenuContainer, PageContainer, Page } from "./styles";

const Layout: React.FC = ({ children }) => {
  return (
    <Container>
      <MenuContainer>
        <SideMenu />
      </MenuContainer>

      <PageContainer>
        <Page>{children}</Page>
      </PageContainer>
    </Container>
  );
};

export default Layout;
