import axios from "axios";
import { getToken } from "./auth";
import https from "https";
import { logout } from "./auth";

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
  responseType: "json",
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

api.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      logout();
      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;
