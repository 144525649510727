import styled from 'styled-components';

export const Container = styled.div`

`;

export const MajoritarieFranchisee = styled.span`
  font-size: .9rem;
  font-weight: 700;
  text-transform: capitalize;
`
