import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import {
  LogOut,
  Layers,
  BuildingMultiple,
  Home,
  UserGroup,
  ViewGrid,
  MenuOutline,
  CloseOutline,
  FileEarmarkText,
  MailTemplate
} from "../../styles/Icons";

interface IResponsive {
  responsive?: boolean;
}

export const Container = styled.nav<IResponsive>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 7%;
  background-color: var(--black-opaco);

  .logout-mobile {
    display: none;
  }

  ${({ responsive }) =>
    responsive === true &&
    css`
      position: absolute;
      z-index: 1;

      .logout-mobile {
        position: fixed;
        top: 6.5%;
        right: 4%;
        display: unset;
        color: var(--white);
        z-index: 3;
      }
    `}
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17%;
  width: 70%;

  img {
    width: 85%;
  }

  @media screen and (max-width: 575px) {
    height: 35%;

    img {
      width: 60%;
    }
  }
`;

export const NavMenuContainer = styled.ul`
  display: flex;
  flex-direction: column;
  height: 68%;
  width: 100%;
  margin-top: 5%;

  @media screen and (max-width: 575px) {
    height: 65%;
    align-items: center;
    justify-content: center;
  }
`;

interface INavItem {
  actived?: boolean;
}

export const MenuContainer = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 11%;
  padding: 3% 0;
  border-top: 1px solid var(--gray-900);
  transition: 0.2s;

  :last-child {
    border-bottom: 1px solid var(--gray-900);
  }

  @media screen and (max-width: 1600px) {
    height: 12%;
  }

  @media screen and (max-width: 1366px) {
    height: 15%;
  }

  @media screen and (max-width: 575px) {
    border-top: none;
    height: 10%;
    :last-child {
      border-bottom: none;
    }
  }
`;

export const NavItem = styled(Link)<INavItem>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15%;
  width: 100%;
  height: 100%;
  font-size: 0.9rem;
  text-align: start;

  span {
    color: var(--gray-600);
  }

  :hover {
    span,
    svg {
      color: var(--white);
    }
  }

  ${({ actived }) =>
    actived &&
    css`
      border-radius: 0.9rem;
      background-color: var(--gray-800);

      span,
      svg {
        color: var(--white);
      }

      @media screen and (max-width: 575px) {
        background-color: transparent;
        &:before,
        &:after {
          content: "";
          flex: 1;
          height: 1px;
          margin: 0 1em;
          background: white !important;
          width: 100%;
        }
      }
    `}

  @media only screen and (max-width: 1680px) {
    padding: 0 15%;
  }

  @media only screen and (max-width: 1366px) {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 575px) {
    justify-content: center;
    font-size: 1rem;
    padding: 0;
    svg {
      display: none;
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  height: 15%;

  img {
    width: 3rem;
    height: 3rem;
    border-radius: 24px;
  }

  .perfil {
    color: var(--gray-600);
    font-size: 0.8rem;
  }

  @media only screen and (max-width: 1600px) {
    height: 17%;
  }

  @media only screen and (max-width: 1366px) {
    height: 21%;
  }

  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 55%;
  padding: 3%;

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 24px;
  }

  .info-user {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: 1rem;

    .name-user {
      color: var(--white-25);
      font-size: 0.8rem;
    }

    .role-user {
      color: var(--gray-600);
      font-size: 0.8rem;
    }
  }

  @media screen and (max-width: 575px) {
    display: none;
  }
`;

export const ButtonLogout = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45%;
  background-color: var(--gray-800);
  border-radius: 0.9rem;

  span,
  svg {
    color: var(--gray-50);
    font-size: 0.9rem;
  }
`;

const IconCSS = css`
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 1rem;
  color: var(--gray-600);

  @media only screen and (max-width: 1366px) {
    width: 1.1rem;
    height: 1.1rem;
  }
`;

export const LogOutIcon = styled(LogOut)`
  ${IconCSS}
  margin-right: 0.4rem;
  transform: rotate(180deg);
`;

export const ModuleIcon = styled(Layers)`
  ${IconCSS}
`;
export const CompaniesIcon = styled(BuildingMultiple)`
  ${IconCSS}
`;
export const HomeIcon = styled(Home)`
  ${IconCSS}
`;
export const UsersIcon = styled(UserGroup)`
  ${IconCSS}
`;
export const AccessIcon = styled(ViewGrid)`
  ${IconCSS}
`;

export const FileManagmenteIcon = styled(FileEarmarkText)`
  ${IconCSS}
`;

export const MailTemplateIcon = styled(MailTemplate)`
  ${IconCSS}
`;

const ResponsiveIcon = css`
  display: none;
  @media only screen and (max-width: 575px) {
    position: fixed;
    top: 2.5%;
    left: 3%;
    display: unset;
    height: 1.5rem;
    width: 1.5rem;
    color: var(--gray-700);
    z-index: 1;
  }
`;

export const MenuIcon = styled(MenuOutline)`
  ${ResponsiveIcon}
`;

export const CloseIcon = styled(CloseOutline)`
  ${ResponsiveIcon}
  color: var(--white) !important;
  z-index: 3 !important;
`;
