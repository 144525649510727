import React, { useState, useEffect } from "react";

import { Input } from "./styles";

type IProps = {
  onChange;
  value?: number;
  id?: string;
  disabled?: boolean;
};

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const MonetaryInput: React.FC<IProps> = ({ onChange, value, id, disabled }) => {
  const [amount, setAmount] = useState<number>(0);

  useEffect(() => {
    if (value) {
      setAmount(value);
    }
  }, [value]);

  const handleChange = (event, value) => {
    if (typeof value !== "number" && typeof value !== "string") {
      return;
    }
    event.preventDefault();
    setAmount(+value);
    onChange(+value);
  };

  return (
    <Input
      id={id}
      disabled={disabled}
      value={+amount || 0}
      currency="BRL"
      max={999999}
      //
      className="ant-input"
      config={currencyConfig}
      onChange={handleChange}
      onClick={({ target }) => {
        target.selectionStart = 10000;
      }}
    />
  );
};

export default MonetaryInput;
