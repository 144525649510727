import React from "react";

import { companyAudit } from "../../services/companyAudit";

import { CompanyFields } from "../../models/enums/CompanyFields";
import { Company as CompanyModel } from "../../models/entities/company";

import moment from "moment";

import { AuditStore } from "../CompanyList";

import { Empty, Table } from "antd";
import { Modal } from "./styles";

interface IProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldSearch: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  company: CompanyModel | null;
  auditData: AuditStore[];
}

const ModalAuditStore: React.FC<IProps> = ({
  visible,
  setVisible,
  setShouldSearch,
  loading,
  auditData,
}) => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      responsive: ["md"] as any,
      width: "10%",
      key: "id",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Data da alteração",
      dataIndex: "created_at",
      responsive: ["md"] as any,
      key: "created_at",
      width: "15%",

      render: (text) => {
        const formattedDate = moment(text).format("DD/MM/YYYY HH:mm:ss");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Campo",
      dataIndex: "field",
      key: "field",
      render: (text, record) => <span>{CompanyFields[text]}</span>,
    },
    {
      title: "Alteração feita",
      dataIndex: "old_value",
      key: "old_value",
      render: (_, record) => {
        return <span>{companyAudit(record)}</span>;
      },
    },
    {
      title: "Responsável",
      dataIndex: "user_name",
      key: "user_name",
      width: "15%",

      render: (text) => <span>{text}</span>,
    },
  ];

  return (
    <Modal
      title="Auditoria da loja"
      visible={visible}
      centered
      destroyOnClose
      closable
      width={800}
      footer={false}
      onCancel={() => {
        setVisible(false);
      }}
    >
      {auditData.length !== 0 ? (
        <Table
          loading={loading}
          columns={columns}
          dataSource={auditData}
          scroll={{
            x: 600,
            y: 400,
          }}
        />
      ) : (
        <Empty description="Não há histórico na loja selecionada!" />
      )}
    </Modal>
  );
};

export default ModalAuditStore;
