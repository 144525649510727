import styled from "styled-components";

import {
  Table as TableAnt,
  Checkbox as CheckboxAnt,
  Input as InputAnt,
  Col as ColAnt,
} from "antd";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 10%;
  margin: 1rem 0;
`;

export const ListContainer = styled.div`
  width: 100%;
  height: 90%;
`;

export const Table = styled(TableAnt)`
  padding: 10px;
  .ant-table-cell {
    text-transform: capitalize;
  }
`;

export const Checkbox = styled(CheckboxAnt)``;

export const Input = styled(InputAnt)`
  margin-left: 1rem;
  width: 35%;
`;

export const Col = styled(ColAnt)``;

export const RowName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NoContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
