import styled from "styled-components";

import { Folder, ThreeDots } from "../../styles/Icons";

import {
  Select as SelectAnt,
  Spin as SpinAnt,
  Table as TableAnt,
  Menu as MenuAnt,
  Dropdown as DropdownAnt,
  Button as ButtonAnt,
  Modal as ModalAnt,
  AutoComplete as AutoCompleteAnt,
  Row as RowAnt,
} from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-grow: 1;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 15%;
`;

export const Select = styled(SelectAnt)`
  width: 100%;
  text-transform: uppercase;
  text-align: initial;
  background-color: white;
  width: 30%;

  .ant-select-selection-search-input {
    height: 100% !important;
    color: var(--gray-45) !important;
    font-weight: 500 !important;
  }

  .ant-select-single:not(.ant-select-customize-input),
  .ant-select-selector {
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      color: var(--blue-900) !important;
      justify-content: flex-start !important;
    }

    height: 3.25rem !important;
    border-radius: 5px !important;
    outline: none !important;
    border: 1px solid var(--gray-50) !important;
    box-shadow: 0 0 0 0 !important;
    background: transparent !important;
    color: var(--gray-45) !important;
    font-weight: 500 !important;

    :focus,
    :active,
    :hover {
      outline: none;
      box-shadow: 0 0 0 0;
    }
  }

  .ant-select-arrow {
    color: var(--orange-350) !important;
  }

  @media only screen and (max-width: 578px) {
    width: 100%;

    .ant-select-selector {
      height: 3.25rem !important;
    }
  }
`;

export const CentralizeContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Spin = styled(SpinAnt)`
  .ant-spin-dot-item {
    background-color: orange;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 85%;
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 10%;
`;

export const MidContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 85%;
  background: var(--white);
  border-radius: 0.5rem;
`;

export const Table = styled(TableAnt)`
  width: 100%;
`;

export const Menu = styled(MenuAnt)`
  a {
    color: black;
  }
`;

export const Dropdown = styled(DropdownAnt)``;

export const ThreeDotsIcon = styled(ThreeDots)`
  width: 20px;
`;

export const Button = styled(ButtonAnt)`
  width: 15%;
  height: 40%;
  margin: 0 20px;
  background: #ffc65d;
  border-radius: 5px;
`;

export const Modal = styled(ModalAnt)``;

export const ModalContent = styled.div``;

export const AutoComplete = styled(AutoCompleteAnt)`
  width: 100%;
  margin: 20px 0px;
`;

export const Row = styled(RowAnt)`
  display: flex;
  justify-content: start;
  width: 100%;
  height: 7rem;
`;

export const FolderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 7rem;
  padding: 1rem;
  margin: 0.5rem;
  border: 1px solid var(--gray-150);
  border-radius: 0.5rem;
  overflow: hidden;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
    font-size: 0.8rem;
    width: 80%;
    text-align: center;
  }
`;

export const FolderIcon = styled(Folder)``;

export const ButtonReturn = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 2.25rem;
  margin: 0 20px;
  border: 1px solid var(--gray-250);
  border-radius: 0.4rem;
  color: black;
  background: var(--white);
  box-shadow: none;
  :hover,
  :focus {
    opacity: 80%;
    color: black;
    border: 1px solid var(--gray-250);
    background: var(--white);
    background: none;
    box-shadow: none;
  }
  `;
