import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import api from "../../services/api";
import moment from "moment";
import { login } from "../../services/auth";

import LogoImg from "../../assets/svg/logo.svg";
import RightImg from "../../assets/svg/background-right.svg";
import MailImg from "../../assets/svg/mail-icon.svg";
import PassImg from "../../assets/svg/pass-icon.svg";

import { message as messageAnt, Modal } from "antd";

import {
  Container,
  LeftSide,
  RightSide,
  Form,
  FormItem,
  Input,
  Button,
  Header,
  Logo,
  RecoverPassword,
  Col,
  Row,
  InputCode,
  ModalCodeVerify,
  MailIcon,
  PassIcon,
  FooterModal,
  ButtonModal,
  FormModal,
  H2Bold,
  HomologContent,
  ContentLinkBottom,
} from "./styles";

interface IProps extends RouteComponentProps {}

type RecouverPassword = {
  email?: string | undefined;
  pin?: string;
  new_password?: string;
  confirm_new_password?: string;
  digited_pin?: {
    first?: string | undefined;
    second?: string | undefined;
    third?: string | undefined;
    fourth?: string | undefined;
  };
  generated_at?: Date;
};

const Login: React.FC<IProps> = ({ history }) => {
  const [recouverPassword, setRecouverPassword] =
    useState<RecouverPassword | null>(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleCode, setIsVisibleCode] = useState(false);
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  const onFinish = async () => {
    try {
      setLoading(true);
      const payload = form.getFieldsValue();
      const {
        data: { access_token, modules, permissions, countries },
      } = await api.post("/user/login", payload);

      await login(
        access_token,
        JSON.stringify(modules),
        JSON.stringify(permissions),
        JSON.stringify(countries)
      );
      messageAnt.success(`Bem-vindo ${payload.username}`);
      history.push("/");
    } catch (error: any) {
      messageAnt.error(
        error.response?.data?.message || "Falha ao realizar o login"
      );
    } finally {
      setLoading(false);
    }
  };

  const sendVerifyCode = async () => {
    try {
      if (!recouverPassword?.email) {
        messageAnt.warning("Preencha um email válido");
        return;
      }
      setLoading(true);

      let oldPin: any = localStorage.getItem("@authenticator:PIN");
      oldPin = oldPin ? JSON.parse(oldPin) : null;

      if (
        oldPin &&
        moment(new Date()).isBefore(
          moment(oldPin.generated_at).add(5, "minutes")
        ) &&
        oldPin.email === recouverPassword?.email
      ) {
        setRecouverPassword((oldValues) => ({
          ...oldValues,
          pin: oldPin.pin,
          generated_at: oldPin.generated_at,
        }));
      } else {
        const {
          data: { content },
        } = await api.post("/user/recover-password", {
          email: recouverPassword?.email,
        });

        localStorage.setItem(
          "@authenticator:PIN",
          JSON.stringify({ ...content, email: recouverPassword?.email })
        );

        setRecouverPassword((oldValues) => ({
          ...oldValues,
          pin: content.pin,
          generated_at: content.generated_at,
        }));
      }
      setIsVisible(false);
      setIsVisibleCode(true);
    } catch (error: any) {
      messageAnt.error(
        error.response?.data?.message || "Falha ao enviar email"
      );
    } finally {
      setLoading(false);
    }
  };

  const verifyCode = async () => {
    try {
      const digitedPin = `${recouverPassword?.digited_pin?.first}${recouverPassword?.digited_pin?.second}${recouverPassword?.digited_pin?.third}${recouverPassword?.digited_pin?.fourth}`;
      if (digitedPin.length !== 4) {
        messageAnt.warning("Digite o PIN corretamente");
        return;
      }
      if (digitedPin === recouverPassword?.pin) {
        setIsVisibleCode(false);
        setIsVisiblePassword(true);
      } else {
        messageAnt.warning("PIN incorreto");
      }
    } catch (error: any) {
      messageAnt.error(
        error.response?.data?.message || "Falha ao enviar email"
      );
    } finally {
      setLoading(false);
    }
  };

  const updatePassword = async () => {
    try {
      if (
        recouverPassword?.new_password &&
        recouverPassword?.new_password.length < 8
      ) {
        messageAnt.warning("A senha precisa ter 8 caracteres");
        return;
      }

      if (
        !recouverPassword?.new_password?.match(/[A-Z]/) ||
        !recouverPassword?.new_password?.match(/[a-z]/) ||
        !recouverPassword?.new_password?.match(/\d/) ||
        !recouverPassword?.new_password?.match(
          /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/
        )
      ) {
        messageAnt.warning(
          "A senha precisa conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caracter especial"
        );
        return;
      }
      if (
        recouverPassword?.new_password !==
        recouverPassword?.confirm_new_password
      ) {
        messageAnt.warning("As senhas precisam ser iguais");
        return;
      }
      setLoading(true);
      await api.post("/user/update-password", {
        password: recouverPassword?.new_password,
        email: recouverPassword?.email,
      });
      messageAnt.success("Senha atualizada com sucesso");
      setIsVisiblePassword(false);
    } catch (error: any) {
      messageAnt.error(error.response?.data?.message || "Falha ao atualizar");
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const noSpacesValue = value.replace(/\s+/g, "");
    form.setFieldsValue({ username: noSpacesValue });
  };

  return (
    <Container>
      <Container>
        {process.env.REACT_APP_ENV === "homolog" && (
          <HomologContent>AMBIENTE DE HOMOLOGAÇÃO</HomologContent>
        )}
        <LeftSide>
          <Logo src={LogoImg} />
          <Header>
            <h1>Sistemas The Best Açaí</h1>
            <p>Insira seu usuário e senha para conectar</p>
          </Header>

          <Form layout="vertical" onFinish={onFinish} form={form}>
            <Row>
              <Col sm={24} xs={24}>
                <FormItem
                  label="Usuário"
                  name="username"
                  rules={[{ required: true, message: "Preencha seu usuário." }]}
                >
                  <Input
                    name="username"
                    placeholder="Digite aqui seu usuário"
                    prefix={<MailIcon src={MailImg} />}
                    onChange={handleEmailChange}
                  ></Input>
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col sm={24} xs={24}>
                <FormItem
                  label="Senha"
                  name="password"
                  rules={[{ required: true, message: "Preencha sua senha." }]}
                >
                  <Input
                    name="password"
                    placeholder="**********"
                    type="password"
                    prefix={<PassIcon src={PassImg} />}
                  />
                </FormItem>
              </Col>
            </Row>
            <RecoverPassword>
              <span onClick={() => setIsVisible(true)}>
                Esqueceu a sua senha?
              </span>
            </RecoverPassword>
            <Button htmlType="submit" type="primary" loading={loading}>
              Conectar
            </Button>

            <p>
              Está com algum problema? Entre em contato conosco{" "}
              <span>comunicathebestacai@gmail.com</span>.
            </p>
            <ContentLinkBottom>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://thebestacai.com.br/privacidade"
                className="privacity-term"
              >
                Aviso de Privacidade
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://gaa.digital/thebest"
                className="privacity-term"
              >
                Canal de denúncias
              </a>
            </ContentLinkBottom>
          </Form>
        </LeftSide>

        <RightSide src={RightImg}>
          <h1>REINVENTE-SE</h1>
          <p>Faça o seu melhor!</p>
        </RightSide>
      </Container>

      <Modal
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        footer={
          <FooterModal>
            <span className="spanCancel" onClick={() => setIsVisible(false)}>
              Cancelar
            </span>
            <ButtonModal
              htmlType="submit"
              type="primary"
              loading={loading}
              onClick={sendVerifyCode}
            >
              Recuperar
            </ButtonModal>
          </FooterModal>
        }
        centered={true}
        closable={false}
      >
        <H2Bold>Recuperar senha</H2Bold>
        <FormModal
          label="E-mail"
          name="e-mail"
          rules={[{ required: true, message: "Preencha seu E-mail." }]}
        >
          <Input
            name="email"
            placeholder="Digite aqui seu e-mail"
            onChange={({ target: { value } }) =>
              setRecouverPassword((oldValues) => ({
                ...oldValues,
                email: value,
              }))
            }
          />
        </FormModal>
      </Modal>

      <ModalCodeVerify
        visible={isVisibleCode}
        onCancel={() => setIsVisibleCode(false)}
        footer={null}
        centered={true}
        width={400}
        closable={false}
      >
        <h2>Acabamos de enviar um código para seu e-mail</h2>
        <p>
          Insira o código de verificação de 4 dígitos enviado para seu e-mail.
        </p>

        <Row>
          <Col sm={4} xs={4}>
            <InputCode
              maxLength={1}
              onChange={({ target: { value } }) =>
                setRecouverPassword((oldValues) => ({
                  ...oldValues,
                  digited_pin: {
                    ...oldValues?.digited_pin,
                    first: value,
                  },
                }))
              }
            />
          </Col>
          <Col sm={4} xs={4}>
            <InputCode
              maxLength={1}
              onChange={({ target: { value } }) =>
                setRecouverPassword((oldValues) => ({
                  ...oldValues,
                  digited_pin: {
                    ...oldValues?.digited_pin,
                    second: value,
                  },
                }))
              }
            />
          </Col>
          <Col sm={4} xs={4}>
            <InputCode
              maxLength={1}
              onChange={({ target: { value } }) =>
                setRecouverPassword((oldValues) => ({
                  ...oldValues,
                  digited_pin: {
                    ...oldValues?.digited_pin,
                    third: value,
                  },
                }))
              }
            />
          </Col>
          <Col sm={4} xs={4}>
            <InputCode
              maxLength={1}
              onChange={({ target: { value } }) =>
                setRecouverPassword((oldValues) => ({
                  ...oldValues,
                  digited_pin: {
                    ...oldValues?.digited_pin,
                    fourth: value,
                  },
                }))
              }
            />
          </Col>
        </Row>
        <span onClick={sendVerifyCode}>Reenviar código</span>

        <Button
          htmlType="submit"
          type="primary"
          loading={loading}
          onClick={verifyCode}
        >
          Recuperar
        </Button>
        <p>
          Se não encontrar o e-mail na sua caixa de entrada, verifique a pasta
          de spam.
        </p>
      </ModalCodeVerify>

      <Modal
        visible={isVisiblePassword}
        onCancel={() => setIsVisiblePassword(false)}
        footer={null}
        centered={true}
      >
        <h2>Recuperar senha</h2>
        <Row>
          <Col sm={24} xs={24} style={{ marginBottom: "1rem" }}>
            <Input
              type="password"
              name="password"
              placeholder="Digite a nova senha"
              onChange={({ target: { value } }) =>
                setRecouverPassword((oldValues) => ({
                  ...oldValues,
                  new_password: value,
                }))
              }
            />
            <Input
              type="password"
              name="password"
              placeholder="Confirme a nova senha"
              onChange={({ target: { value } }) =>
                setRecouverPassword((oldValues) => ({
                  ...oldValues,
                  confirm_new_password: value,
                }))
              }
              style={{ marginTop: "10px" }}
            />
          </Col>
          <Col sm={24} xs={24}>
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
              onClick={updatePassword}
            >
              Atualizar
            </Button>
          </Col>
        </Row>
      </Modal>
    </Container>
  );
};

export default withRouter(Login);
