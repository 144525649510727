import { datadogRum } from "@datadog/browser-rum";

const clientToken = process.env.REACT_APP_DD_TOKEN as string;

datadogRum.init({
  applicationId: '40dab077-7bbc-410e-8fda-36c07c630dd6',
  clientToken,
  site: "us3.datadoghq.com",
  service: "authenticator",
  env: "prod",
  version: "1.0.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

if (clientToken) datadogRum.startSessionReplayRecording();
