import React, { useState, useEffect } from "react";
import { message } from "antd";

import Spinner from "../../components/Spinner";
import CompanyList from "../../containers/CompanyList";
import PageContainer from "../../containers/PageContainer";
import CompanyForm from "../../containers/CompanyForm";

import country from "../../services/Countrys";
import allBrazilianStates from "../../models/enums/allBrazilianStates.json";
import { Company as CompanyModel } from "../../models/entities/company";
import { User as UserModel } from "../../models/entities/user";

import api from "../../services/api";

import { exportCSVFile } from "../../helpers/exportCSVFile";

import {
  Container,
  Header,
  Content,
  Col,
  Input,
  Select,
  Row,
  SearchIcon,
  Addicon,
  DownloadIcon,
  ContentButton,
} from "./styles";
import { stringWithoutCaracteres } from "../../services/stringWithoutCaracteres";
import { removeAccentsAndLowerCase } from "../../helpers/removeAccentsAndCaseSensitive";

const Company: React.FC = () => {
  const [companies, setCompanies] = useState<CompanyModel[]>([]);
  const [filter, setFilter] = useState<string | undefined>();
  const [filterState, setFilterState] = useState<string | undefined>();
  const [filterCountry, setFilterCountry] = useState<string | undefined>();
  const [filterStatus, setFilterStatus] = useState<string | undefined>();
  const [company, setCompany] = useState<CompanyModel | null>(null);
  const [shouldSearch, setShouldSearch] = useState(true);
  const [loading, setLoading] = useState(true);
  const [formCompanyVisible, setFormCompanyVisible] = useState(false);
  const [users, setUsers] = useState<UserModel[]>([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        setLoading(true);
        const {
          data: { content },
        } = await api.get("/company");
        setCompanies(content);
      } catch (error: any) {
        message.error("Oops! Não foi possível buscar os usuários vinculados!");
      } finally {
        setShouldSearch(false);
        setLoading(false);
      }
    };

    if (shouldSearch) {
      fetchCompanies();
    }
  }, [shouldSearch]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const {
          data: { content },
        } = await api.get(`/user`);
        const userFranchisee = content.filter((user) => user?.level === 4);
        setUsers(userFranchisee);
      } catch (error: any) {
        message.error("Oops! Não foi possível buscar os usuários!");
      }
    };

    fetchUsers();
  }, []);

  function handleFilterCompanyName(
    pattern?: string,
    stateSigla?: string,
    country?: string,
    company_status?: string
  ) {
    let filteredCompanies = companies;

    if (stateSigla) {
      filteredCompanies = filteredCompanies.filter(
        (entity) =>
          stateSigla === "" ||
          removeAccentsAndLowerCase(entity?.emitente_uf || "") ===
          removeAccentsAndLowerCase(stateSigla)
      );
    }

    if (country) {
      const normalizedCountry = removeAccentsAndLowerCase(country);
      filteredCompanies = filteredCompanies.filter((entity) =>
        removeAccentsAndLowerCase(entity?.country || "")?.includes(
          normalizedCountry
        )
      );
    }

    if (company_status) {
      if (company_status === "true") {
        filteredCompanies = filteredCompanies.filter(
          (entity) => entity.company_status === true
        );
      } else if (company_status === "false") {
        filteredCompanies = filteredCompanies.filter(
          (entity) => entity.company_status === false
        );
      }
    }

    if (pattern) {
      const normalizedPattern = removeAccentsAndLowerCase(pattern);
      filteredCompanies = filteredCompanies.filter((entity) => {
        return (
          removeAccentsAndLowerCase(entity?.company_name || "").includes(
            normalizedPattern
          ) ||
          removeAccentsAndLowerCase(entity?.cnpj || entity?.ruc || "").includes(
            normalizedPattern
          ) ||
          removeAccentsAndLowerCase(entity?.city || "").includes(
            normalizedPattern
          )
        );
      });
    }

    return filteredCompanies;
  }

  const downloadCSV = () => {
    const headers = {
      id: "ID DA LOJA",
      name: "NOME DA LOJA",
      franchisee: "ID E PORCENTAGEM",
    };

    const formattedInformations = () => {
      return companies.map((company) => ({
        id: company?.id,
        name: stringWithoutCaracteres(company?.company_name),
        franchisee: company?.companyFranchisee
          ? company.companyFranchisee
            .map(
              (user) =>
                `ID: ${user?.user_id}, PORCENTAGEM: ${user?.company_percentage}%`
            )
            .join(", ")
          : "",
      }));
    };

    const data = formattedInformations();
    exportCSVFile(headers, data, "Lojas");
  };

  return (
    <PageContainer route="Empresas">
      <Container>
        <Header>
          <Row gutter={6}>
            <Col sm={8} xs={24}>
              <Input
                value={filter}
                prefix={<SearchIcon />}
                placeholder="Buscar empresa"
                onChange={({ target: { value } }) => setFilter(value)}
                className="search-input"
                style={{ height: "3rem", alignItems: "center" }}
              />
            </Col>

            <Col sm={4} xs={24}>
              <Select
                showSearch
                placeholder="Status da loja"
                onChange={(value) => setFilterStatus(value?.toString())}
                value={filterStatus}
              >
                <Select.Option value="" key="todas">
                  Todas as lojas
                </Select.Option>
                <Select.Option value="true" key="ativa">
                  Ativa
                </Select.Option>
                <Select.Option value="false" key="inativa">
                  Inativa
                </Select.Option>
              </Select>
            </Col>

            <Col sm={6} xs={24}>
              <Select
                showSearch
                placeholder="Selecionar país"
                onChange={(value) => setFilterCountry(value?.toString())}
                value={filterCountry}
              >
                {country.map((country) => (
                  <Select.Option value={country.codigo} key={country.codigo}>
                    {country.nome}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col sm={6} xs={24}>
              <Select
                placeholder="Filtro de estado"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(value) => setFilterState(value?.toString())}
              >
                {allBrazilianStates.map((state) => (
                  <Select.Option value={state.sigla} key={state.sigla}>
                    {state.nome}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Row gutter={12}>
            <ContentButton>
              <Col sm={7} xs={12}>
                <button onClick={() => downloadCSV()} className="btnCsv">
                  <DownloadIcon />
                  <span>Download CSV</span>
                </button>
              </Col>
              <Col sm={7} xs={12}>
                <button
                  onClick={() => {
                    setCompany(null);
                    setFormCompanyVisible(true);
                  }}
                  className="btnModal"
                >
                  <Addicon />
                  <span>Nova Empresa</span>
                </button>
              </Col>
            </ContentButton>
          </Row>
        </Header>
        <Content>
          {loading ? (
            <Spinner />
          ) : (
            <CompanyList
              setVisible={setFormCompanyVisible}
              company={company}
              data={handleFilterCompanyName(
                filter,
                filterState,
                filterCountry,
                filterStatus
              )}
              setCompany={setCompany}
              setShouldSearch={setShouldSearch}
            />
          )}
        </Content>
        <CompanyForm
          setVisible={setFormCompanyVisible}
          visible={formCompanyVisible}
          company={company}
          setCompany={setCompany}
          setShouldSearch={setShouldSearch}
          users={users}
        />
      </Container>
    </PageContainer>
  );
};

export default Company;
