import styled from "styled-components";

import BannerImg from "../../assets/svg/banner.svg";
import BannerMobImg from "../../assets/svg/banner_mob.svg";

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 575px) {
    overflow-y: scroll;
  }
`;

export const BannerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 25%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.5%;
  border-radius: 0.6rem;
  background-image: url(${BannerImg});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.5% 2%;

  p {
    color: var(--white);
    font-weight: 400;

    span {
      color: var(--orange-100);
    }
  }

  h1 {
    color: var(--white);
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: 500;
  }

  @media only screen and (max-width: 1600px) {
    h1 {
      font-size: 2.5rem;
    }
  }

  @media only screen and (max-width: 1550px) and (max-height: 750px) {
    h1 {
      font-size: 2.2rem;
      margin-bottom: 1%;
    }

    p {
      font-size: 0.9rem;
    }
  }

  @media only screen and (max-width: 1366px) {
    h1 {
      font-size: 2rem;
      margin-bottom: 1%;
    }

    p {
      font-size: 0.9rem;
    }
  }

  @media only screen and (max-width: 575px) {
    height: 30%;
    background-image: url(${BannerMobImg});
    margin-bottom: 2%;
    padding: 5%;

    p {
      font-size: 0.8rem;
    }

    h1 {
      font-size: 1.5rem;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  height: 75%;

  @media only screen and (max-width: 575px) {
    height: 70%;
  }
`;

export const Cards = styled.div`
  ::-webkit-scrollbar {
    height: 0.7rem;
  }

  ::-webkit-scrollbar-track {
    background: var(--gray-500);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--gray-550);
    border-radius: 10px;
  }

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 25%;
  grid-gap: 1%;
  height: 100%;
  width: 100%;

  overflow-x: auto;

  @media only screen and (max-width: 575px) {
    display: grid;
    grid-gap: 1%;
    grid-auto-flow: row;
    grid-auto-columns: none;
    grid-template-columns: repeat(1, 1fr);
    overflow-x: none;
  }
`;
