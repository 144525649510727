import styled, { css } from "styled-components";
import { Grid3x3GapFill, Bell } from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  @media only screen and (max-width: 575px) {
    padding: 3%;
  }
`;

export const HomologContent = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 0, 0, 0.5);
  padding: 10px;
  color: white;
  z-index: 1;
  pointer-events: none;

  @media screen and (max-width: 768px){
    top: 25%;
    height: 2rem;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Header = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5%;
  width: 100%;
  padding: 0 1.5%;
  background: var(--white);

  h3 {
    margin-bottom: 0;
  }

  svg,
  h3 {
    color: var(--gray-700);
  }

  @media only screen and (max-width: 1550px) and (max-height: 750px) {
    h3 {
      font-size: 1rem;
    }
  }

  @media only screen and (max-width: 1440px) {
    h3 {
      font-size: 1rem;
    }
  }

  @media only screen and (max-width: 575px) {
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column-reverse;
    height: 10%;
    min-height: 3rem;
    margin-bottom: 1%;
    padding: 0;

    svg {
      margin-left: 1rem;
    }

    h3 {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 5%;
  height: 100%;

  img {
    display: none;
  }

  .actions-icons {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  @media only screen and (max-width: 575px) {
    width: 100%;
    height: 40%;

    img {
      display: none;
    }

    .actions-icons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 35%;
      height: 100%;

      
        @media only screen and (max-width: 575px) {
          justify-content: flex-end;
          width: 100%;
        }
    }
  }
`;

export const Body = styled.div`
  display: flex;
  height: 95%;
  padding: 1.5%;

  @media only screen and (max-width: 575px) {
    height: 90%;
    padding: 0;
  }
`;

export const Dropdown = styled.div`
  position: fixed;
  width: 10%;
  height: 30%;
  top: 5%;
  right: 4%;
  background-color: var(--white);
  border-radius: 3px;
  box-shadow: 0 8px 16px -4px rgb(9 30 66 / 25%), 0 0 0 1px rgb(9 30 66 / 8%);
  box-sizing: border-box;
  outline: 0;
  overflow: hidden;
  z-index: 1;

  @media only screen and (max-width: 575px) {
    width: 35%;
    height: 20%;
    top: 14%;
    right: 25%;
  }
`;

export const MenuContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10%;
  width: 100%;
  height: 100%;
  padding: 10%;
`;

export const Card = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;

  img {
    height: 2.5rem;
    width: 2.5rem;
    object-fit: contain;
  }

  span {
    text-align: center;
    width: 100%;
    font-size: 0.8rem;
    overflow-wrap: unset;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--gray-550);
  }

  @media only screen and (max-width: 1550px) and (max-height: 750px) {
    img {
      width: 2rem;
      height: 2rem;
    }

    span {
      font-size: 0.7rem;
    }
  }

  @media only screen and (max-width: 1440px) {
    img {
      width: 2rem;
      height: 2rem;
    }

    span {
      font-size: 0.7rem;
    }
  }
`;

const IconCSS = css`
  user-select: none;
  height: 1.2rem;
  width: 1.2rem;
  color: black;
  cursor: pointer;

  @media only screen and (max-width: 1550px) and (max-height: 750px) {
    height: 1rem;
    width: 1rem;
  }

  @media only screen and (max-width: 1440px) {
    height: 1rem;
    width: 1rem;
  }

  @media only screen and (max-width: 575px) {
    height: 1.2rem;
    width: 1.2rem;
  }
`;

export const AppIcon = styled(Grid3x3GapFill)`
  ${IconCSS}
`;

export const BellIcon = styled(Bell)`
  ${IconCSS}
`;
