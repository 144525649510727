const districtPY = [
  "Alto Paraguay (Fuerte Olimpo)",
  "Alto Paraná (Ciudad del Este)",
  "Amambay (Pedro Juan Caballero)",
  "Boquerón (Filadélfia)",
  "Caaguazú (Coronel Oviedo)",
  "Caazapá (Caazapá)",
  "Canindeyú (Salto del Guairá)",
  "Central (Areguá)",
  "Concepción (Concepción)",
  "Cordillera (Caacupé)",
  "Guairá (Villarrica)",
  "Itapúa (Encarnación)",
  "Misiones (San Juan Bautista)",
  "Ñeembucú (Pilar)",
  "Paraguarí (Paraguarí)",
  "Presidente Hayes (Villa Hayes)",
  "San Pedro (San Pedro)",
  "Distrito Capital (Asunción)",
];

export default districtPY;
