import React, { useState, useEffect } from "react";

import api from "../../services/api";
import { decodeToken } from "../../services/auth";
import brazilStates from "../../services/allBrazilStates";

import Spinner from "../../components/Spinner";

import { UserLevel } from "../../models/enums/UserLevel";

import { message as messageAnt, Checkbox } from "antd";

import {
  Col,
  Row,
  CompanyContainer,
  Select,
  Option,
  Table,
  Input,
  SearchIcon,
  SpinnerContainer,
} from "./styles";
import districtPY from "../../services/allParaguayDistrict";
import country from "../../services/Countrys";
import { removeAccentsAndLowerCase } from "../../helpers/removeAccentsAndCaseSensitive";

type Company = {
  key: number;
  value: number;
  title: string;
  disabled?: boolean;
  state: string;
  country?: string;
  distrito_py?: string;
  own_store?: boolean;
};

interface IProps {
  userId: number | undefined;
  handleChange: (companyIds: number[]) => void;
}

const Companies: React.FC<IProps> = ({ userId, handleChange }) => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [userCompanies, setUserCompanies] = useState<number[]>([]);
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [filterName, setFilterName] = useState<string | undefined>(undefined);
  const [filterState, setFilterState] = useState<string | undefined>(undefined);
  const [filterOwnStore, setFilterOwnStore] = useState(false);
  const [filterCountry, setFilterCountry] = useState<string | undefined>();
  const authenticatedUser = decodeToken().id;
  const level = decodeToken().level;

  const allStates = ["Todos os Estados", ...brazilStates];

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        setLoadingCompany(true);
        setUserCompanies([]);
        setCompanies([]);
        let _userCompanies;

        if (userId) {
          const {
            data: { content },
          } = await api.get(`/companyUser/${userId}/user`);

          _userCompanies = content.map((entity) => entity.company_id);
          setUserCompanies(_userCompanies);
        } else {
          _userCompanies = [];
        }

        if (level === UserLevel.MASTER) {
          const {
            data: { content },
          } = await api.get("/company");
          const _companies: Company[] = content.map((entity) => ({
            key: entity.id,
            value: entity.id,
            title: entity.company_name,
            state: entity.state_registration,
            country: entity.country,
            distrito_py: entity.distrito_py,
            own_store: entity.own_store,
          }));

          setCompanies(
            _companies
              .filter((companie) =>
                _userCompanies.some(
                  (userCompanie) => userCompanie === companie.value
                )
              )
              .concat(
                _companies.filter((companie) =>
                  _userCompanies.every(
                    (userCompanie) => userCompanie !== companie.value
                  )
                )
              )
          );
        } else {
          const {
            data: { content },
          } = await api.get(`/companyUser/${authenticatedUser}/user`);
          const _companies: Company[] = content.map((entity) => ({
            key: entity.company_id,
            value: entity.company_id,
            title: entity.company.company_name,
            state: entity.company.state_registration,
            country: entity.country,
            distrito_py: entity.distrito_py,
            own_store: entity.own_store,
          }));

          setCompanies(
            _companies
              .filter((companie) =>
                _userCompanies.some(
                  (userCompanie) => userCompanie === companie.value
                )
              )
              .concat(
                _companies.filter((companie) =>
                  _userCompanies.every(
                    (userCompanie) => userCompanie !== companie.value
                  )
                )
              )
          );
        }
      } catch {
        messageAnt.error("Falha ao obter empresas do usuário");
      } finally {
        setLoadingCompany(false);
      }
    };
    fetchCompanies();
  }, [authenticatedUser, userId, level]);

  useEffect(() => {
    const handleSaveCompanies = () => {
      try {
        if (userCompanies.length) {
          handleChange(userCompanies);
        } else {
          handleChange([]);
        }
      } catch {
        messageAnt.error("Falha na seleção de empresas");
      }
    };
    handleSaveCompanies();
  }, [userCompanies]);

  const columns = [
    {
      title: "ID da loja",
      dataIndex: "key",
      key: "key",
      sorter: (a, b) => a.key - b.key,
      showSorterTooltip: false,
    },
    {
      title: "Nome da loja",
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <span style={{ textTransform: "capitalize" }}>{text}</span>
      ),
    },
    {
      title: "Estado da loja",
      dataIndex: "state",
      key: "state",
      render: (_, record) => {
        if (record.country === "brasil") {
          return <span>{record.state}</span>;
        } else if (record.country === "paraguai") {
          return (
            <span style={{ textTransform: "capitalize" }}>
              {record.distrito_py}
            </span>
          );
        }
        return "Estado Não encontrado";
      },
    },
  ];

  const rowSelection = {
    onSelect: (record, selected) => {
      if (selected) {
        setUserCompanies((oldValues) => [...oldValues, record.value]);
      } else {
        setUserCompanies((oldValues) =>
          oldValues.filter((company) => company !== record.value)
        );
      }
    },
    onSelectAll: (selected, selectedRows) => {
      if (selected) {
        setUserCompanies(
          selectedRows
            .filter((record) => record && record.value !== undefined)
            .map((record) => record.value)
        );
      } else {
        setUserCompanies([]);
      }
    },
  };

  function handleFilterCompanyName(
    pattern?: string,
    state?: string,
    country?: string,
    ownStore?: boolean
  ) {
    let filteredCompanies = companies;

    if (ownStore) {
      filteredCompanies = filteredCompanies.filter(
        (entity) => entity.own_store
      );
    }

    if (country && country !== "Todos os Países") {
      filteredCompanies = filteredCompanies.filter(
        (entity) => entity.country === country
      );
    }

    if (state && state !== "Todos os Estados") {
      filteredCompanies = filteredCompanies.filter((entity) => {
        return entity.state ? entity.state.includes(state) : false;
      });
    }

    if (pattern) {
      filteredCompanies = filteredCompanies.filter((entity) => {
        return entity.title.toLowerCase().includes(pattern.toLowerCase());
      });
    }

    return filteredCompanies;
  }

  return (
    <CompanyContainer>
      <Row>
        <Col sm={6} xs={12} style={{ marginRight: "1.5rem" }}>
          <Input
            placeholder="Procurar loja"
            onChange={({ target: { value } }) => setFilterName(value)}
            prefix={<SearchIcon />}
          />
        </Col>

        <Col sm={6} xs={8} style={{ marginRight: "1.5rem" }}>
          <Select
            placeholder="Selecione algum estado"
            onChange={(value) => setFilterState(value as string | undefined)}
          >
            {allStates.map((state) => (
              <Option value={state} key={state}>
                {state}
              </Option>
            ))}
          </Select>
        </Col>
        <Col sm={4} xs={6}>
          <Select
            showSearch
            placeholder="Selecionar país"
            onChange={(value) => setFilterCountry(value?.toString())}
            value={filterCountry}
          >
            {country.map((country) => (
              <Select.Option value={country.codigo} key={country.codigo}>
                {country.nome}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>{" "}
      {(level === UserLevel.ADMIN ||
        level === UserLevel.FULL ||
        level === UserLevel.MASTER) && (
        <Row>
          <Col sm={4} xs={6} style={{ marginTop: "1rem" }}>
            <Checkbox
              onChange={({ target: { checked } }) => setFilterOwnStore(checked)}
            >
              Mostrar apenas lojas próprias
            </Checkbox>
          </Col>
        </Row>
      )}
      {loadingCompany ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <Table
          columns={columns}
          rowSelection={{
            ...rowSelection,
            selectedRowKeys: userCompanies,
          }}
          dataSource={handleFilterCompanyName(
            filterName,
            filterState,
            filterCountry,
            filterOwnStore
          )}
          scroll={{ y: 350 }}
          pagination={false}
        />
      )}
    </CompanyContainer>
  );
};

export default Companies;
