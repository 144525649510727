import React, { useState, useEffect } from "react";
import api from "../../services/api";

import Spinner from "../../components/Spinner";
import Upload from "../../components/Upload";
import PageContainer from "../../containers/PageContainer";

import swal from "sweetalert";

import { Module as ModuleModel } from "../../models/entities/module";

import { message as messageAnt, Form } from "antd";

import {
  Container,
  Collapse,
  PanelAnt,
  Header,
  Content,
  Button,
  AddIcon,
  EditIcon,
  SettingsIcon,
  Col,
  Input,
  Row,
  RowRouteList,
  RemoveIcon,
  ButtonCancel,
  ButtonSave,
  Modal,
  ButtonsPermission,
  Dropdown,
  Menu,
  MenuItem,
  MenusInfoContent,
  ModalAddRoute,
  BtnsAddRoute,
  BtnsNewModule,
  ModalNewModule,
  CollapseHeader,
  CollapseItem,
} from "./styles";

const Modules: React.FC = () => {
  const [modules, setModules] = useState<ModuleModel[]>([]);
  const [image, setImage] = useState<File | null>(null);
  const [shouldSearch, setShouldSearch] = useState(true);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [visibleAddRoute, setVisibleAddRoute] = useState(false);
  const [visibleAddPermission, setVisibleAddPermission] = useState(false);
  const [moduleForm] = Form.useForm();
  const [routeForm] = Form.useForm();
  const [permissionForm] = Form.useForm();

  useEffect(() => {
    const fetchModules = async () => {
      try {
        setLoading(true);
        const {
          data: { content },
        } = await api.get("/module");
        setModules(content);
      } catch (error) {
        messageAnt.error("Falha ao obter módulos");
      } finally {
        setLoading(false);
        setShouldSearch(false);
      }
    };

    if (shouldSearch) {
      fetchModules();
    }
  }, [shouldSearch]);

  useEffect(() => {
    if (visible) {
      setImage(null);
    }
  }, [visible]);

  const handleModule = async () => {
    try {
      await moduleForm.validateFields();
      const payload = moduleForm.getFieldsValue();
      if (!payload.id && !image) {
        swal("Oops", "Selecione uma imagem", "warning");
        return;
      }
      setSaving(true);
      const method = payload.id ? "put" : "post";
      const url = payload.id ? `/module/${payload.id}` : "/module";
      if (image) {
        const imageToUpload = new FormData();

        imageToUpload.append("file", image as File);

        const {
          data: { location, key },
        } = await api.post("/s3-upload/upload", imageToUpload);

        payload.image = location;
        payload.image_key = key;
      }
      await api[method](url, payload);
      swal("Módulo salvo com sucesso", "", "success");
      setShouldSearch(true);
      setVisible(false);
    } catch (error) {
      swal("Oops", "Preencha os campos corretamente.", "warning");
    } finally {
      setSaving(false);
    }
  };

  const handleRoute = async () => {
    try {
      await routeForm.validateFields();
      const payload = routeForm.getFieldsValue();
      setSaving(true);
      const method = payload.id ? "put" : "post";
      const url = payload.id ? `/route/${payload.id}` : "/route";
      await api[method](url, payload);
      swal("Salvo com sucesso", "", "success");
      setShouldSearch(true);
      setVisibleAddRoute(false);
    } catch (error) {
      swal(
        "Oops",
        "Erro ao salvar rota. Preencha o campo corretamente.",
        "warning"
      );
    } finally {
      setSaving(false);
    }
  };

  const handleDeleteRoute = async (id: number) => {
    try {
      swal({
        title: "Excluir rota",
        text: "Você tem certeza de que quer excluir esta rota?",
        icon: "warning",
        dangerMode: true,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Excluir",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then(async (willDelete) => {
        if (willDelete) {
          setSaving(true);
          await api.delete(`/route/${id}`);
          swal("Rota removida com sucesso!", "", "success");
        }
        setShouldSearch(true);
        setSaving(false);
      });
    } catch (error) {
      swal("Oops", "Erro ao excluir rota.", "error");
    } finally {
      setSaving(false);
    }
  };

  const handleDeletePermission = async (id: number) => {
    try {
      swal({
        title: "Excluir permissão",
        text: "Você tem certeza de que quer excluir esta permissão?",
        icon: "warning",
        dangerMode: true,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Excluir",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then(async (willDelete) => {
        if (willDelete) {
          setSaving(true);
          await api.delete(`/permission/${id}`);
          swal("Permissão removida com sucesso!", "", "success");
        }
        setShouldSearch(true);
        setSaving(false);
      });
    } catch (error) {
      swal("Oops", "Erro ao excluir permissão", "warning");
    } finally {
      setSaving(false);
    }
  };

  const handleDeleteModul = async (id: number) => {
    try {
      swal({
        title: "Excluir módulo",
        text: "Você tem certeza de que quer excluir este módulo?",
        icon: "warning",
        dangerMode: true,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Excluir",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then(async (willDelete) => {
        if (willDelete) {
          setSaving(true);
          await api.delete(`/module/${id}`);
          swal("Módulo removido com sucesso!", "", "success");
        }
        setShouldSearch(true);
        setSaving(false);
      });
    } catch (error) {
      swal("Oops", "Erro ao excluir Módulo", "warning");
    } finally {
      setSaving(false);
    }
  };

  const handlePermission = async () => {
    try {
      await permissionForm.validateFields();
      const payload = permissionForm.getFieldsValue();
      setSaving(true);
      const method = payload.id ? "put" : "post";
      const url = payload.id ? `/permission/${payload.id}` : "/permission";
      await api[method](url, payload);
      swal("Permissão salva com sucesso", "", "success");
      setShouldSearch(true);
      setVisibleAddPermission(false);
    } catch (error) {
      swal(
        "Oops!",
        "Falha ao salvar a permissão. Preencha os campos corretamente.",
        "error"
      );
    } finally {
      setSaving(false);
    }
  };

  return (
    <PageContainer route="Módulos">
      <Container>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Header>
              <Button onClick={() => setVisible(true)}>
                <AddIcon />
                Adicionar
              </Button>
            </Header>
            <Content>
              <Collapse expandIconPosition="left">
                <CollapseHeader>
                  <p>Módulos</p>
                  <p>Ações</p>
                </CollapseHeader>
                {modules.map((_module) => (
                  <PanelAnt
                    header={_module.name}
                    key={_module.id}
                    extra={
                      <Dropdown
                        overlay={
                          <Menu>
                            <MenusInfoContent>
                              <MenuItem
                                key="1"
                                onClick={() => {
                                  moduleForm.setFieldsValue({
                                    ..._module,
                                  });
                                  setVisible(true);
                                }}
                              >
                                <span>Editar</span>
                              </MenuItem>
                              <Menu.Item
                                onClick={() => {
                                  routeForm.setFieldsValue({
                                    module_id: _module.id,
                                  });
                                  setVisibleAddRoute(true);
                                }}
                                key="2"
                              >
                                <span>Adicionar rota</span>
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => handleDeleteModul(_module.id)}
                                key="3"
                              >
                                <span style={{ color: "var(--red-600)" }}>
                                  Excluir módulo
                                </span>
                              </Menu.Item>
                            </MenusInfoContent>
                          </Menu>
                        }
                        placement="bottomRight"
                        trigger={["click"]}
                      >
                        <SettingsIcon
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        />
                      </Dropdown>
                    }
                  >
                    {_module.routes.map((route) => (
                      <CollapseItem key={route.id}>
                        <PanelAnt
                          header={
                            route.name.toLowerCase() ===
                            _module.name.toLowerCase()
                              ? "Permissões Gerais"
                              : `/${route.name}`
                          }
                          key={route.id}
                          extra={
                            <Dropdown
                              overlay={
                                <Menu>
                                  <MenuItem
                                    key="1"
                                    onClick={() => {
                                      routeForm.setFieldsValue({
                                        ...route,
                                      });
                                      setVisibleAddRoute(true);
                                    }}
                                  >
                                    <span>Editar</span>
                                  </MenuItem>
                                  {!route.permissions.length && (
                                    <MenuItem
                                      onClick={() =>
                                        handleDeleteRoute(route.id)
                                      }
                                      key="2"
                                    >
                                      <span>Excluir</span>
                                    </MenuItem>
                                  )}
                                  <MenuItem
                                    key="3"
                                    onClick={() => {
                                      permissionForm.setFieldsValue({
                                        route_id: route.id,
                                      });
                                      setVisibleAddPermission(true);
                                    }}
                                  >
                                    <span>Adicionar Permissão</span>
                                  </MenuItem>
                                </Menu>
                              }
                              placement="bottomLeft"
                              trigger={["click"]}
                            >
                              <SettingsIcon
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              />
                            </Dropdown>
                          }
                        >
                          {route.permissions.map((permission) => (
                            <RowRouteList>
                              <Col sm={22} xs={22}>
                                <label key={permission.id}>
                                  {permission.description}
                                </label>
                              </Col>
                              <Col
                                sm={2}
                                xs={2}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  padding: "0 0.5%",
                                }}
                              >
                                <EditIcon
                                  onClick={() => {
                                    permissionForm.setFieldsValue({
                                      ...permission,
                                    });
                                    setVisibleAddPermission(true);
                                  }}
                                />

                                <RemoveIcon
                                  onClick={() =>
                                    handleDeletePermission(permission.id)
                                  }
                                />
                              </Col>
                            </RowRouteList>
                          ))}
                        </PanelAnt>
                      </CollapseItem>
                    ))}
                  </PanelAnt>
                ))}
              </Collapse>
            </Content>

            <ModalNewModule
              title="Adicionar novo módulo"
              centered
              visible={visible}
              onOk={() => setVisible(false)}
              onCancel={() => {
                moduleForm.resetFields();
                setVisible(false);
              }}
              closable={false}
              footer={
                <Row justify="end">
                  <BtnsNewModule>
                    <ButtonCancel
                      onClick={() => {
                        moduleForm.resetFields();
                        setVisible(false);
                      }}
                    >
                      Cancelar
                    </ButtonCancel>
                    <ButtonSave
                      type="primary"
                      loading={saving}
                      onClick={handleModule}
                    >
                      Salvar
                    </ButtonSave>
                  </BtnsNewModule>
                </Row>
              }
            >
              <Form layout="vertical" form={moduleForm} preserve={false}>
                <Row>
                  <Col sm={0} xs={0}>
                    <Form.Item name="id">
                      <Input name="id" />
                    </Form.Item>
                  </Col>
                  <Col sm={12}>
                    <Form.Item
                      label="Nome do módulo"
                      name="name"
                      rules={[{ required: true, message: "Nome obrigatório" }]}
                    >
                      <Input
                        name="name"
                        placeholder="Digite o nome do módulo"
                      />
                    </Form.Item>
                  </Col>

                  <Col sm={12}>
                    <Form.Item
                      label="Descrição do módulo"
                      name="description"
                      rules={[
                        { required: true, message: "Descrição obrigatória" },
                      ]}
                    >
                      <Input
                        name="description"
                        placeholder="Digite uma descrição para exibição"
                      />
                    </Form.Item>
                  </Col>

                  <Col sm={12}>
                    <Form.Item
                      label="URL do módulo"
                      name="url"
                      rules={[{ required: true, message: "URL obrigatório" }]}
                    >
                      <Input
                        name="url"
                        placeholder="Informe a URL de acesso para o módulo"
                      />
                    </Form.Item>
                  </Col>

                  <Col sm={12}>
                    <Form.Item
                      label="Upload de imagem"
                      rules={[
                        { required: false, message: "Imagem obrigatório" },
                      ]}
                    >
                      <Upload
                        file={image}
                        setFile={setImage}
                        typeFile="image/*"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </ModalNewModule>

            <ModalAddRoute
              title="Adicionar Rota"
              destroyOnClose={true}
              centered
              visible={visibleAddRoute}
              onOk={() => setVisibleAddRoute(false)}
              onCancel={() => {
                moduleForm.resetFields();
                setVisibleAddRoute(false);
              }}
              closable={false}
              footer={
                <Row justify="end">
                  <BtnsAddRoute>
                    <ButtonCancel
                      onClick={() => {
                        moduleForm.resetFields();
                        setVisibleAddRoute(false);
                      }}
                    >
                      Cancelar
                    </ButtonCancel>
                    <ButtonSave
                      type="primary"
                      htmlType="submit"
                      loading={saving}
                      onClick={handleRoute}
                    >
                      Salvar
                    </ButtonSave>
                  </BtnsAddRoute>
                </Row>
              }
            >
              <Form layout="vertical" form={routeForm} preserve={false}>
                <Col sm={0} xs={0}>
                  <Form.Item name="id">
                    <Input name="id" />
                  </Form.Item>
                </Col>
                <Col sm={0} xs={0}>
                  <Form.Item name="module_id">
                    <Input name="module_id" />
                  </Form.Item>
                </Col>
                <Row>
                  <Col sm={12}>
                    <Form.Item
                      label="Nome"
                      name="name"
                      rules={[{ required: true, message: "Nome obrigatório" }]}
                    >
                      <Input name="name" placeholder="Digite o nome da rota" />
                    </Form.Item>
                  </Col>

                  <Col sm={12}>
                    <Form.Item
                      label="Descrição"
                      name="description"
                      rules={[
                        { required: true, message: "Descrição obrigatória" },
                      ]}
                    >
                      <Input
                        name="description"
                        placeholder="Digite a descrição da rota"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </ModalAddRoute>

            <Modal
              title="Adicionar Permissão"
              destroyOnClose={true}
              centered
              visible={visibleAddPermission}
              onOk={() => setVisibleAddPermission(false)}
              onCancel={() => {
                moduleForm.resetFields();
                setVisibleAddPermission(false);
              }}
              closable={false}
              footer={
                <ButtonsPermission>
                  <ButtonCancel
                    onClick={() => {
                      moduleForm.resetFields();
                      setVisibleAddPermission(false);
                    }}
                  >
                    Cancelar
                  </ButtonCancel>
                  <ButtonSave
                    type="primary"
                    htmlType="submit"
                    loading={saving}
                    onClick={handlePermission}
                  >
                    Salvar
                  </ButtonSave>
                </ButtonsPermission>
              }
            >
              <Form layout="vertical" form={permissionForm} preserve={false}>
                <Row>
                  <Col sm={0} xs={0}>
                    <Form.Item name="id">
                      <Input name="id" />
                    </Form.Item>
                  </Col>
                  <Col sm={0} xs={0}>
                    <Form.Item name="route_id">
                      <Input name="route_id" />
                    </Form.Item>
                  </Col>
                  <Col sm={12}>
                    <Form.Item
                      label="Nome"
                      name="name"
                      rules={[{ required: true, message: "Nome obrigatório" }]}
                    >
                      <Input
                        name="name"
                        placeholder="Digite aqui o nome da rota"
                      />
                    </Form.Item>
                  </Col>

                  <Col sm={12}>
                    <Form.Item
                      label="Descrição"
                      name="description"
                      rules={[
                        { required: true, message: "Descrição obrigatório" },
                      ]}
                    >
                      <Input
                        name="description"
                        placeholder="Informe uma descrição para a permissão"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>
          </>
        )}
      </Container>
    </PageContainer>
  );
};

export default Modules;
