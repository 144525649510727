import styled from "styled-components";

import {
  Modal as ModalAnt,
  Form as FormAnt,
  Row as RowAnt,
  Col as ColAnt,
  Select as SelectAnt,
  Button,
} from "antd";

const { Option: OptionAnt } = SelectAnt;

export const Modal = styled(ModalAnt)`
  padding-bottom: 0;
  .stepForm {
    max-height: 20rem;
    overflow: auto;
  }
  .warning {
    color: red;
    font-size: 0.8rem;
  }
`;

export const CancelButton = styled(Button)`
  background: none;
  color: var(--gray-900);
  font-weight: 500;

  :hover,
  :focus,
  :active {
    background: none;
    color: var(--gray-900);
  }
`;

export const SaveButton = styled(Button)`
  background: var(--orange-100);
  color: var(--white);
  border-radius: 5px;

  :hover,
  :focus,
  :active {
    background: var(--orange-100);
    color: var(--white);
  }
`;

export const Form = styled(FormAnt)`
  .ant-divider-horizontal,
  .ant-divider-with-text {
    border-top-color: var(--gray-100);

    .ant-divider-inner-text {
      font-weight: 500;
      padding-left: 0;
      color: var(--red-600);
    }

    &::before {
      width: 0;
    }
  }

  .ant-input,
  .ant-select-selector,
  .ant-picker {
    width: 100%;
    height: 3rem !important;
    border: 1px solid var(--gray-100);
    border-radius: 5px;
    outline: none;
    box-shadow: 0 0 0 0;
  }

  .ant-form-item-label {
    font-weight: 500;
    color: var(--gray-550);
  }

  .ant-form-item {
    margin-bottom: 0.25rem;
  }

  @media only screen and (max-width: 1366px) {
    .ant-form-item {
      margin-bottom: 0.8rem;
    }

    .ant-input,
    .ant-select-selector,
    .ant-picker {
      height: 2.3rem !important;
      font-size: 0.8rem;
    }
    .ant-form-item-label {
      font-size: 0.8rem;
    }

    .ant-form-item-explain.ant-form-item-explain-error {
      font-size: 0.7rem;
    }
  }
`;

export const Row = styled(RowAnt)`
  display: flex;
  width: 100%;

  .span-certificate {
    cursor: pointer;
    color: var(--orange-150);
  }
`;

export const Col = styled(ColAnt)``;

export const FormUpload = styled(FormAnt.Item)`
  .dropzone {
    border: 1px dashed var(--gray-500);
  }
`;

export const Select = styled(SelectAnt)``;

export const Option = styled(OptionAnt)``;

export const Centralizer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
