import styled, { css } from "styled-components";

import { RecordCircle, Trash2Outline, Copy } from "../../styles/Icons";

import {
  Modal as ModalAnt,
  Form as FormAnt,
  Row as RowAnt,
  Col as ColAnt,
  Select as SelectAnt,
  Input as InputAnt,
  Space as SpaceAnt,
  Button,
} from "antd";

const { Option: OptionAnt } = SelectAnt;

export const Space = styled(SpaceAnt)`
  display: flex;
  justify-content: flex-start;
  align-items: unset;
`;

export const Modal = styled(ModalAnt)`
  padding-bottom: 0;

  .stepForm {
    max-height: 20rem;
    overflow: auto;
  }
  .warning {
    color: red;
    font-size: 0.8rem;
  }

  .ant-modal-body {
    max-height: 30rem;
    overflow: scroll;
  }
`;

export const DashedContractDiv = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px dashed #ccc;
`;

export const ContainerImg = styled.div`
  display: flex;
  align-items: center;
  img {
    position: relative;
    width: 7rem;
    height: 7rem;
    border: 1px solid var(--gray-250);
    border-radius: 0.5rem;
  }
`;

export const Row = styled(RowAnt)`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const RadioButtonContent = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
`;

export const Col = styled(ColAnt)``;

export const FormUpload = styled(FormAnt.Item)`
  .dropzone {
    border: 1px dashed var(--gray-500);
  }
`;

export const Select = styled(SelectAnt)`
  .ant-select-selector {
    border: 1px solid var(--gray-100) !important;
    border-radius: 5px !important;
  }
`;

export const Input = styled(InputAnt)`
  border: 1px solid var(--gray-100) !important;
  border-radius: 5px !important;
  height: 3rem !important;

  .ant-input {
    height: 2.4rem !important;
  }
`;

export const Option = styled(OptionAnt)``;

export const Form = styled(FormAnt)`
  .ant-divider-horizontal,
  .ant-divider-with-text {
    border-top-color: var(--gray-100);

    .ant-divider-inner-text {
      font-weight: 400;
      padding-left: 0;
      color: var(--red-600);
    }

    &::before {
      width: 0;
    }
  }

  .ant-input,
  .ant-select-selector,
  .ant-picker {
    width: 100%;
    height: 3rem !important;
    border: 1px solid var(--gray-100);
    border-radius: 5px;
    outline: none;
    box-shadow: 0 0 0 0;
  }

  .ant-form-item-label {
    font-weight: 400;
    color: var(--gray-550);
  }

  @media only screen and (max-width: 1366px) {
    .ant-form-item {
      margin-bottom: 0.8rem;
    }
    .ant-form-item-label {
      font-size: 0.8rem;
    }
    .ant-form-item-explain.ant-form-item-explain-error {
      font-size: 0.7rem;
    }
  }
`;

export const StepperConatiner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 4rem;

  label {
    font-weight: 400;
    color: var(--gray-550);

    span {
      color: var(--orange-100);
    }
  }

  @media only screen and (max-width: 1366px) {
    height: 3rem;

    label,
    span {
      font-size: 0.8rem;
    }
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
`;

interface ISelectedCountry {
  selected: boolean;
}
export const ButtonCountry = styled.button<ISelectedCountry>`
  padding: 0.5rem;
  ${({ selected }) =>
    selected === true &&
    css`
      border: 1px solid var(--orange-100);
      border-radius: 5px;
    `}
`;

interface ISteps {
  currentPage: number;
}

export const Country = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

export const Steps = styled.div<ISteps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .step-line1,
  .step-line2,
  .step-line3,
  .step-line4,
  .step-line5,
  .step-line6 {
    width: 25%;
    height: 0.5rem;
    background: var(--gray-150);
    content: "";
  }

  ${({ currentPage }) => {
    if (currentPage === 1) {
      return css`
        .step-icon1 {
          color: var(--orange-100);
        }
      `;
    }

    if (currentPage === 2) {
      return css`
        .step-icon1,
        .step-icon2 {
          color: var(--orange-100);
        }

        .step-line1 {
          background: var(--orange-100);
        }
      `;
    }

    if (currentPage === 3) {
      return css`
        .step-icon1,
        .step-icon2,
        .step-icon3 {
          color: var(--orange-100);
        }

        .step-line1,
        .step-line2 {
          background: var(--orange-100);
        }
      `;
    }
    if (currentPage === 4) {
      return css`
        .step-icon1,
        .step-icon2,
        .step-icon3,
        .step-icon4 {
          color: var(--orange-100);
        }

        .step-line1,
        .step-line2,
        .step-line3 {
          background: var(--orange-100);
        }
      `;
    }
    if (currentPage === 5) {
      return css`
        .step-icon1,
        .step-icon2,
        .step-icon3,
        .step-icon4,
        .step-icon5 {
          color: var(--orange-100);
        }

        .step-line1,
        .step-line2,
        .step-line3,
        .step-line4 {
          background: var(--orange-100);
        }
      `;
    }
    if (currentPage === 6) {
      return css`
        .step-icon1,
        .step-icon2,
        .step-icon3,
        .step-icon4,
        .step-icon5,
        .step-icon6 {
          color: var(--orange-100);
        }

        .step-line1,
        .step-line2,
        .step-line3,
        .step-line4,
        .step-line5 {
          background: var(--orange-100);
        }
      `;
    }
  }}
`;

export const StepIcon = styled(RecordCircle)`
  color: var(--gray-150);
  width: 1rem;
  height: 1rem;
`;

export const ContentUploadDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .delete {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    color: var(--red-600);
    font-size: 1rem;
  }
`;

export const TrashIcon = styled(Trash2Outline)`
  color: var(--red-600);
  width: 1.1rem;
  height: 1.1rem;
  cursor: pointer;
  margin-left: 10px;
`;

export const CancelButton = styled(Button)`
  background: none;
  color: var(--gray-900);
  font-weight: 400;

  :hover,
  :focus,
  :active {
    background: none;
    color: var(--gray-900);
  }
`;

interface IButtonColor {
  color: string;
}

export const AddOrRemoveButton = styled(Button) <IButtonColor>`
  background: transparent;
  color: ${(props) => props.color};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover:focus,
  :active {
    background: transparent !important;
    color: ${(props) => props.color} !important;
    opacity: 50% !important;
  }
`;

export const SaveButton = styled(Button)`
  background: var(--orange-100);
  color: var(--white);
  border-radius: 5px;

  :hover,
  :focus,
  :active {
    background: var(--orange-100);
    color: var(--white);
  }
`;

export const Centralizer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const IconCopy = styled(Copy)`
  width: 1.3rem;
`;

export const CopyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonCopy = styled(Button)`
  &&:hover,
  &&:focus {
    color: var(--orange-250);
  }
`;
