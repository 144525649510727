import React from "react";
import { getToken } from "../../services/auth";
import { Container } from "./styles";

type Module = {
  id: number;
  image: string;
  name: string;
  description: string;
  url: string;
};

interface IProps {
  _module: Module;
  url_twygo?: string;
}
const ModuleCard: React.FC<IProps> = ({ _module, url_twygo }) => {
  const handleAccess = () => {
    const link = document.createElement("a");
    let finalUrl = "";
    const fileExtensionRegex = /\.(exe|zip)$/i;

    if (_module.url.includes("https://universidade.franqueadosthebest.com")) {
      finalUrl = `${_module.url}${url_twygo}`;
    } else if (fileExtensionRegex.test(_module.url)) {
      finalUrl = _module.url;
    } else {
      finalUrl = `${_module.url}/login/${getToken()}`;
    }

    link.href = finalUrl;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Container>
      <img src={_module.image} alt="Logo" />
      <h2>{_module.name}</h2>
      <p>{_module.description}</p>
      <button onClick={handleAccess}>ACESSAR</button>
    </Container>
  );
};

export default ModuleCard;
