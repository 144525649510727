import styled from "styled-components";
import { Search, Edit, SendPlane } from "../../styles/Icons";
import {
  Input as InputAnt,
  Col as ColAnt,
  Row as RowAnt,
  Table as TableAnt,
  Tabs as TabsAnt,
  Button as ButtonAnt,
} from "antd";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 95%;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    height: fit-content;
    overflow-y: scroll;
  }
`;

export const Input = styled(InputAnt)`
  margin-right: 10px;
`;

export const ButtonAcess = styled(ButtonAnt)`
  background-color: var(--orange-100);
  color: var(--white);
  width: 8rem;
  height: 2.5rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover,
  :focus {
    background-color: var(--orange-100);
    color: var(--white);
    opacity: 80%;
  }

  @media screen and (max-width: 575px){
    margin-bottom: 0;
  }
`;

export const Row = styled(RowAnt)`
  display: flex;
  width: 100%;

  .ant-input-affix-wrapper {
    padding: 2% 5%;
    height: 100%;
  }
`;

export const Col = styled(ColAnt)``;

export const Tabs = styled(TabsAnt)`
  width: 100%;

  .content-switch {
    display: flex;
    align-items: center;
  }

  .name-switcher {
    margin-left: 1rem;
  }

  .ant-tabs-content-holder {
    margin-top: 3%;

    span {
      color: var(--gray-550);
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-nav-list {
    max-width: 100%;
  }

  .ant-tabs-nav {
    margin: 0;

    &::before {
      border-bottom: none;
    }

    .ant-tabs-tab {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--gray-550);
      color: var(--gray-550);
      border-radius: 5px;
      width: 11.5rem;
      max-width: 100%;
      height: 3rem;
    }

    .ant-tabs-tab-active {
      border: 1px solid var(--orange-100);
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: var(--orange-100);
      user-select: none;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 10px;
    }

    @media only screen and (max-width: 1600px) {
      .ant-tabs-tab {
        width: 10rem;
        height: 2.5rem;
        font-size: 0.8rem;
      }
    }

    @media only screen and (max-width: 1366px) {
      .ant-tabs-tab {
        width: 9rem;
        height: 2.3rem;
        font-size: 0.7rem;
      }

      .switch-span {
        font-size: 0.7rem;
        display: flex;
        align-items: center;

        span {
          margin-left: 1rem;
        }
      }
    }
  }
`;

export const Table = styled(TableAnt)`
  width: 100%;

  @media only screen and (max-width: 1600px) {
    tr {
      font-size: 0.8rem !important;
    }

    .ant-table-tbody {
      text-transform: capitalize;
    }
  }

  @media only screen and (max-width: 575px) {
    margin-top: .5rem;
  }

  @media only screen and (max-width: 1366px) {
    tr {
      font-size: 0.7rem !important;
    }
  }
`;

export const SearchIcon = styled(Search)`
  width: 1.2rem;
  height: 1.2rem;
  color: var(--gray-550);

  @media only screen and (max-width: 1366px) {
    width: 1rem;
    height: 1rem;
  }
`;

export const EditIcon = styled(Edit)`
  width: 1.2rem;
  height: 1.2rem;
  color: var(--white);
  margin-left: 0.8rem;

  cursor: pointer;

  @media only screen and (max-width: 1366px) {
    width: 1rem;
    height: 1rem;
  }
`;

export const SendPlaneIcon = styled(SendPlane)`
  width: 1.2rem;
  height: 1.2rem;
  color: var(--white);
  margin-left: 0.5rem;

  cursor: pointer;

  @media only screen and (max-width: 1366px) {
    width: 1rem;
    height: 1rem;
  }
`;

export const UserListContainer = styled.div`
  display: flex;
  width: 25%;
  height: 100%;

  .user-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: scroll;

     @media only screen and (max-width: 575px) {
      overflow: scroll;
      margin-top: .5rem;
    } 
  }

  @media only screen and (max-width: 575px) {
    width: 100%;
    min-height: 50%;
  }
`;

export const PermissionListContainer = styled.div`
  display: flex;
  width: 75%;
  height: 100%;
  margin-left: 1rem;

  @media only screen and (max-width: 575px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const PermissionListContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: var(--white);
  border: 1px solid rgba(205, 207, 213, 0.5);

  @media screen and (max-width: 575px) {
    border: none;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .footer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
    bottom: 0;
    width: 100%;
    height: 8%;
    background: var(--white-25);
    font-size: 0.9rem;

    .button-selectall {
      color: var(--gray-700);
      font-weight: 500;
    }

    .button-save {
      color: var(--orange-100);
      font-weight: 500;
    }
  }
`;

export const ModulesList = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 2%;

  .tab-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 99%;
    height: 30%;

    .selecteduser-name {
      position: absolute;
      align-items: center;
      display: flex;
      width: fit-content;
      font-size: 0.8rem;
      margin-bottom: 2%;
      font-weight: 500;
      text-transform: uppercase;
      background: var(--orange-100);
      color: var(--white);
      border-radius: 0 0 5px 5px;
      top: 0;
      padding: 0.5%;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

      @media only screen and (max-width: 1366px) {
        font-size: 0.7rem;
      }
    }
  }

  .tab-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    overflow: scroll;

    h4 {
      margin: 0;
    }

    .select-content {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      .SelectAll-input {
        margin-right: 0.5rem;
      }
    }

    span {
      font-size: 0.8rem;
    }

    .top-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #ccc;

      @media screen and (max-width: 575px) {
        padding: 1rem 1rem 1rem 0;
      }
    }

    .checkbox {
      display: flex;
      align-items: center;

      .content-span {
        margin-right: 1rem;
      }
    }

    .empty-permission {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
`;

export const PermissionList = styled.div`
  display: grid;
  width: 100%;
  height: fit-content;
  margin: 2% 0 3%;
  grid-template-columns: repeat(4, 1fr);

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
    margin-bottom: 10%;
  }

  @media only screen and (max-width: 1600px) {
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      font-size: 0.8rem;
    }
  }

  @media only screen and (max-width: 1400px) {
    grid-gap: 1%;
  }

  @media only screen and (max-width: 1366px) {
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      font-size: 0.7rem;
    }
  }

  @media only screen and (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
