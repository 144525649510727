import styled, { css } from "styled-components";

import {
  Input as InputAnt,
  Modal as ModalAnt,
  Button as ButtonAnt,
  Select as SelectAnt,
} from "antd";

export const Container = styled(ModalAnt)``;
const inputs = css`
  border: 1px solid #ccc !important;
  margin: 1rem 0;
  border-radius: 8px;
  height: 2.5rem;
`;
export const Select = styled(SelectAnt)`
  .ant-select-selector {
      border: 1px solid #ccc !important;
      border-radius: 8px !important;
      height: 2.5rem !important;
    }
`;
export const Input = styled(InputAnt)`
  ${inputs}
`;

export const ButtonAcess = styled(ButtonAnt)`
  background-color: var(--orange-100);
  color: var(--white);
  width: 8rem;
  height: 2.5rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover,
  :focus {
    background-color: var(--orange-100);
    color: var(--white);
    opacity: 80%;
  }
`;

export const ButtonCancel = styled(ButtonAnt)`
  background-color: transparent;
  color: var(--gray-900);
  width: 8rem;
  height: 2.5rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover,
  :focus {
    background-color: transparent;
    color: var(--gray-900);
    opacity: 80%;
  }
`;

export const ContentModalDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  flex-direction: row-reverse;
  justify-content: end;
  padding-top: 0.5rem;
`;
