import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import api from "../../services/api";

import { Company as CompanyModel } from "../../models/entities/company";

import { message, notification } from "antd";

import {
  Modal,
  Col,
  Row,
  CancelButton,
  SaveButton,
  Input,
  Form,
} from "./styles";

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  company: CompanyModel | null;
}

const NfceForm: React.FC<IProps> = ({
  visible,
  setVisible,
  setShouldSearch,
  company,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...company,
    });
  }, [company, form]);

  const handleSubmit = async (): Promise<void> => {
    try {
      await form.validateFields();
      Modal.confirm({
        title: "Atualizar dados NFC-e",
        content: "Deseja continuar e atualizar os dados da NFc-e?",
        okText: "Sim",
        okType: "primary",
        cancelText: "Não",
        okButtonProps: { loading },
        async onOk() {
          try {
            setLoading(true);
            const values = form.getFieldsValue();

            const payload = {
              token_focus_production: values.token_focus_production,
              token_focus_homologation: values.token_focus_homologation,
            };

            await api.put(`/company/${company?.id}`, payload);
            setVisible(false);
            setShouldSearch(true);
            message.success("NFCe cadastrada com sucesso!");
          }  catch (e) {
            //@ts-ignore
            const _description = e?.response?.data?.message;
    
            notification.error({
              message: `Erro ao vincular dados da loja`,
              description: _description,
              duration: 3,
            });
          } finally {
            setLoading(false);
          }
        },
      });
    } catch (error: any) {
      let filedsWithError: string[] = [];
      const { errorFields } = error;
      errorFields.forEach((errorField) => {
        filedsWithError.push(errorField.errors[0].split(" é obrigatório")[0]);
      });
      message.warning(
        "Os campos são obrigatórios para a habilitar a emissão da Nfc-e: " +
          filedsWithError.join(", ")
      );
    }
  };

  return (
    <Modal
      title={
        company?.token_focus_homologation && company.token_focus_production
          ? "Editar NFc-e"
          : "Cadastro NFc-e"
      }
      centered
      closable={false}
      visible={visible}
      confirmLoading={loading}
      width={700}
      destroyOnClose
      footer={[
        <CancelButton onClick={() => setVisible(false)}>
          Cancelar cadastro
        </CancelButton>,
        <SaveButton onClick={() => handleSubmit()}>
          Confirmar cadastro
        </SaveButton>,
      ]}
    >
      <Form layout="vertical" initialValues={{ remember: false }} form={form}>
        <Row>
          <Col sm={11}>
            <Form.Item
              label="Token Produção"
              name="token_focus_production"
              rules={[
                {
                  required: true,
                  message: "Token Produção é obrigatório",
                },
              ]}
            >
              <Input
                type="text"
                name="token_nfce_production_focus"
                placeholder="Token Produção"
              />
            </Form.Item>
          </Col>

          <Col sm={11}>
            <Form.Item
              label="Token Homologação"
              name="token_focus_homologation"
              rules={[
                {
                  required: true,
                  message: "Token Homologação é obrigatório",
                },
              ]}
            >
              <Input
                type="text"
                name="token_focus_homologation"
                placeholder="Token Homologação"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default NfceForm;
