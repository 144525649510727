import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { decodeToken } from "../../services/auth";

import PageContainer from "../../containers/PageContainer";
import Spinner from "../../components/Spinner";

import { User as UserModel } from "../../models/entities/user";
import { UserModule } from "../../models/entities/userModule";

import SelectedImg from "../../assets/svg/selected_user.svg";
import Setting from "../../assets/svg/settings.svg";

import { Checkbox, Empty, message, Select, Switch, notification } from "antd";

import {
  Container,
  Content,
  Input,
  SearchIcon,
  Row,
  UserListContainer,
  PermissionListContainer,
  Table,
  PermissionListContent,
  ModulesList,
  PermissionList,
  Tabs,
  EditIcon,
  SendPlaneIcon,
  ButtonAcess,
} from "./styles";

import ModalAddUser from "../../containers/ModalAddUser";

import { getTokenInfo } from "../../services/auth";
import { UserLevel } from "../../models/enums/UserLevel";

type AccessPermission = {
  id: number;
  name: string;
  actived: boolean;
  routes: {
    id: number;
    name: string;
    actived: boolean;
    permissions: {
      id: number;
      description: string;
      actived: boolean;
    }[];
  }[];
};

const Access: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [loadingPermission, setLoadingPermission] = useState(false);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserModel | null>(null);
  const [selectedModule, setSelectedModule] = useState(0);
  const [currentUserModules, setCurrentUserModules] = useState<UserModule[]>(
    []
  );
  const [filterName, setFilterName] = useState<string | undefined>();
  const [accessPermissions, setAccessPermissions] = useState<
    AccessPermission[]
  >([]);
  const [selectedEdit, setSelectedEdit] = useState(false);
  const [editedFullName, setEditedFullName] = useState(
    selectedUser?.full_name || ""
  );
  const [editedLevel, setEditedLevel] = useState(selectedUser?.level || "");
  const [shouldSearch, setShouldSearch] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const localStorageTab = localStorage.getItem("selectedTab") || "0";
  const [selectedTab, setSelectedTab] = useState<string>(localStorageTab);

  const [isMaster] = useState(getTokenInfo()?.level === UserLevel.MASTER);

  const userLevelArray = [
    { description: "Principal", value: 1 },
    { description: "Geral", value: 2 },
    { description: "Administrador", value: 3 },
    { description: "Proprietário", value: 4 },
    { description: "Usuário básico", value: 5 }
  ];

  useEffect(() => {
    localStorage.setItem("selectedTab", selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api.get(`/userModule/${decodeToken().id}/user`);
        setCurrentUserModules(data.content);
        const {
          data: { content },
        } = await api.get("/user?is_profile=true");

        const reloadUser = content.find((user) => user.id === selectedUser?.id);
        setSelectedUser(reloadUser);

        setUsers(content);
      } catch (error) {
        //@ts-ignore
        message.error("Erro ao buscar dados:", error);
      } finally {
        setLoading(false);
        setShouldSearch(false);
      }
    }

    if (shouldSearch) {
      fetchData();
    }
  }, [shouldSearch, selectedUser]);

  useEffect(() => {
    async function fetchData() {
      setLoadingPermission(true);
      try {
        const {
          data: { content: selectedUserModules },
        } = await api.get<{ content: UserModule[] }>(
          `/userModule/${selectedUser?.id}/user`
        );

        const response = currentUserModules.map((currentUserModule) => {
          const selectedUserModule = selectedUserModules.find(
            (selectedUserModule) =>
              selectedUserModule.module_id === currentUserModule.module_id
          );
          if (!selectedUserModule) {
            const response: AccessPermission = {
              id: currentUserModule.module_id,
              name: currentUserModule.module.name,
              actived: false,
              routes: currentUserModule.module.routes.map((route) => {
                const permissions = route.permissions.map((permission) => ({
                  id: permission.id,
                  description: permission.description,
                  actived: false,
                }));
                return {
                  id: route.id,
                  name: route.description,
                  actived: false,
                  permissions,
                };
              }),
            };
            return response;
          }
          const routes = currentUserModule.module.routes.map(
            (currentUserRoute) => {
              const selectedUserRoute = selectedUserModule.module.routes.find(
                (_selectedUserRoute) =>
                  _selectedUserRoute.id === currentUserRoute.id
              );
              if (!selectedUserRoute) {
                const permissions = currentUserRoute.permissions.map(
                  (permission) => ({
                    id: permission.id,
                    description: permission.description,
                    actived: false,
                  })
                );
                return {
                  id: currentUserRoute.id,
                  name: currentUserRoute.description,
                  actived: false,
                  permissions,
                };
              }
              const permissions = currentUserRoute.permissions.map(
                (currentUserPermission) => {
                  const selectedUserPermission =
                    selectedUserRoute.permissions.find(
                      (_selectedUserPermission) =>
                        _selectedUserPermission.id === currentUserPermission.id
                    );
                  if (!selectedUserPermission) {
                    return {
                      id: currentUserPermission.id,
                      description: currentUserPermission.description,
                      actived: false,
                    };
                  }
                  return {
                    id: currentUserPermission.id,
                    description: currentUserPermission.description,
                    actived: true,
                  };
                }
              );

              return {
                id: currentUserRoute.id,
                name: currentUserRoute.description,
                actived: true,
                permissions,
              };
            }
          );
          return {
            id: currentUserModule.module_id,
            name: currentUserModule.module.name,
            actived: true,
            routes,
          };
        });

        setAccessPermissions(response);
      } catch (error) {
        //@ts-ignore
        message.error(error);
      } finally {
        setShouldSearch(false);
        setLoadingPermission(false);
      }
    }

    if (selectedUser) {
      fetchData();
    }
  }, [currentUserModules, selectedUser]);

  const handleModule = async (
    isChecked: boolean,
    _accessPermission: AccessPermission
  ) => {
    setLoading(true);
    try {
      if (isChecked) {
        await api.post("/userModule", {
          module_id: +_accessPermission.id,
          user_id: selectedUser?.id,
        });
        message.success("Permissão de módulo adicionada com sucesso");
      } else {
        await api.delete(
          `/userModule/${selectedUser?.id} - ${_accessPermission.id}`
        );
        message.success("Permissão de módulo removida com sucesso");
      }

      const updatedPermissions = accessPermissions.map((accessPermission) => {
        if (accessPermission.id === _accessPermission.id) {
          return {
            ...accessPermission,
            actived: isChecked,
          };
        } else {
          return accessPermission;
        }
      });
      setAccessPermissions(updatedPermissions);
    } catch (error: any) {
      if (isChecked) {
        message.error(`Falha ao adicionar permissão de módulo ao usuário`);
      } else {
        message.error(`Falha ao remover permissão de módulo do usuário `);
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePermission = async (
    isChecked: boolean,
    permission_id: number,
    routeIndex: number
  ) => {
    setLoadingPermission(true);
    try {
      if (isChecked) {
        await api.post("/userPermission", {
          user_id: selectedUser?.id,
          module_id: accessPermissions[selectedModule].id,
          route_id: accessPermissions[selectedModule].routes[routeIndex].id,
          permission_id,
        });
        message.success("Permissão adicionada com sucesso");
      } else {
        await api.delete(
          `/userPermission/${selectedUser?.id}-${permission_id}`
        );
        message.success("Permissão removida com sucesso");
      }
      const updatedPermissions = accessPermissions.map(
        (accessPermission, moduleIndex) => {
          if (moduleIndex !== selectedModule) {
            return accessPermission;
          } else {
            const routes = accessPermission.routes.map((route, routeIndex) => {
              if (routeIndex !== routeIndex) {
                return route;
              } else {
                const permissions = route.permissions.map((permission) => {
                  if (permission.id !== permission_id) {
                    return permission;
                  } else {
                    return {
                      ...permission,
                      actived: isChecked,
                    };
                  }
                });
                return {
                  id: route.id,
                  name: route.name,
                  actived: route.actived,
                  permissions,
                };
              }
            });
            return {
              id: accessPermission.id,
              name: accessPermission.name,
              actived: accessPermission.actived,
              routes,
            };
          }
        }
      );
      setAccessPermissions(updatedPermissions);
    } catch {
      if (isChecked) {
        message.error(`Falha ao adicionar permissão de módulo ao usuário`);
      } else {
        message.error(`Falha ao remover permissão de módulo do usuário `);
      }
    } finally {
      setLoadingPermission(false);
    }
  };

  const handleRoutePermissions = async (isChecked, route_id) => {
    try {
      setLoading(true);
      await api.post("/userPermission/route", {
        type: isChecked ? "add" : "remove",
        user_id: selectedUser?.id,
        route_id: route_id,
      });

      notification.success({
        message: `Permissões ${isChecked ? "adicionadas" : "removidas"
          } com sucesso`,
        duration: 3,
      });

      setShouldSearch(true);
    } catch (error) {
      notification.warning({
        message: "Houve um erro ao realizar a operação",
        duration: 3,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleModulePermissions = async (isChecked, module_id) => {
    setLoading(true);
    try {
      await api.post("/userPermission/module", {
        type: isChecked ? "add" : "remove",
        user_id: selectedUser?.id,
        module_id,
      });

      notification.success({
        message: `Permissões ${isChecked ? "adicionadas" : "removidas"
          } com sucesso`,
        duration: 3,
      });

      setShouldSearch(true);
    } catch (error) {
      notification.warning({
        message: "Houve um erro ao realizar a operação",
        duration: 3,
      });
    } finally {
      setLoading(false);
    }
  };

  function handleFilter<T>(data, prop, pattern?: string): T[] {
    if (pattern) {
      return data.filter(function (entity) {
        return entity[prop]?.toLowerCase()?.includes(pattern.toLowerCase());
      });
    }
    return data;
  }

  useEffect(() => {
    setEditedFullName(selectedUser?.full_name || "");
    setEditedLevel(selectedUser?.level || "");
  }, [selectedUser]);

  const handleEditName = async () => {
    try {
      await api.put(`/user/${selectedUser?.id}`, {
        full_name: editedFullName,
        name: editedFullName,
        level: editedLevel || null,
      });
      message.success("Edição realizada com sucesso");
      setLoadingPermission(false);
      setSelectedEdit(false);
      setShouldSearch(true);
    } catch (error) {
      message.error("Ocorreu um erro");
      setShouldSearch(false);
    }
  };

  const columns = [
    {
      title: "ID ",
      dataIndex: "id",
      key: "id",
      width: 80,
    },
    {
      title: "Nome",
      dataIndex: "full_name",
      key: "full_name",
      width: 150,
      ellipsis: true,
      sorter: (a, b) => a.full_name.localeCompare(b.full_name),
      defaultSortOrder: "ascend" as any,
    },
  ];

  return (
    <PageContainer route="Opções de acesso">
      <Container>
        {isMaster && (
          <>
            <ButtonAcess onClick={() => setOpenModal(true)}>
              Adicionar perfil
            </ButtonAcess>
          </>
        )}
        <Content>
          {loading ? (
            <Spinner />
          ) : (
            <UserListContainer>
              <div className="user-list">
                <Row>
                  <Input
                    placeholder="Buscar usuário por nome"
                    prefix={<SearchIcon />}
                    onChange={({ target: { value } }) => setFilterName(value)}
                    style={{ width: "100%", margin: 0 }}
                  />
                </Row>
                <>
                  <Table
                    columns={columns}
                    dataSource={handleFilter<UserModel>(
                      users,
                      "full_name",
                      filterName
                    )}
                    pagination={false}
                    scroll={{ y: 1000 }}
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          setSelectedUser(record as UserModel);
                        },
                      };
                    }}
                  />
                </>
              </div>
            </UserListContainer>
          )}

          <PermissionListContainer>
            <PermissionListContent>
              {!selectedUser ? (
                <Empty
                  description="Nenhum usuário selecionado"
                  image={SelectedImg}
                  imageStyle={{
                    height: 240,
                  }}
                />
              ) : (
                <>
                  <ModulesList>
                    <div className="tab-container">
                      <span className="selecteduser-name">
                        {selectedEdit ? (
                          <>
                            <Input
                              onPressEnter={(e) =>
                                e.key === "Enter" && handleEditName()
                              }
                              value={editedFullName}
                              onChange={(e) =>
                                setEditedFullName(e.target.value)
                              }
                            />
                            <Select
                              placeholder="Selecione uma opção"
                              style={{width: '10rem'}}
                              value={editedLevel}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(value) => setEditedLevel(value as number)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleEditName();
                                }
                              }}
                            >
                              {userLevelArray.map((levelName) => (
                                <Select.Option key={levelName.value} value={levelName.value}>
                                  {levelName.description}
                                </Select.Option>
                              ))}
                            </Select>
                          </>
                        ) : (
                          <>
                            <div>
                              [{selectedUser.id}] {selectedUser.full_name} [
                              {selectedUser.level}]
                            </div>
                          </>
                        )}
                        <>
                          {isMaster && (
                            <>
                              {" "}
                              {selectedEdit ? (
                                <SendPlaneIcon onClick={handleEditName} />
                              ) : (
                                <EditIcon
                                  onClick={() => setSelectedEdit(!selectedEdit)}
                                />
                              )}
                            </>
                          )}
                        </>
                      </span>
                      <Tabs
                        onChange={(value) => {
                          setSelectedTab(value);
                          setSelectedModule(+value);
                        }}
                        activeKey={selectedModule.toString()}
                      >
                        {accessPermissions.map((accessPermission, index) => (
                          <Tabs.TabPane tab={accessPermission.name} key={index}>
                            <div className="content-switch">
                              <Switch
                                disabled={!isMaster}
                                onChange={(event) =>
                                  handleModule(event, accessPermission)
                                }
                                checked={accessPermission.actived}
                              />
                              <div className="switch-span">
                                <span className="name-switcher">Habilitar</span>{" "}
                                {accessPermission.name}
                              </div>
                            </div>
                          </Tabs.TabPane>
                        ))}
                      </Tabs>
                    </div>
                    {loadingPermission ? (
                      <Spinner />
                    ) : (
                      <>
                        <div className="tab-content">
                          {accessPermissions.map((module, moduleIndex) => (
                            <div
                              key={moduleIndex}
                              style={{
                                display:
                                  moduleIndex === selectedModule
                                    ? "block"
                                    : "none",
                              }}
                            >
                              {module.actived ? (
                                <>
                                  <div className="select-content">
                                    <Checkbox
                                      disabled={loading || !isMaster}
                                      prefixCls="SelectAll"
                                      checked={accessPermissions[
                                        moduleIndex
                                      ].routes.every((route) =>
                                        route.permissions.every(
                                          (permission) => permission.actived
                                        )
                                      )}
                                      onChange={async ({
                                        target: { checked },
                                      }) =>
                                        await handleModulePermissions(
                                          checked,
                                          module.id
                                        )
                                      }
                                    >
                                      Selecionar todas as permissões de{" "}
                                      {accessPermissions[selectedModule]?.name}
                                    </Checkbox>
                                  </div>
                                  {module.routes.map((route, routeIndex) => (
                                    <div key={routeIndex}>
                                      <div className="top-content">
                                        <h4>{route.name}</h4>

                                        <div className="checkbox">
                                          <span className="content-span">
                                            Selecionar tudo
                                          </span>
                                          <Checkbox
                                            disabled={loading || !isMaster}
                                            prefixCls="SelectAll"
                                            checked={accessPermissions[
                                              moduleIndex
                                            ].routes[
                                              routeIndex
                                            ].permissions.every(
                                              (permission) => permission.actived
                                            )}
                                            onChange={async ({
                                              target: { checked },
                                            }) =>
                                              await handleRoutePermissions(
                                                checked,
                                                route.id
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      <PermissionList>
                                        {route.permissions.map((permission) => (
                                          <Checkbox
                                            disabled={!isMaster}
                                            key={permission.id}
                                            checked={permission.actived}
                                            onChange={({
                                              target: { checked },
                                            }) => {
                                              handlePermission(
                                                checked,
                                                permission.id,
                                                routeIndex
                                              );
                                            }}
                                          >
                                            {permission.description}
                                          </Checkbox>
                                        ))}
                                      </PermissionList>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className="empty-permission">
                                  <Empty
                                    description={`Oops! Esse usuário não tem permissão para o módulo ${module.name}.`}
                                    image={Setting}
                                    imageStyle={{
                                      height: 250,
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </ModulesList>
                </>
              )}
            </PermissionListContent>
          </PermissionListContainer>
        </Content>
      </Container>

      <ModalAddUser
        visible={openModal}
        setVisible={setOpenModal}
        setReload={setShouldSearch}
        userLevelArray={userLevelArray}
      />
    </PageContainer>
  );
};

export default Access;
