import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import api from "../../services/api";
import swal from "sweetalert";

import { message as messageAnt, Menu, Dropdown, Row, Col, Input } from "antd";

import {
  Table,
  MoreIcon,
  BtnsModalUser,
  ButtonCancel,
  ButtonSave,
  FormItem,
  Form,
  ModalEditPass,
  Modal,
  LevelSpan,
} from "./styles";

import { User as UserModel } from "../../models/entities/user";
import { verifyPermission } from "../../services/auth";

interface IProps {
  user?: UserModel | null;
  data: UserModel[];
  setUser: Dispatch<SetStateAction<UserModel | null>>;
  setVisibleEditUser: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}
const UserList: React.FC<IProps> = ({
  user,
  data,
  setUser,
  setShouldSearch,
  setVisibleEditUser,
}) => {
  const [visibleEditPassModal, setVisibleEditPassModal] =
    useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (user) {
      delete user.password;
      form.setFieldsValue({
        ...user,
        validated_email: user.email,
        profile_permission: user.profile_permission?.split(".")[1],
      });
    }
  }, [user, form]);

  const onFinishPassword = async () => {
    await form.validateFields();
    try {
      setLoading(true);
      let payload = form.getFieldsValue();
      delete payload.validated_password;
      const url = payload.id ? `/user/${payload.id}` : "/user";
      await api.put(url, { ...payload });
      swal("Senha alterada com sucesso", "", "success");
      setShouldSearch(true);
    } catch (err: any) {
      setLoading(false);
      const error = err.response?.data?.message || "Falha ao trocar senha";

      swal("Oops!", Array.isArray(error) ? error.join(", ") : error, {
        icon: "error",
      });
    } finally {
      setUser(null);
      setLoading(false);
    }
  };

  const onDelete = async (
    id: number | undefined,
    image_key: string | undefined
  ) => {
    Modal.confirm({
      title: "Atenção",
      content: "Deseja realmente remover permanentemente o usuário?",
      width: 500,
      centered: true,
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",

      async onOk() {
        try {
          setLoading(true);
          await api.delete(`/s3-upload/${image_key}`);
          await api.delete(`/user/${id}`);
          setShouldSearch(true);
          swal("Usuário excluído com sucesso", "", "success");
        } catch (error: any) {
          messageAnt.error(error.response.data.message);
        } finally {
          setUser(null);
          setLoading(false);
        }
      },
    });
  };

  const onUpdatePass = async (id: UserModel) => {
    setVisibleEditPassModal(true);
    setUser(id);
  };

  const validatePasswordConfirm = (value) => {
    const password = form.getFieldValue("password");
    if (password !== value) {
      return Promise.reject("As senhas informadas devem ser iguais");
    }
    return Promise.resolve();
  };

  const formatProfileLevel = (level) => {
    const profileLevels = {
      1: "Principal",
      2: "Geral",
      3: "Administrador",
      4: "Proprietário",
    };
  
    return profileLevels[level] || "Usuário básico";
  };

  const columns = [
    {
      title: "ID do Usuário",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nome",
      dataIndex: "full_name",
      key: "full_name",
      ellipsis: true,
      render: (text) => <span style={{textTransform: "capitalize"}}>{text}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      responsive: ["md"] as any,
    },
    {
      title: "Nivel",
      dataIndex: "level",
      key: "level",
      render: (text, record) => <LevelSpan>{formatProfileLevel(record.level)}</LevelSpan>,
    },    
    {
      title: "Ações",
      dataIndex: "more",
      key: "more",
      render: (text, record) => (
        <>
          <Dropdown
            overlay={
              <Menu key={record.id}>
                {verifyPermission("user.edit_user") && (
                  <Menu.Item
                    onClick={() => {
                      setUser(record);
                      setVisibleEditUser(true);
                    }}
                    key="1"
                  >
                    <span>Editar Usuário</span>
                  </Menu.Item>
                )}
                {verifyPermission("user.edit_password") && (
                  <Menu.Item onClick={() => onUpdatePass(record)} key="2">
                    <span>Alterar Senha</span>
                  </Menu.Item>
                )}
                {verifyPermission("user.delete_user") && (
                  <Menu.Item
                    onClick={() => onDelete(record.id, record.image_key)}
                    key="3"
                  >
                    <span style={{ color: "var(--red-600)" }}>
                      Remover Usuário
                    </span>
                  </Menu.Item>
                )}
              </Menu>
            }
            placement="bottomLeft"
            arrow
            trigger={["click"]}
          >
            <MoreIcon />
          </Dropdown>
        </>
      ),
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={data.map((entity) => ({
          ...entity,
          key: entity.id,
        }))}
      />

      <ModalEditPass
        title="Editar Senha"
        destroyOnClose={true}
        centered
        width={1000}
        confirmLoading={loading}
        visible={visibleEditPassModal}
        closable={false}
        footer={
          <BtnsModalUser>
            <ButtonCancel
              onClick={() => {
                setVisibleEditPassModal(false);
              }}
            >
              Não
            </ButtonCancel>
            <ButtonSave
              type="primary"
              onClick={() => {
                onFinishPassword();
              }}
            >
              Sim
            </ButtonSave>
          </BtnsModalUser>
        }
      >
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col sm={24}>
            <Row gutter={4}>
              <Col sm={0} xs={0}>
                <FormItem label="Id" name="id">
                  <Input name="id" value={""} disabled />
                </FormItem>
              </Col>
              <Col sm={12}>
                <FormItem
                  label="Nova Senha"
                  name="password"
                  rules={[
                    { required: true, message: "Senha é obrigatória." },
                    {
                      min: 8,
                      message: "É preciso conter ao menos 8 caracteres.",
                    },
                    {
                      pattern: /^(?=.*[A-Z])/,
                      message:
                        "É preciso conter ao menos um caracter maiúsculo.",
                    },
                    {
                      pattern: /^(?=.*[a-z])/,
                      message:
                        "É preciso conter ao menos um caracter minúsculo.",
                    },
                    {
                      pattern: /^(?=.*\d)/,
                      message: "É preciso conter ao menos um número.",
                    },
                    {
                      pattern: /^(?=.*[@$!%*?&])/,
                      message:
                        "É preciso conter ao menos um caracter especial. (*, !, etc)",
                    },
                  ]}
                >
                  <Input
                    autoComplete="new-password"
                    type="password"
                    name="password"
                    placeholder="Digite aqui sua nova senha"
                  />
                </FormItem>
              </Col>

              <Col sm={12}>
                <FormItem
                  label="Confirmar Nova Senha"
                  name="validated_password"
                  rules={[
                    {
                      required: true,
                      message: "Confirmação de senha obrigatória",
                    },
                    {
                      validator: (_, value) => validatePasswordConfirm(value),
                    },
                  ]}
                >
                  <Input
                    autoComplete="new-password"
                    type="password"
                    name="validated_password"
                    placeholder="Confirme aqui sua nova senha"
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Form>
      </ModalEditPass>
    </>
  );
};

export default UserList;
