import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  :root {
    --modal-background: #000000; 
    --black-opaco: #1B1C1E;
    --dark-blue: #040F2E;
    --white:#FFFFFF;
    --white-25: #f8f8f9;

    --gray-900: #27282A;
    --gray-800: #323335;
    --gray-700: #4F4E53;
    --gray-650: #444444;
    --gray-630: #545454;
    --gray-620: #747474;
    --gray-600: #707173;
    --gray-550: #686F82;
    --gray-530: #50576D;
    --gray-500: #96A0B5;
    --gray-350: #939394;
    --gray-300: #a4a5a6;
    --gray-250: #acaeae;
    --gray-150: #CDCFD5;
    --gray-150-20: #CDCFD520;
    --gray-100: #E5EBF0;
    --gray-50: #f3f3f3;
    --gray-30: #F3F4F6;
    --gray-25: #f2f5f9;
    --gray-20: #F5F7F8;

    --green-450: #5da66f;
    --green-300: #29ff6f;
    --green-700: #009760;
    --purple-150: #8073D9;
    --blue-300: #040F2E;
    --red-600:#a44343;
    --orange-600: #e84329;
    --orange-250: #fe9d0e;
    --orange-150: #f28750;
    --orange-100: #FF8561;
    --gradiente: linear-gradient(90deg, #FF875E -18.55%, #FD67AE 166.13%);
  } 

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }

  html, body, #root {
    width: 100%;
    height: 100%;
    font: 400 1rem "Roboto", sans-serif;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;

    .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
      font-size: 12px !important;
      color: var(--gray-620) !important;
  }

    .ant-tabs-nav-list {
      color: var(--orange-100);
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: var(--orange-100) !important;
    }
    .ant-tabs-ink-bar {
      background: var(--orange-100);
    }
    .ant-tabs-tab:hover {
      color: var(--orange-100) !important;
    }
    .ant-tabs-tab{
      color: var(--orange-100) !important;
    }
    .ant-tabs-tab-active {
      color: var(--orange-100) !important;
    }

    .ant-input-affix-wrapper > input.ant-input {
        height: 2.2rem !important;
    }

    .ant-table-content {
      overflow-y: scroll;

      @media only screen and (max-width: 1680px) {
       max-height: 100%;
      }

      @media only screen and (max-width: 1600px) {
       max-height: 30rem;
      }

      @media only screen and (max-width: 1400px ) {
        @media only screen and (max-height: 1050px ) {
          max-height: 100%
        }
      }

      @media only screen and (max-width: 1366px) {
       max-height: 25rem;
       font-size: 0.8rem;
      }

      @media only screen and (max-width: 1280px) {
        max-height: 35rem;

        @media only screen and (max-height: 960px) {
          max-height: 36rem;
        }

        @media only screen and (max-height: 800px) {
          max-height: 25rem;
        }
      }

      @media only screen and (max-width: 1250px) {
       max-height: 20rem;
      }

      @media only screen and (max-width: 575px) {
        max-height: 30rem;

       
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: white;
      border-color: var(--green-450);
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 2px solid var(--green-450);
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: " ";
    }

    .ant-input {
      touch-action: manipulation !important;
    }
    
    .ant-select, .ant-select-selector {
      touch-action: manipulation !important;
    }

    .ant-input-affix-wrapper {
      outline: none;
      border: none;
      box-shadow: 0 0 0 0;
      background: var(--white);
      border: 1px solid rgba(205, 207, 213, 0.5);
    }

    .ant-switch {
      outline: none;
      box-shadow: 0 0 0 0;
      background-color: var(--white);
      border: 1px solid var(--gray-550);

      .ant-switch-handle {
        top: 1px;
        box-shadow: none;
      }

      .ant-switch-handle::before {
        background-color: var(--gray-500);
      }

      @media only screen and (max-width: 1600px) {
        min-width: 40px;
        height: 18px;
        line-height: 22px;

        .ant-switch-handle {
          width: 14px;
          height: 14px;
        }
      }
    }
    
    
    .ant-switch-checked {
      .ant-switch-handle::before {
        box-shadow: none;

        background-color: var(--orange-100);
      }
    }

    .ant-input, .ant-input-password, .ant-select-selector, .ant-picker {
    
      outline: none;
      border: none;
      box-shadow: 0 0 0 0;
      background: var(--white);

      @media only screen and (max-width: 1366px) {
        height: 3.2rem !important;
      }

      @media only screen and (max-width: 1280px) {
        height: 2.8rem !important;
      }

      @media only screen and (max-width: 800px) {
        height: 2.5rem !important;
      }
            
    }

    .ant-select-selection-search {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-btn {
      border: none;
      box-shadow: none;

      :focus,
      :active,
      :hover {
        border: none;
      }
    }


    .ant-dropdown-menu-item, .ant-dropdown-menu-item-only-child  {
      color: var(--gray-550);
      border-bottom: 1px solid var(--gray-100);
      font-size: 0.8rem;
    }

    .ant-dropdown-menu {
      padding: 0% 0%;
    }

    .ant-select-selector {
      align-items: center;
    }

    .ant-table-container {
      color: var(--gray-550);
      border: 1px solid rgba(205, 207, 213, 0.5);
      min-height: 2.5rem;
   
      @media only screen and (max-width: 1280px) {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 800px) {
        font-size: 0.7rem;
      }
      @media only screen and (max-width: 578px) {
        font-size: 0.6rem;
      }
    }

    .ant-table-pagination.ant-pagination {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 4rem;
      background-color: var(--white);
      margin: 0;
      padding: 0 5%;

      .ant-pagination-options {
        display: none;
      }

      li, .ant-pagination-item-link {
        border-radius: 5px;

        svg {
          width: 0.6rem;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      .ant-pagination-prev, .ant-pagination-next {
        display: flex;
        align-items: center;
      }

      a {
        font-size: 12px;
      }

      
     
      .ant-pagination-item {
        background-color: var(--gray-100);
        border: none;
        
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        
        a {
          color: var(--gray-350);
        }

        :hover, :focus, :active {
          border: none;
        }
      }
       
      .ant-pagination-item-active {
        border: none;
        background-color: var(--orange-100);
        
        a {
          color: var(--white);
        }
      }

      .ant-pagination-item-ellipsis {
        color: var(--gray-350)
      }

      @media only screen and (max-width: 575px) {
        padding: 0;
      }
    }


    .ant-table-thead > tr > th {
      background: white;
      color: var(--gray-350);
    }

    .ant-table-pagination-right {
      justify-content: center;

      .ant-select-selector {
        height: 2rem !important;
      }
    }

    .ant-collapse-header{
      border: 1px solid var(--white-25);
    }

    .ant-collapse > .ant-collapse-item{
      border-bottom: none !important;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
      fill: var(--orange-100);
  }

 

    .ant-modal-header{
      border-bottom: none;
    }

    .ant-modal-footer{
      border-top: none;
      background: var(--white-25);
      padding: 1rem 1.5rem;    
    }
  }

  *, button, input{
    border: 0;
    background: none;
    outline: none;
  }

  html {
    color: black;
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .ant-modal-confirm{
    .ant-modal-body{
      padding: 2rem 3rem;


    .anticon svg{
      color: var(--orange-100)
    }


    .ant-btn:not([disabled]){
        color: var(--blue-300);
        margin-right: 3.7rem;
        background-color: transparent;
      :active,
      :hover,
      :focus {
        opacity: 0.8;
      }
    }

    .ant-modal-confirm-btns .ant-btn + .ant-btn{
        width: 7.5rem;
        height: 3.2rem;

        margin-right: 0;

        background: var(--orange-100);
        color: white;
        border-radius: 5px;

      :active,
      :hover,
      :focus {
        background: var(--orange-100);
      }
    }
  }

  }

`;
