import React from 'react';
import { Company as CompanyModel } from "../../models/entities/company";
import { FranchiseeInfo } from '../../models/entities/franchisee';
import { Empty, Modal, Table } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { MajoritarieFranchisee } from './styles';

interface IProps {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    company: CompanyModel | null;
    franchiseeData: FranchiseeInfo[];
}

const ModalFranchiseeInfo: React.FC<IProps> = ({ visible, setVisible, loading, franchiseeData }) => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: '10%',
            key: 'id',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Sócio',
            dataIndex: 'user',
            key: 'user',
            sorter: (a, b) => a.company_percentage - b.company_percentage,
            defaultSortOrder: 'descend' as SortOrder,
            render: (_, record, index) => (
                <div>
                    {index === 0 ?
                        <MajoritarieFranchisee>
                            {'Majoritário → ' + record.user?.full_name}
                        </MajoritarieFranchisee>
                        : record.user.full_name}
                </div>
            ),
        },
        {
            title: 'Porcentagem da empresa',
            dataIndex: 'company_percentage',
            key: 'company_percentage',
            render: (text) => <span>{text}%</span>,
        },
    ];

    return (
        <Modal
            title="Informações do franqueado"
            visible={visible}
            centered
            destroyOnClose
            closable
            width={window.innerWidth > 600 ? '60%' : '100%'}
            footer={false}
            onCancel={() => {
                setVisible(false)
            }}
        >
            {franchiseeData.length !== 0 ? (
                <Table
                    loading={loading}
                    columns={columns}
                    pagination={false}
                    dataSource={franchiseeData}
                />
            ) : (
                <Empty description="Não há informações na loja selecionada!" />
            )}

        </Modal>
    )
}

export default ModalFranchiseeInfo;
