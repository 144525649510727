import moment from "moment";

enum ReasonType {
  "Pedidos mais de 15 dias em atraso" = 0,
  "Boletos de renegociação vencidos" = 1,
  "O fechamento semanal está acima do permitido" = 2,
}

export const companyAudit = (value) => {
  const renderValue = (value: any) => {
    if (value === null || value === 0) {
      return "Não preenchido";
    } else {
      return value;
    }
  };

  if (
    value?.field === "software" ||
    value?.field === "royalts" ||
    value?.field === "marketing" ||
    value?.field === "franchisee_fee"
  ) {
    return `R$ ${renderValue(value?.old_value)} foi alterado para R$ ${Number(
      renderValue(value?.new_value)
    ).toFixed(2)}`;
  } else if (value?.field === "contract_time") {
    return `${value?.old_value} meses foi alterado para ${value?.new_value} meses`;
  } else if (value?.field === "contract_start") {
    return `${moment(value?.old_value).format(
      "DD/MM/YYYY HH:mm:ss"
    )} foi alterado para ${moment(value?.new_value).format(
      "DD/MM/YYYY HH:mm:ss"
    )} `;
  } else if (value?.field === "company_name") {
    return `Nome da empresa foi alterado de ${renderValue(
      value?.old_value
    )} para ${renderValue(value?.new_value)}`;
  } else if (
    value?.field === "emitente_incentivo_fiscal" ||
    value?.field === "emitente_isento" ||
    value?.field === "enviar_email_destinatario" ||
    value?.field === "discrimina_impostos" ||
    value?.field === "habilita_nfce" ||
    value?.field === "selected_group"
  ) {
    return `${renderValue(value?.old_value === "true" ? "Sim" : "Não")} para ${
      value?.new_value === "true" ? "Sim" : "Não"
    }`;
  } else if (value?.field === "responsible_user_id") {
    return `${renderValue(value?.old_value)} para ${parseInt(
      value?.new_value
    )}`;
  } else if (value?.field === "habilita_nfce") {
    return `${value?.old_value === "true" ? "Sim" : "Não"} para ${
      value?.new_value === "true" ? "Sim" : "Não"
    }`;
  } else if (value?.field === "habilita_nfe") {
    return `${value?.old_value === "true" ? "Sim" : "Não"} para ${
      value?.new_value === "true" ? "Sim" : "Não"
    }`;
  } else if (value?.field === "defaulter_status") {
    const oldValueText =
      value?.old_value === "true" ? "Bloqueada" : "Desbloqueada";
    const newValueText =
      value?.new_value === "true" ? "Bloqueada" : "Desbloqueada";

    return `${oldValueText} para ${newValueText}`;
  } else if (value?.field === "defaulter_reason") {
    return `Razão do bloqueio: ${
      ReasonType[value?.new_value]
        ? ReasonType[value?.new_value]
        : "Desconhecido"
    }`;
  } else if (value?.field === "company_reason") {
    if (value?.new_value === null) {
      return `Empresa está ativa`;
    }
    return `Justificativa da inatividade foi alterada de ${renderValue(
      value?.old_value
    )} para ${renderValue(value?.new_value)}`;
  } else if (value?.field === "company_status") {
    const oldStatusText = value?.old_value === "true" ? "Ativa" : "Inativa";
    const newStatusText = value?.new_value === "true" ? "Ativa" : "Inativa";
    return `Status da empresa foi alterado de ${oldStatusText} para ${newStatusText}`;
  } else {
    return `${renderValue(value?.old_value)} foi alterado para ${renderValue(
      value?.new_value
    )}`;
  }
};
