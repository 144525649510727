import React, { useState } from "react";
import { getModules, getTokenInfo } from "../../services/auth";

import {
  Container,
  AppIcon,
  BellIcon,
  Body,
  Header,
  ActionContainer,
  Dropdown,
  MenuContainer,
  Card,
  HomologContent,
} from "./styles";

interface IProps {
  route: string;
}

const PageContainer: React.FC<IProps> = ({ children, route }) => {
  const [dropVisible, setDropVisible] = useState(false);

  return (
    <Container>
      <Header>
        <h3>{route}</h3>
        {process.env.REACT_APP_ENV === "homolog" && (
          <HomologContent>AMBIENTE DE HOMOLOGAÇÃO</HomologContent>
        )}
        <ActionContainer>
          <div className="actions-icons">
            <AppIcon onClick={() => setDropVisible(!dropVisible)} />
            <BellIcon />
            <img
              src={
                getTokenInfo()?.image
                  ? getTokenInfo()?.image
                  : "https://www.ecp.org.br/wp-content/uploads/2017/12/default-avatar-1.png"
              }
              alt="Imagem de avatar do usuário"
            />
          </div>
        </ActionContainer>
        {dropVisible && (
          <Dropdown>
            <MenuContainer>
              {getModules().map((module) => (
                <Card target="_blank" href={module.url}>
                  <img src={module.image} alt="Logo" />
                  <span>{module.name}</span>
                </Card>
              ))}
            </MenuContainer>
          </Dropdown>
        )}
      </Header>
      <Body>{children}</Body>
    </Container>
  );
};

export default PageContainer;
