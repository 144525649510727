import styled, { css } from "styled-components";
import Upload from "../../components/Upload";
import {
  Form as FormAnt,
  Row as RowAnt,
  Col as ColAnt,
  Button as ButtonAnt,
  Modal as ModalAnt,
  Select as SelectAnt,
  Tabs as TabsAnt,
  DatePicker as DatePickerAnt,
} from "antd";

import { ExclamationCircle, Edit } from "../../styles/Icons";

const { Option: OptionAnt } = SelectAnt;

export const DatePicker = styled(DatePickerAnt)`
  width: 100%;
  padding: 0;

  input{
    padding-left: 0.5rem;
  }
`;

export const Tabs = styled(TabsAnt)`
  overflow-y: auto;
  .ant-row .ant-form-item {
    margin: 0;
  }
`;

export const UploadComponent = styled(Upload)`
  border: none !important;

  .dropzone {
    border: none !important;
  }
`;

export const InputDate = styled.input`
  width: 100%;
  height: 3.2rem !important;
  border: 1px solid var(--gray-100);
  border-radius: 0.3rem;
  padding: 10px;
`;

export const Form = styled(FormAnt)`
  padding: 0 2.7rem;
  background: var(--white);
  height: 32rem;
  overflow: auto;
  .ant-form-item {
    margin-bottom: 0px;
  }
  .imgPerfir {
    width: 150px;
    height: 150px;
    border-radius: 100px;
  }
  @media only screen and (max-height: 630px) {
    height: 25rem;
  }
  @media only screen and (max-height: 540px) {
    height: 20rem;
  }
`;

export const FormItem = styled(FormAnt.Item)`
  padding: 0.3rem;
`;
export const FormUpload = styled(FormAnt.Item)`
  padding: 0.3rem;
`;

export const Row = styled(RowAnt)`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Col = styled(ColAnt)``;

export const Option = styled(OptionAnt)``;

export const ModalAddNewUser = styled(ModalAnt)`
  .ant-row .ant-form-item {
    padding: 10px;
  }
  .ant-modal-content {
    width: 100%;
    height: 80%;
    overflow-y: auto;
  }

  .ant-modal-body {
    padding: 1rem 0;
    height: 80%;

    input {
      border: 1px solid var(--gray-100);
      border-radius: 0.3rem;
      height: 2.7rem;
    }

    label {
      color: var(--gray-600);
      font-weight: 400;
    }

    .ant-select-selector {
      height: 2.7rem;
      border: 1px solid var(--gray-100);
      border-radius: 0.3rem;
    }

    .ant-form-item-label
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::after {
      display: inline-block;
      color: var(--orange-150);
      content: "*";
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::before {
      content: "";
    }
  }

  .ant-modal-header {
    height: 9.1%;
    padding: 1rem 3rem 0;
    border-bottom: none;

    .ant-modal-title {
      font-weight: 500;
    }
  }

  .ant-modal-footer {
    height: 11.2%;
    justify-content: end;
    border-top: none;
    background-color: var(--gray-20);
  }
`;

export const ButtonCancel = styled(ButtonAnt)`
  color: var(--blue-300);
  background-color: transparent !important;
  :active,
  :hover,
  :focus {
    opacity: 0.8;
  }
`;

export const ButtonSave = styled(ButtonAnt)`
  height: 3.2rem;
  background: var(--orange-100);
  color: white;
  border-radius: 5px;

  :active,
  :hover,
  :focus {
    background: var(--orange-100);
  }
`;

export const ModalConfirmEdit = styled(ModalAnt)`
  .ant-modal-content {
    width: 100%;
    height: 70%;

    .ant-modal-body {
      height: 60%;
      padding: 0 0 2rem 5.3rem;
      span {
      }
    }

    .ant-modal-header {
      padding: 2rem 0 1.5rem 2.8rem;
      border-bottom: none;

      .ant-modal-title {
        font-weight: 400;
        font-size: 1rem;
        color: var(--blue-300);
      }
    }

    .ant-modal-footer {
      justify-content: end;
      border-top: none;
      background-color: var(--gray-20);
      span {
        width: 3.3rem !important;
      }
    }
  }
`;

export const Exclamation = styled(ExclamationCircle)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  color: var(--orange-100);
`;

export const ContainerImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  img {
    position: relative;
    width: 10rem !important;
    height: 10rem !important;
    border: 1px solid var(--gray-250);
    border-radius: 1.5rem;

    cursor: pointer;
  }
`;

export const BtnsAddUser = styled.div`
  @media only screen and (max-width: 575px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const BtnsModalUser = styled.div``;

export const EditIcon = styled(Edit)`
  background-color: white;
  border-radius: 50%;
  padding: 0.2rem;

  color: var(--orange-100);
  width: 20px;
  height: 20px;
`;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentCountrySelectButton = styled.button`
  border: 1px solid var(--orange-100);
  background: transparent;
  border-radius: 5px;
`;

interface ISelectedCountry {
  selected: boolean;
}

export const ButtonCountry = styled.button<ISelectedCountry>`
  padding: 0.5rem;
  ${({ selected }) =>
    selected === true &&
    css`
      border: 1px solid var(--orange-100);
      border-radius: 5px;
    `}
`;

export const Country = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;
