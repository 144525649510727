import styled, { css } from "styled-components";

import {
  Input as InputAnt,
  Form as FormAnt,
  Button as ButtonAnt,
  Col as ColAnt,
  Row as RowAnt,
  Modal as ModalAnt,
} from "antd";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 578px) {
    flex-direction: column-reverse;
    background-color: black;
  }
`;

interface IRightSide {
  src?: string;
}

export const RightSide = styled.div<IRightSide>`
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 100%;
  justify-content: flex-end;
  border-top: 0.5rem solid var(--orange-100);

  ${({ src }) => css`
    background-image: url(${src});
    background-repeat: no-repeat;
    background-size: cover;
  `}

  h1 {
    text-align: right;
    margin-right: 2%;
    margin-bottom: 0;
    color: var(--orange-150);
    font-size: 3.5rem;
    font-weight: 950;
  }

  p {
    text-align: right;
    margin-right: 15%;
    margin-bottom: 2%;
    color: var(--white);
    font-size: 2.5rem;
    font-weight: 100;
  }

  @media only screen and (max-width: 1280px) {
    width: 60%;
    h1 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.5rem;
    }
  }

  @media only screen and (max-width: 800px) {
    width: 55%;
    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }

  @media only screen and (max-width: 578px) {
    width: 100%;
    height: 30%;
  }
`;

export const HomologContent = styled.div`
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 0, 0, 0.5);
  padding: 10px;
  color: white;
  z-index: 1;
  pointer-events: none;

  @media screen and (max-width: 768px){
    top: 5%;
    height: 2rem;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
  height: 100%;
  padding: 0 5%;
  border-top: 0.5rem solid var(--orange-100);

  @media only screen and (max-width: 1280px) {
    width: 40%;
  }

  @media only screen and (max-width: 800px) {
    width: 45%;
  }

  @media only screen and (max-width: 578px) {
    width: 100%;
    height: 70%;
    border-radius: 52px 52px 0 0;
    background: white;
  }
`;

export const Col = styled(ColAnt)``;

export const Row = styled(RowAnt)`
  width: 100%;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.3rem;

  h1 {
    font-size: 2.5rem;
    color: var(--black-opaco);
    font-weight: 500;
  }

  p {
    color: var(--gray-500);
    text-align: start;
  }

  @media only screen and (max-width: 1466px) {
    h1 {
      font-size: 2rem;
    }
  }

  @media only screen and (max-width: 1280px) {
    h1 {
      font-size: 1.5rem;
    }
    p {
      font-size: 0.85rem;
    }
  }

  @media only screen and (max-width: 800px) {
    h1 {
      font-size: 1.2rem;
    }
    p {
      font-size: 0.7rem;
    }
  }

  @media only screen and (max-width: 578px) {
    height: 20%;
    h1 {
      font-size: 2rem;
    }
  }
`;

export const FooterModal = styled.footer`
  .spanCancel {
    margin-right: 4rem;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    color: var(--blue-300);
  }
  .spanCancel:hover {
    opacity: 0.7;
  }
`;

export const Logo = styled.img`
  width: 9rem;
  height: 11rem;
  position: relative;
  top: -0.55rem;

  @media only screen and (max-width: 1466px) {
    width: 7rem;
    height: 9rem;
    top: -0.8rem;
  }

  @media only screen and (max-width: 1280px) {
    width: 6rem;
    height: 8rem;
    top: -0.9rem;
  }

  @media only screen and (max-width: 800px) {
    width: 5rem;
    height: 7rem;
    top: -1rem;
  }

  @media only screen and (max-width: 578px) {
    display: none;
  }
`;

export const Form = styled(FormAnt)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 0.7rem;
  width: 100%;
  height: 50%;

  .ant-input,
  .ant-input-password {
    height: 3.7rem;
  }

  p {
    color: var(--gray-500);
    margin-top: 0.3rem;
    font-size: 0.9rem;
    font-weight: 500;

    span {
      color: var(--orange-100);
    }
  }

  .privacity-term {
    font-weight: 500;
    color: var(--orange-100);

    :hover {
      text-decoration: underline;
    }
  }

  .ant-form-item {
    margin-bottom: 15px;
  }

  .privacity-term {
    font-weight: 500;
    color: var(--orange-100);

    :hover {
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 1366px) {
    p {
      font-size: 0.8rem;

      span {
        font-size: 0.8rem;
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    p {
      font-size: 0.7rem;

      span {
        font-size: 0.7rem;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .ant-form-item-required {
      font-size: 14px;
    }

    p {
      font-size: 0.6rem;

      span {
        font-size: 0.6rem;
      }
    }

    .ant-form-item {
      margin-bottom: 10px;
    }
  }

  @media only screen and (max-width: 578px) {
    width: 80%;
    height: 70%;
  }
`;

export const FormItem = styled(FormAnt.Item)`
  label {
    color: var(--blue-300);
    left: -0.2rem;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    color: var(--orange-150);
    content: "*";
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: "";
  }
  @media only screen and (max-width: 800px) {
    padding-top: 1rem;
  }
`;

export const H2Bold = styled.h2`
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--blue-300);
  margin: 1.3rem;
`;

export const FormModal = styled(FormAnt.Item)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 1.3rem;

  label {
    color: var(--gray-600);
    padding-bottom: 0;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    color: var(--orange-150);
    content: "*";
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: "";
  }

  input {
    margin-top: 0;
    width: 25rem;
    border-radius: 0.4rem;

    ::placeholder {
      color: var(--gray-250);
      padding-left: 1rem;
      font-weight: 400;
    }
  }

  @media only screen and (max-width: 800px) {
    padding-top: 1rem;
  }
`;

export const Input = styled(InputAnt)`
  margin-top: 0.3rem;
  border: var(--gray-100) 1px solid !important;

  ::placeholder {
    height: 3.25rem;
    color: var(--gray-500);
  }
`;

export const InputCode = styled(InputAnt)`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
`;

export const MailIcon = styled.img`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
`;

export const PassIcon = styled.img`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
`;

export const Button = styled(ButtonAnt)`
  height: 3.25rem;
  width: 100%;
  background: var(--orange-100);
  border: none;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin-top: 0.3rem;

  :active,
  :focus {
    background: var(--orange-250);
  }

  :hover {
    background: var(--orange-150);
  }

  @media only screen and (max-width: 1366px) {
    height: 3rem;
  }

  @media only screen and (max-width: 1280px) {
    height: 2.7rem;
  }

  @media only screen and (max-width: 800px) {
    height: 2rem;
    font-size: 1rem;
  }
`;

export const ButtonModal = styled(ButtonAnt)`
  height: 3.25rem;
  width: 7.5rem;
  background: var(--orange-100);
  border: none;
  border-radius: 0.4rem;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  :active,
  :focus {
    background: var(--orange-250);
  }

  :hover {
    background: var(--orange-150);
  }

  @media only screen and (max-width: 1366px) {
    height: 3rem;
  }

  @media only screen and (max-width: 1280px) {
    height: 2.7rem;
  }

  @media only screen and (max-width: 800px) {
    height: 2rem;
    font-size: 1rem;
  }
`;

export const RecoverPassword = styled.div`
  height: 2rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;

  span {
    margin-top: 0.3rem;
    color: var(--gray-500);
  }

  @media only screen and (max-width: 800px) {
    margin-top: 0.2rem;
    font-size: 0.7rem;
  }
`;

export const Footer = styled.div`
  width: 75%;
  height: 10%;
`;

export const ModalCodeVerify = styled(ModalAnt)`
  .ant-modal-body {
    > span {
      font-weight: bold;
      cursor: pointer;
      color: var(--orange-100);
      margin-bottom: 1rem;
    }
    p:last-child {
      color: var(--gray-200);
      font-weight: 400;
      font-size: 0.8rem;
      margin-top: 1rem;
    }
    input {
      border: var(--gray-100) 1px solid;
      border-radius: 0.4rem;
    }
    button {
      border-radius: 0.4rem;
    }
    h2 {
      color: var(--blue-300);
      font-weight: 600;
      font-size: 1.2rem;
    }
    p {
      color: var(--gray-600);
      font-weight: 400;
    }
    .ant-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-evenly;
      margin: 1rem 0;
    }
  }
`;

export const ContentLinkBottom = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
`;
