import React, { useEffect, useState } from "react";
import api from "../../services/api";

import Spinner from "../../components/Spinner";
import PageContainer from "../../containers/PageContainer";

import { message as messageAnt, Empty } from "antd";

import {
  Container,
  Table,
  Checkbox,
  Input,
  SearchContainer,
  ListContainer,
  NoContentContainer,
} from "./styles";

const ModulePermission: React.FC = () => {
  const [tableColumns, setTableColumns] = useState<any>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [filter, setFilter] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const {
          data: { content: moduleContent },
        } = await api.get("/module");
        const {
          data: { content: userContent },
        } = await api.get("/user");

        const headers: any = [
          {
            title: "Nome",
            dataIndex: "name",
          },
        ];
        if (!moduleContent.length) {
          return;
        }
        const _modules = moduleContent.map((_module) => {
          let response = {
            id: _module.id,
            moduleName: _module.name,
            moduleKey: _module.name.replaceAll(" ", "_").toLowerCase(),
          };
          headers.push({
            title: response.moduleName,
            dataIndex: response.moduleKey,
            render: (text, record) => {
              const handleClick = async (event) => {
                const [module_id, module_key] = event.target.name.split("-");
                const user_id = record.user_id;
                if (event.target.checked) {
                  try {
                    await api.post("/userModule", {
                      module_id: +module_id,
                      user_id,
                    });
                    setTableData((oldValues) => {
                      const userIndex = oldValues.findIndex(
                        (_user) => +_user.user_id === +user_id
                      );
                      const updatedData = oldValues;
                      updatedData[userIndex][module_key] = true;
                      return updatedData;
                    });
                  } catch {
                    messageAnt.error(
                      `Falha ao adicionar permissão de módulo ao usuário ${record.name}`
                    );
                  }
                } else {
                  try {
                    await api.delete(`/userModule/${user_id}-${module_id}`);
                    setTableData((oldValues) => {
                      const userIndex = oldValues.findIndex(
                        (_user) => +_user.user_id === +user_id
                      );
                      const updatedData = oldValues;
                      updatedData[userIndex][module_key] = false;
                      return updatedData;
                    });
                  } catch {
                    messageAnt.error(
                      `Falha ao remover permissão de módulo do usuário ${record.name}`
                    );
                  }
                }
              };

              return (
                <Checkbox
                  defaultChecked={record[response.moduleKey]}
                  name={`${response.id}-${response.moduleKey}`}
                  onChange={handleClick}
                />
              );
            },
          });
          return response;
        });

        const tableData = userContent.map((user) => {
          let userModule = {};
          _modules.forEach((_module) => {
            userModule[_module.moduleKey] = !!moduleContent
              .find((entity) => entity.id === _module.id)
              ?.userModules.find(
                (userModule) => userModule.user_id === user.id
              );
          });
          return {
            key: user.id,
            user_id: user.id,
            name: user.name.toLowerCase(),
            ...userModule,
          };
        });

        setTableColumns(headers);
        setTableData(tableData);
      } catch (error) {
        messageAnt.error("Erro ao obter dados do servidor");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  function handleFilter(pattern?: string) {
    if (pattern) {
      return tableData.filter(function (entity) {
        return entity.name.toLowerCase().includes(pattern.toLowerCase());
      });
    }
    return tableData;
  }

  return (
    <PageContainer route="Módulos">
      <>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {tableData.length ? (
              <Container>
                <SearchContainer>
                  <Input
                    placeholder="Buscar por usuário"
                    onChange={({ target: { value } }) => setFilter(value)}
                  />
                </SearchContainer>
                <ListContainer>
                  <Table
                    columns={tableColumns}
                    dataSource={handleFilter(filter)}
                  />
                </ListContainer>
              </Container>
            ) : (
              <NoContentContainer>
                <Empty description="Nenhuma permissão de módulo encontrada" />
              </NoContentContainer>
            )}
          </>
        )}
      </>
    </PageContainer>
  );
};

export default ModulePermission;
