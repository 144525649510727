import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";

import locale from "antd/es/date-picker/locale/pt_BR";

import moment from "moment";
import api from "../../services/api";
import paraguaiDistrict from "../../services/allParaguayDistrict";
import { onlyNumbers } from "../../services/onlyNumber";
import { verifyPermission, getTokenInfo } from "../../services/auth";

import MaskedInput from "antd-mask-input";
import Companies from "../../components/Companies";
import Spinner from "../../components/Spinner";
import Upload from "../../components/Upload";
import { cpf } from "cpf-cnpj-validator";

import Brasil from "../../assets/image/brasil.webp";
import Paraguai from "../../assets/image/paraguai.webp";

import { User as UserModel } from "../../models/entities/user";
import {
  atuationArea,
  genderOptions,
  maritalStatus,
  scholarityOptions,
} from "../../models/franchiseeInfos";

import { Checkbox, Divider, Modal, notification, Input, Select } from "antd";

import {
  Form,
  FormItem,
  Row,
  Col,
  Option,
  ModalAddNewUser,
  BtnsAddUser,
  ButtonCancel,
  ButtonSave,
  BtnsModalUser,
  Exclamation,
  ModalConfirmEdit,
  FormUpload,
  ContainerImg,
  Tabs,
  ContentHeader,
  ContentButton,
  ButtonCountry,
  Country,
  InputDate,
  DatePicker,
} from "./styles";
import axios from "axios";
const { TabPane } = Tabs;

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  visibleEditUser: boolean;
  setVisibleEditUser: Dispatch<SetStateAction<boolean>>;
  setUser: Dispatch<SetStateAction<UserModel | null>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  user?: UserModel | null;
  users?: UserModel[];
  profiles?: UserModel[];
}

interface IViaCep {
  bairro: string | null;
  cep: string | null;
  complemento: string | null;
  ddd?: string | null;
  gia?: string | null;
  ibge: string | null;
  localidade: string | null;
  logradouro: string | null;
  siafi?: string | null;
  uf: string | null;
}

const UserForm: React.FC<IProps> = ({
  visible,
  setVisible,
  visibleEditUser,
  setVisibleEditUser,
  user,
  setUser,
  setShouldSearch,
  profiles,
}) => {
  const [companies, setCompanies] = useState<number[]>([]);
  const [isProfile, setIsProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visibleConfirmEdit, setVisibleConfirmEdit] = useState(false);
  const [profileType, setProfileType] = useState<number | undefined>(undefined);
  const [selectedScholarity, setSelectedScholarity] = useState<
    number | undefined
  >();
  const [isMarried, setIsMarried] = useState<number | undefined>();
  const [checked, setChecked] = useState(false);
  const [viaCepData, setViaCepData] = useState<IViaCep>();
  const [isAnotherArea, setIsAnotherArea] = useState<string | undefined>();

  const [selectCountryInput, setSelecteCountryInput] = useState("BR");

  const [form] = Form.useForm();
  const [image, setImage] = useState<File | null>(null);

  useEffect(() => {
    if (user) {
      delete user.password;

      setIsMarried(isProfile ? user?.marital_status : undefined);
      setSelectedScholarity(isProfile ? user?.scholarity : undefined);
      setIsAnotherArea(isProfile ? user?.atuation : undefined);

      form.setFieldsValue({
        ...user,
        validated_email: user.email,
        profile_permission: user.profile_permission?.split(".")[1],
        birthday: user.birthday ? moment(user.birthday) : null,
      });

      if (user) {
        setSelecteCountryInput(user.cpf ? "BR" : "PY");
      }

      if (user.is_profile) {
        setIsProfile(true);
      }
    } else {
      form.resetFields();
    }
  }, [user, form, isProfile]);

  const clearFields = () => {
    setUser(null);
    setImage(null);
    setIsMarried(undefined);
    setSelectedScholarity(undefined);
    form.resetFields();
  };

  const closeModals = () => {
    setVisible(false);
    setVisibleEditUser(false);
    setVisibleConfirmEdit(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      let payload = form.getFieldsValue();

      if (payload.validated_email !== payload.email) {
        notification.warning({
          message: "E-mails inválidos",
          description: "Os e-mails devem ser iguais",
          duration: 3,
        });
        return;
      }
      delete payload.validated_email;
      payload.name = payload.name?.toLowerCase()?.trim();
      payload.last_name = payload.last_name?.toLowerCase()?.trim();
      payload.full_name = payload.name + " " + payload.last_name;
      payload.email = payload.email?.toLowerCase()?.trim();
      payload.username = payload.email;
      payload.atuation = payload.atuation;
      if (payload.birthday) {
        payload.birthday = moment(payload.birthday).set({
          hour: 12,
          minute: 0,
          second: 0,
        });
      }

      if (payload.cpf) {
        payload.cpf = onlyNumbers(payload.cpf)?.toString();
      }
      if (payload.phone) {
        payload.phone = onlyNumbers(payload.phone)?.toString();
      }
      if (!payload.password) {
        delete payload.password;
      }

      if (selectCountryInput === "PY") {
        payload.ruc = payload.ruc?.toString();
        delete payload.cpf;
      }

      const method = payload.id ? "put" : "post";
      if (method === "post" && !companies.length) {
        notification.warning({
          message: "Nenhuma empresa selecionada",
          description: `Selecione pelo menos uma empresa para o usuário`,
          duration: 3,
        });
        return;
      }

      const url = payload.id ? `/user/${payload.id}` : "/user";

      if (image) {
        if (user?.image_key) {
          await api.delete(`/s3-upload/${user.image_key}`);
        }

        const imageToUpload = new FormData();

        imageToUpload.append("file", image as File);

        const {
          data: { location, key },
        } = await api.post("/s3-upload/upload", imageToUpload);

        payload.image = location;
        payload.image_key = key;
      }

      await api[method](url, { ...payload, companies });
      notification.success({
        message: user?.id
          ? "Usuário editado com sucesso"
          : "Usuário cadastrado com sucesso",
        duration: 3,
      });

      setShouldSearch(true);
      clearFields();
      setCompanies([]);
      closeModals();
    } catch (err: any) {
      let errorMessages: any[] = [];
      if (err && err.errorFields && Array.isArray(err.errorFields)) {
        err.errorFields.forEach((fieldError) => {
          if (
            fieldError &&
            fieldError.errors &&
            Array.isArray(fieldError.errors)
          ) {
            errorMessages = errorMessages.concat(fieldError.errors);
          }
        });
      } else {
        const errorMessageFromResponse = err?.response?.data?.message;
        if (errorMessageFromResponse) {
          errorMessages.push(errorMessageFromResponse);
        }
      }
      const errorMessage =
        errorMessages.length > 0
          ? errorMessages.join(", ")
          : "Um erro interno ocorreu";

      notification.warning({
        message: "Ops! Erro ao cadastrar usuário",
        description: errorMessage,
        duration: 5,
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const saveNewUser = async () => {
    if (companies.length) {
      if (!user?.id) {
        onFinish();
      } else {
        setVisibleConfirmEdit(true);
      }
    } else {
      notification.warning({
        message: "Nenhuma empresa selecionada",
        duration: 3,
      });
    }
  };

  const onCancel = () => {
    Modal.confirm({
      title: "Deseja realmente cancelar?",
      content: "As informações preenchidas serão descartadas.",
      okText: "Sim",
      centered: true,
      okType: "primary",
      cancelText: "Não",
      width: 500,
      async onOk() {
        clearFields();
        setVisible(false);
        setVisibleEditUser(false);
      },
    });
  };

  const handleChangeCep = async (cep) => {
    const _cep = onlyNumbers(cep.target.value)?.toString();
    if (_cep?.length === 8) {
      const { data } = await axios({
        url: `https://viacep.com.br/ws/${_cep}/json/`,
        method: "GET",
      });
      setViaCepData(data);
      form.setFieldsValue({
        state: data?.uf || "",
        city: data?.localidade || "",
        district: data?.bairro || "",
        address: data?.logradouro || "",
        complement: data?.complemento !== "" ? data?.complemento : "Não possui",
      });
    }
  };

  return (
    <>
      <ModalAddNewUser
        title={
          <ContentHeader>
            {user?.id ? "Editar usuário" : "Novo usuário"}

            <ContentButton>
              <ButtonCountry
                selected={selectCountryInput === "BR"}
                disabled={user?.ruc ? true : false}
                onClick={() => setSelecteCountryInput("BR")}
              >
                {useMemo(
                  () => (
                    <Country src={Brasil}></Country>
                  ),
                  []
                )}{" "}
                🇧🇷
              </ButtonCountry>
              <ButtonCountry
                selected={selectCountryInput === "PY"}
                disabled={user?.cpf ? true : false}
                onClick={() => setSelecteCountryInput("PY")}
              >
                {useMemo(
                  () => (
                    <Country src={Paraguai}></Country>
                  ),
                  []
                )}{" "}
                🇵🇾
              </ButtonCountry>
            </ContentButton>
          </ContentHeader>
        }
        destroyOnClose={true}
        centered
        width={"90%"}
        visible={visible || visibleEditUser}
        closable={false}
        footer={
          <BtnsAddUser>
            <ButtonCancel
              onClick={() => {
                onCancel();
              }}
            >
              {" "}
              {user?.id ? "Cancelar Edição" : "Cancelar Cadastro"}
            </ButtonCancel>
            <ButtonSave
              type="primary"
              htmlType="submit"
              loading={loading}
              onClick={() => {
                saveNewUser();
              }}
            >
              {user?.id ? "Confirmar Edição" : "Confirmar Cadastro"}
            </ButtonSave>
          </BtnsAddUser>
        }
      >
        <Form
          layout="vertical"
          form={form}
          autoComplete="new-form"
          onFinish={() => {
            onFinish();
          }}
        >
          <Tabs defaultActiveKey="1">
            {!user?.id ? (
              <>
                <TabPane tab="Informações do login" key="1" forceRender={true}>
                  <Row>
                    <Row gutter={12}>
                      <Col sm={24} xs={24}>
                        <FormUpload label="Imagem de Perfil">
                          <Upload file={image} setFile={setImage} />
                        </FormUpload>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col sm={0} xs={0}>
                        <FormItem label="Id" name="id">
                          <Input name="id" disabled />
                        </FormItem>
                      </Col>
                      <Col sm={12} xs={24}>
                        <FormItem
                          label="Nome"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Nome é obrigatório",
                            },
                          ]}
                        >
                          <Input
                            maxLength={50}
                            name="name"
                            placeholder="Digite o nome"
                          />
                        </FormItem>
                      </Col>

                      <Col sm={12} xs={24}>
                        <FormItem
                          label="Sobrenome"
                          name="last_name"
                          rules={[
                            {
                              required: isProfile ? false : true,
                              message: "Sobrenome é obrigatório",
                            },
                          ]}
                        >
                          <Input
                            name="last_name"
                            placeholder="Digite o sobrenome"
                            type="string"
                            maxLength={50}
                          />
                        </FormItem>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col sm={12} xs={24}>
                        <FormItem
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: isProfile ? false : true,
                              message: "Email é obrigatório",
                            },
                            {
                              type: "email",
                              message: "Entre com um e-mail válido",
                            },
                          ]}
                        >
                          <Input
                            name="email"
                            type="email"
                            placeholder="Digite o e-mail"
                            autoComplete="new-email"
                            maxLength={100}
                          />
                        </FormItem>
                      </Col>

                      <Col sm={12} xs={24}>
                        <FormItem
                          label="Confirme o e-mail"
                          name="validated_email"
                          rules={[
                            {
                              required: isProfile ? false : true,
                              message: "Email é obrigatório",
                            },
                            {
                              type: "email",
                              message: "Entre com um e-mail válido",
                            },
                          ]}
                        >
                          <Input
                            type="email"
                            name="validated_email"
                            placeholder="Confirme o e-mail"
                            autoComplete="new-email"
                          />
                        </FormItem>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="Senha"
                          name="password"
                          rules={[
                            {
                              required: isProfile
                                ? false
                                : user?.id
                                  ? false
                                  : true,
                              message: "Senha é obrigatória.",
                            },
                            {
                              min: 8,
                              message:
                                "É preciso conter ao menos 8 caracteres.",
                            },
                            {
                              pattern: /^(?=.*[A-Z])/,
                              message:
                                "É preciso conter ao menos um caracter maiúsculo.",
                            },
                            {
                              pattern: /^(?=.*[a-z])/,
                              message:
                                "É preciso conter ao menos um caracter minúsculo.",
                            },
                            {
                              pattern: /^(?=.*\d)/,
                              message: "É preciso conter ao menos um número.",
                            },
                            {
                              pattern: /^(?=.*[@$!%*?&])/,
                              message:
                                "É preciso conter ao menos um caracter especial. (*, !, etc)",
                            },
                          ]}
                        >
                          <Input
                            autoComplete="new-password"
                            type="password"
                            name="password"
                            placeholder="Digite a senha"
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={12} xs={24}>
                        <FormItem
                          label="Tipo de Conta"
                          name="level"
                          rules={[
                            {
                              required: isProfile ? false : true,
                              message: "Tipo de conta é obrigatório",
                            },
                          ]}
                        >
                          <Select placeholder="Escolha a opção">
                            {getTokenInfo()?.level === 1 && (
                              <Option value={1}>Master</Option>
                            )}
                            {verifyPermission("user.create_level_full") && (
                              <Option value={2}>Geral</Option>
                            )}
                            {(verifyPermission("user.create_level_full") ||
                              verifyPermission("user.create_level_admin")) && (
                                <Option value={3}>Administrador</Option>
                              )}
                            {(verifyPermission("user.create_level_admin") ||
                              verifyPermission("user.create_level_owner")) && (
                                <Option value={4}>Proprietário</Option>
                              )}
                            {(verifyPermission("user.create_level_owner") ||
                              verifyPermission("user.create_level_user")) && (
                                <Option value={5}>Usuário básico</Option>
                              )}
                          </Select>
                        </FormItem>{" "}
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      {verifyPermission("user.add_perfil") && (
                        <Col sm={12} xs={24}>
                          <FormItem
                            label="Perfil"
                            name="profile"
                            rules={[
                              {
                                required: true,
                                message: "Perfil é obrigatório",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Escolha a opção"
                              value={profileType}
                              onChange={(value) =>
                                setProfileType(value as number | undefined)
                              }
                            >
                              {profiles?.map((_profiles) => (
                                <Option
                                  value={_profiles?.id}
                                  key={_profiles?.id}
                                >
                                  {_profiles?.name}
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </Col>
                      )}
                    </Row>
                  </Row>
                </TabPane>

                <TabPane
                  tab="Informações do usuário"
                  key="2"
                  forceRender={true}
                >
                  <Row gutter={12}>
                    <Col sm={12} xs={24}>
                      {selectCountryInput === "BR" ? (
                        <FormItem
                          label="CPF"
                          name="cpf"
                          rules={[
                            {
                              required: true,
                              message: "CPF é obrigatório",
                            },
                            {
                              validator: (_, value) => {
                                if (!value) {
                                  return Promise.resolve();
                                }
                                const cpfCnpj = value.replace(/[^\d]+/g, "");
                                if (cpfCnpj.length === 11) {
                                  if (cpf.isValid(cpfCnpj)) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error("CPF inválido")
                                  );
                                }
                              },
                            },
                          ]}
                        >
                          <MaskedInput
                            name="cpf"
                            mask="111.111.111-11"
                            placeholder="999.999.999-99"
                          />
                        </FormItem>
                      ) : (
                        <FormItem
                          label="RUC"
                          name="ruc"
                          rules={[
                            {
                              required: true,
                              message: "RUC é obrigatório",
                            },
                          ]}
                        >
                          <MaskedInput
                            name="ruc"
                            mask="1111111-1"
                            placeholder="11111111-1"
                          />
                        </FormItem>
                      )}
                    </Col>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        label="Data de nascimento"
                        name="birthday"
                        style={{ paddingBottom: "5px" }}
                      >
                        <DatePicker
                          placeholder="Data de nascimento"
                          name="birthday"
                          format="DD/MM/YYYY"
                          locale={locale}
                          inputReadOnly={true}
                          allowClear={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={12}>
                    <Col sm={12} xs={24}>
                      <FormItem label="Telefone" name="phone">
                        <MaskedInput
                          name="phone"
                          mask="(11) 11111-1111"
                          placeholder="(99) 99999-9999"
                        />
                      </FormItem>
                    </Col>
                    <Col sm={12} xs={24}>
                      <Form.Item label="Gênero" name="gender">
                        <Select
                          placeholder="Selecione o gênero"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {genderOptions.map((option) => (
                            <Select.Option
                              value={option.value}
                              key={option.value}
                            >
                              {option.description}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={12}>
                    <Col sm={12} xs={24}>
                      <Form.Item label="Estado civil" name="marital_status">
                        <Select
                          placeholder="Selecione o estado civil"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => setIsMarried(value as any)}
                        >
                          {maritalStatus.map((option) => (
                            <Select.Option
                              value={option.value}
                              key={option.value}
                            >
                              {option.description}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {isMarried === 0 && (
                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="Cônjuge irá atuar no negócio?"
                          name="marital_status"
                        >
                          <Checkbox
                            checked={checked}
                            onChange={() => setChecked(true)}
                            name="is_married"
                          >
                            Sim, irá atuar
                          </Checkbox>
                          <Checkbox
                            checked={!checked}
                            onChange={() => setChecked(false)}
                            name="is_married"
                          >
                            Não
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>

                  <Divider />
                  {selectCountryInput === "BR" ? (
                    <>
                      <Row gutter={12}>
                        <Col sm={12} xs={24}>
                          <FormItem label="CEP" name="cep">
                            <MaskedInput
                              name="cep"
                              mask="11111-111"
                              onChange={(value) => handleChangeCep(value)}
                              placeholder="00000-000"
                            />
                          </FormItem>
                        </Col>
                        <Col sm={12} xs={24}>
                          <Form.Item label="Endereço" name="address">
                            <Input
                              disabled={!!viaCepData?.logradouro}
                              name="address"
                              placeholder="Preencha com o endereço"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={12}>
                        <Col sm={12} xs={24}>
                          <Form.Item label="Estado" name="state">
                            <Input
                              disabled={!!viaCepData}
                              name="state"
                              placeholder="Preencha com o Estado"
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={12} xs={24}>
                          <Form.Item label="Cidade" name="city">
                            <Input
                              disabled={!!viaCepData}
                              name="city"
                              placeholder="Preencha com a cidade"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={12}>
                        <Col sm={12} xs={24}>
                          <Form.Item label="Bairro" name="district">
                            <Input
                              disabled={!!viaCepData?.bairro}
                              name="district"
                              placeholder="Preencha com o bairro"
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={12} xs={24}>
                          <Form.Item label="Complemento" name="complement">
                            <Input
                              disabled={!!viaCepData}
                              name="complement"
                              placeholder="Preencha com o complemento"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Row gutter={12}>
                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="CEP"
                          name="cep"
                          rules={[
                            { required: true, message: "CEP é obrigatório" },
                          ]}
                        >
                          <MaskedInput
                            placeholder="CEP"
                            mask="111111"
                            name="cep"
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="Departamento"
                          name="distrito_py"
                          rules={[
                            {
                              required: true,
                              message: "Departamento é obrigatório",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Departamento"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {paraguaiDistrict.map((state) => (
                              <Select.Option value={state} key={state}>
                                {state}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="Cidade"
                          name="city"
                          rules={[
                            { required: true, message: "Cidade é obrigatório" },
                          ]}
                        >
                          <Input
                            type="text"
                            name="city"
                            placeholder="Cidade"
                            maxLength={70}
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="Endereço"
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: "Endereço é obrigatório",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            name="address"
                            placeholder="Endereço"
                            maxLength={100}
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="Bairro"
                          name="district"
                          rules={[
                            { required: true, message: "Bairro é obrigatório" },
                          ]}
                        >
                          <Input
                            type="text"
                            name="district"
                            placeholder="Bairro"
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="Complemento"
                          name="complement"
                          rules={[
                            {
                              required: false,
                              message: "Complemento é obrigatório",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            name="complement"
                            placeholder="Complemento"
                            maxLength={30}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  <Divider />

                  <Row gutter={12}>
                    {isAnotherArea === "Outro (especificar)" ? (
                      <Col sm={12} xs={24}>
                        <FormItem
                          label="Outra área de atuação"
                          name="atuation"
                          rules={[
                            {
                              required: isAnotherArea ? true : false,
                              message: "A atuação é obrigatória",
                            },
                          ]}
                        >
                          <Input
                            maxLength={50}
                            name="atuation"
                            placeholder="Digite a área de atuação"
                          />
                        </FormItem>
                      </Col>
                    ) : (
                      <Col sm={12} xs={24}>
                        <Form.Item label="Atuação" name="atuation">
                          <Select
                            placeholder="Selecione a área de atuação"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) =>
                              setIsAnotherArea(value as string)
                            }
                          >
                            {atuationArea
                              .sort((a, b) =>
                                a.description.localeCompare(b.description)
                              )
                              .map((option) => (
                                <Select.Option
                                  value={option.description}
                                  key={option.value}
                                >
                                  {option.description}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                    <Col sm={12} xs={24}>
                      <Form.Item label="Grau de escolaridade" name="scholarity">
                        <Select
                          placeholder="Selecione o grau de escolaridade"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) =>
                            setSelectedScholarity(value as any)
                          }
                        >
                          {scholarityOptions.map((option) => (
                            <Select.Option
                              value={option.value}
                              key={option.value}
                            >
                              {option.description}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
              </>
            ) : (
              <TabPane tab="Informações do usuário" key="1" forceRender={true}>
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <Col sm={0} xs={0}>
                    <FormItem label="Id" name="id">
                      <Input name="id" disabled />
                    </FormItem>
                  </Col>

                  {user?.image ? (
                    <Row>
                      <Col sm={3} xs={24}>
                        <ContainerImg>
                          <img
                            src={user?.image}
                            alt="Foto de perfil do usuário"
                          />
                        </ContainerImg>
                      </Col>
                      <Col sm={6} xs={24}>
                        <FormUpload noStyle>
                          <Upload file={image} setFile={setImage} />
                        </FormUpload>
                      </Col>
                    </Row>
                  ) : (
                    <Row gutter={12}>
                      <Col sm={6} xs={24}>
                        <FormUpload label="Imagem de Perfil">
                          <Upload file={image} setFile={setImage} />
                        </FormUpload>
                      </Col>
                    </Row>
                  )}

                  <Divider orientation="left">
                    Dados de informação do usuário
                  </Divider>

                  <Row gutter={12}>
                    <Col sm={12} xs={24}>
                      <FormItem
                        label="Nome"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Nome é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          maxLength={50}
                          name="name"
                          placeholder="Digite o nome"
                        />
                      </FormItem>
                    </Col>

                    <Col sm={12} xs={24}>
                      <FormItem
                        label="Sobrenome"
                        name="last_name"
                        rules={[
                          {
                            required: isProfile ? false : true,
                            message: "Sobrenome é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          name="last_name"
                          placeholder="Digite o sobrenome"
                          maxLength={50}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col sm={12} xs={24}>
                      <FormItem
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: isProfile ? false : true,
                            message: "Email é obrigatório",
                          },
                          {
                            type: "email",
                            message: "Entre com um e-mail válido",
                          },
                        ]}
                      >
                        <Input
                          name="email"
                          type="email"
                          placeholder="Digite o e-mail"
                          autoComplete="new-email"
                          maxLength={100}
                        />
                      </FormItem>
                    </Col>
                    <Col sm={12} xs={24}>
                      <FormItem
                        label="Confirme o e-mail"
                        name="validated_email"
                        rules={[
                          {
                            required: isProfile ? false : true,
                            message: "Email é obrigatório",
                          },
                          {
                            type: "email",
                            message: "Entre com um e-mail válido",
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          name="validated_email"
                          placeholder="Confirme o e-mail"
                          autoComplete="new-email"
                        />
                      </FormItem>
                    </Col>
                  </Row>

                  <Row gutter={12}>
                    <Col sm={12} xs={24}>
                      <Form.Item label="Gênero" name="gender">
                        <Select
                          placeholder="Selecione o gênero"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {genderOptions.map((option) => (
                            <Select.Option
                              value={option.value}
                              key={option.value}
                            >
                              {option.description}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col sm={12} xs={24}>
                      <Form.Item
                        label="Data de nascimento"
                        name="birthday"
                        style={{ paddingBottom: "5px" }}
                      >
                        <DatePicker
                          placeholder="Data de nascimento"
                          name="birthday"
                          format="DD/MM/YYYY"
                          locale={locale}
                          inputReadOnly={true}
                          allowClear={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={12}>
                    <Col sm={12} xs={24}>
                      <FormItem label="Telefone" name="phone">
                        <MaskedInput
                          name="phone"
                          mask="(11) 11111-1111"
                          placeholder="(99) 99999-9999"
                        />
                      </FormItem>
                    </Col>
                    <Col sm={12} xs={24}>
                      <Form.Item label="Grau de escolaridade" name="scholarity">
                        <Select
                          placeholder="Selecione o grau de escolaridade"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) =>
                            setSelectedScholarity(value as any)
                          }
                        >
                          {scholarityOptions.map((option) => (
                            <Select.Option
                              value={option.value}
                              key={option.value}
                            >
                              {option.description}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={12}>
                    {selectedScholarity && selectedScholarity >= 6 && (
                      <Col sm={12} xs={24}>
                        <Form.Item label="Cursos/Graduações" name="courses">
                          <Input name="courses" />
                        </Form.Item>
                      </Col>
                    )}
                    <Col sm={12} xs={24}>
                      {isAnotherArea === "Outro (especificar)" ? (
                        <FormItem
                          label="Outra área de atuação"
                          name="atuation"
                          rules={[
                            {
                              required: isAnotherArea ? true : false,
                              message: "A atuação é obrigatória",
                            },
                          ]}
                        >
                          <Input
                            maxLength={50}
                            name="atuation"
                            placeholder="Digite a área de atuação"
                          />
                        </FormItem>
                      ) : (
                        <Form.Item label="Atuação" name="atuation">
                          <Select
                            placeholder="Selecione a área de atuação"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) =>
                              setIsAnotherArea(value as string)
                            }
                          >
                            {atuationArea.map((option) => (
                              <Select.Option
                                value={option.description}
                                key={option.value}
                              >
                                {option.description}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Col>
                  </Row>

                  <Row gutter={12}>
                    <Col sm={12} xs={24}>
                      <Form.Item label="Estado civil" name="marital_status">
                        <Select
                          placeholder="Selecione o estado civil"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => setIsMarried(value as any)}
                        >
                          {maritalStatus.map((option) => (
                            <Select.Option
                              value={option.value}
                              key={option.value}
                            >
                              {option.description}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {isMarried === 0 && (
                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="Cônjuge irá atuar no negócio?"
                          name="marital_status"
                          rules={[
                            {
                              required: isMarried === 0 ? true : false,
                              message: "O campo é obrigatório",
                            },
                          ]}
                        >
                          <Checkbox
                            checked={checked}
                            onChange={() => setChecked(true)}
                            name="is_married"
                          >
                            Sim, irá atuar
                          </Checkbox>
                          <Checkbox
                            checked={!checked}
                            onChange={() => setChecked(false)}
                            name="is_married"
                          >
                            Não
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>

                  <Row gutter={12}>
                    <Col sm={12} xs={24}>
                      {verifyPermission("user.add_perfil") && (
                        <FormItem
                          label="Perfil"
                          name="profile"
                          rules={[
                            {
                              required: isProfile ? false : true,
                              message: "Perfil é obrigatório",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Escolha a opção"
                            value={profileType}
                            onChange={(value) =>
                              setProfileType(value as number | undefined)
                            }
                          >
                            {profiles?.map((_profiles) => (
                              <Option value={_profiles?.id} key={_profiles?.id}>
                                {_profiles?.name}
                              </Option>
                            ))}
                          </Select>
                        </FormItem>
                      )}
                    </Col>
                    <Col sm={12} xs={24}>
                      {getTokenInfo()?.level === 1 && (
                        <FormItem
                          label="Tipo de Conta"
                          name="level"
                          rules={[
                            {
                              required: isProfile ? false : true,
                              message: "Tipo de conta é obrigatório",
                            },
                          ]}
                        >
                          <Select placeholder="Escolha a opção">
                            <Option value={1}>Master</Option>
                            <Option value={2}>Geral</Option>
                            <Option value={3}>Administrador</Option>
                            <Option value={4}>Proprietário</Option>
                            <Option value={5}>Usuário básico</Option>
                          </Select>
                        </FormItem>
                      )}
                    </Col>
                  </Row>

                  <Divider orientation="left">
                    Dados de localização do usuário
                  </Divider>
                  {selectCountryInput === "BR" ? (
                    <>
                      <Row gutter={12}>
                        <Col sm={12} xs={24}>
                          <FormItem label="CEP" name="cep">
                            <MaskedInput
                              name="cep"
                              mask="11111-111"
                              onChange={(value) => handleChangeCep(value)}
                              placeholder="00000-000"
                            />
                          </FormItem>
                        </Col>
                        <Col sm={12} xs={24}>
                          <Form.Item label="Endereço" name="address">
                            <Input
                              disabled={!!viaCepData?.logradouro}
                              name="address"
                              placeholder="Preencha com o endereço"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={12}>
                        <Col sm={12} xs={24}>
                          <Form.Item label="Estado" name="state">
                            <Input
                              disabled={!!viaCepData}
                              name="state"
                              placeholder="Preencha com o Estado"
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={12} xs={24}>
                          <Form.Item label="Cidade" name="city">
                            <Input
                              disabled={!!viaCepData}
                              name="city"
                              placeholder="Preencha com a cidade"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={12}>
                        <Col sm={12} xs={24}>
                          <Form.Item label="Bairro" name="district">
                            <Input
                              disabled={!!viaCepData?.bairro}
                              name="district"
                              placeholder="Preencha com o bairro"
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={12} xs={24}>
                          <Form.Item label="Complemento" name="complement">
                            <Input
                              disabled={!!viaCepData}
                              name="complement"
                              placeholder="Preencha com o complemento"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Row gutter={12}>
                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="CEP"
                          name="cep"
                          rules={[
                            { required: true, message: "CEP é obrigatório" },
                          ]}
                        >
                          <MaskedInput
                            placeholder="CEP"
                            mask="111111"
                            name="cep"
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="Departamento"
                          name="distrito_py"
                          rules={[
                            {
                              required: true,
                              message: "Departamento é obrigatório",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Departamento"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {paraguaiDistrict.map((state) => (
                              <Select.Option value={state} key={state}>
                                {state}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="Cidade"
                          name="city"
                          rules={[
                            { required: true, message: "Cidade é obrigatório" },
                          ]}
                        >
                          <Input
                            type="text"
                            name="city"
                            placeholder="Cidade"
                            maxLength={70}
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="Endereço"
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: "Endereço é obrigatório",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            name="address"
                            placeholder="Endereço"
                            maxLength={100}
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="Bairro"
                          name="district"
                          rules={[
                            { required: true, message: "Bairro é obrigatório" },
                          ]}
                        >
                          <Input
                            type="text"
                            name="district"
                            placeholder="Bairro"
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="Complemento"
                          name="complement"
                          rules={[
                            {
                              required: false,
                              message: "Complemento é obrigatório",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            name="complement"
                            placeholder="Complemento"
                            maxLength={30}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Row>
              </TabPane>
            )}

            <TabPane tab="Seleção de Empresas" key="4" forceRender={true}>
              <Row>
                {loading ? (
                  <Spinner />
                ) : (
                  <Col sm={24} xs={24}>
                    <FormItem label="" name="companies">
                      <Companies
                        userId={user?.id}
                        handleChange={(companyIds) => setCompanies(companyIds)}
                      />
                    </FormItem>
                  </Col>
                )}
              </Row>
            </TabPane>
          </Tabs>
        </Form>
      </ModalAddNewUser>

      <ModalConfirmEdit
        title={
          <>
            <Exclamation></Exclamation>
            <span>Atenção</span>
          </>
        }
        destroyOnClose={true}
        centered
        confirmLoading={loading}
        visible={visibleConfirmEdit}
        closable={false}
        footer={
          <BtnsModalUser>
            <ButtonCancel
              onClick={() => {
                setVisibleConfirmEdit(false);
              }}
            >
              Não
            </ButtonCancel>
            <ButtonSave
              type="primary"
              onClick={() => {
                setVisibleConfirmEdit(false);
                onFinish();
              }}
            >
              Sim
            </ButtonSave>
          </BtnsModalUser>
        }
      >
        <span>Deseja continuar e realizar as alterações selecionadas?</span>
      </ModalConfirmEdit>
    </>
  );
};

export default UserForm;
